import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = ({ fill, ...props }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 1.75C7.23516 1.75 1.75 7.23516 1.75 14C1.75 20.7648 7.23516 26.25 14 26.25C20.7648 26.25 26.25 20.7648 26.25 14C26.25 7.23516 20.7648 1.75 14 1.75ZM14 24.1719C8.38359 24.1719 3.82812 19.6164 3.82812 14C3.82812 8.38359 8.38359 3.82812 14 3.82812C19.6164 3.82812 24.1719 8.38359 24.1719 14C24.1719 19.6164 19.6164 24.1719 14 24.1719Z"
      fill={fill}
    />
    <path
      d="M17.0516 8.65977C16.2312 7.94063 15.1484 7.54688 14 7.54688C12.8516 7.54688 11.7687 7.94336 10.9484 8.65977C10.0953 9.40625 9.625 10.4098 9.625 11.4844V11.6922C9.625 11.8125 9.72344 11.9109 9.84375 11.9109H11.1562C11.2766 11.9109 11.375 11.8125 11.375 11.6922V11.4844C11.375 10.2785 12.5535 9.29688 14 9.29688C15.4465 9.29688 16.625 10.2785 16.625 11.4844C16.625 12.3348 16.0234 13.1141 15.091 13.4723C14.5113 13.6938 14.0191 14.082 13.6664 14.5906C13.3082 15.1102 13.1223 15.7336 13.1223 16.3652V16.9531C13.1223 17.0734 13.2207 17.1719 13.341 17.1719H14.6535C14.7738 17.1719 14.8723 17.0734 14.8723 16.9531V16.3324C14.8737 16.067 14.9551 15.8081 15.1058 15.5896C15.2565 15.3711 15.4696 15.203 15.7172 15.1074C17.3305 14.4867 18.3723 13.0648 18.3723 11.4844C18.375 10.4098 17.9047 9.40625 17.0516 8.65977ZM12.9062 20.0156C12.9062 20.3057 13.0215 20.5839 13.2266 20.789C13.4317 20.9941 13.7099 21.1094 14 21.1094C14.2901 21.1094 14.5683 20.9941 14.7734 20.789C14.9785 20.5839 15.0938 20.3057 15.0938 20.0156C15.0938 19.7255 14.9785 19.4473 14.7734 19.2422C14.5683 19.0371 14.2901 18.9219 14 18.9219C13.7099 18.9219 13.4317 19.0371 13.2266 19.2422C13.0215 19.4473 12.9062 19.7255 12.9062 20.0156Z"
      fill={fill}
    />
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: 'var(--colors-santas-gray)',
};

export default SvgComponent;
