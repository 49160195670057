import PropTypes from 'prop-types';
import clsx from 'clsx';

const SvgComponent = ({ className, fill, ...props }) => (
  <svg
    className={clsx('w-8 h-8', className)}
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.01 23.499a15.03 15.03 0 0 1-1.467-3.49 4.5 4.5 0 0 0 .003-8.02 14.985 14.985 0 0 1 3.751-6.505 4.5 4.5 0 0 0 6.947-4.011 14.985 14.985 0 0 1 7.51.003 4.5 4.5 0 0 0 6.948 4.008c.869.885 1.64 1.891 2.288 3.015a14.959 14.959 0 0 1 1.467 3.489 4.5 4.5 0 0 0-.003 8.02 14.986 14.986 0 0 1-3.752 6.506 4.5 4.5 0 0 0-6.946 4.01 14.986 14.986 0 0 1-7.51-.002 4.5 4.5 0 0 0-6.949-4.008 15.03 15.03 0 0 1-2.287-3.015Zm8.49.294a7.49 7.49 0 0 1 3.375 4.155c.748.07 1.5.072 2.248.001a7.489 7.489 0 0 1 3.377-4.155 7.49 7.49 0 0 1 5.288-.847A11.9 11.9 0 0 0 25.91 21 7.49 7.49 0 0 1 24 15.999c0-1.89.705-3.656 1.91-5.001a12.187 12.187 0 0 0-1.125-1.947 7.49 7.49 0 0 1-5.285-.846 7.49 7.49 0 0 1-3.375-4.155c-.748-.07-1.5-.072-2.248-.002A7.49 7.49 0 0 1 10.5 8.203a7.49 7.49 0 0 1-5.288.848c-.434.612-.81 1.264-1.122 1.947a7.49 7.49 0 0 1 1.91 5c0 1.89-.705 3.656-1.91 5.002.315.682.691 1.334 1.125 1.947a7.489 7.489 0 0 1 5.285.846Zm4.5-3.294a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm0-3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      fill={fill || 'var(--colors-cadet)'}
    />
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: 'var(--colors-cadet)',
  className: '',
};

export default SvgComponent;
