const SvgComponent = props => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.4 23.4A2.403 2.403 0 0 1 3 21V3A2.403 2.403 0 0 1 5.4.6h8.756L21 7.45v13.552a2.402 2.402 0 0 1-2.4 2.4H5.4Z"
      fill="#D9E4E8"
    />
    <path opacity={0.14} d="m20.4 6-4.147-4.148A3 3 0 0 0 13.801 4.8V6a3 3 0 0 0 3 3h3.6V6Z" fill="#17292D" />
    <path d="M16.798 7.2a2.403 2.403 0 0 1-2.4-2.4V.85l6.335 6.35h-3.935Z" fill="#79D3D3" />
    <path
      d="M14.405 0H5.398a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h13.2a3 3 0 0 0 3-3V7.2L14.405 0Zm4.882 6.6h-2.489a1.802 1.802 0 0 1-1.8-1.8V2.3l4.289 4.3ZM20.398 21a1.802 1.802 0 0 1-1.8 1.8h-13.2a1.802 1.802 0 0 1-1.8-1.8V3a1.802 1.802 0 0 1 1.8-1.8h8.4v3.6a3 3 0 0 0 3 3h3.6V21Z"
      fill="#333"
    />
  </svg>
);

export default SvgComponent;
