import clsx from 'clsx';
import PropTypes from 'prop-types';

const ArticleTitle = ({ children, className }) => (
  <h2 className={clsx('text-lg text-dark-charcoal my-5 font-roboto-medium', className)}>{children}</h2>
);

ArticleTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string,
};

ArticleTitle.defaultProps = {
  className: '',
};

export default ArticleTitle;
