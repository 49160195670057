import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useDevice from '@hooks/useDevice';
import { Checkbox } from '@components/index';

import styles from './Reminders.module.scss';

const Reminders = ({ emailEnabled, smsEnabled, setEmailEnabled, setSmsEnabled }) => {
  const { isMobile } = useDevice();

  return (
    <section>
      <h2 className="text-default laptop:text-xl font-roboto-medium mb-5 laptop:mb-6">Reminder Notifications by:</h2>
      <div className="flex flex-col laptop:flex-row mb-14">
        <div
          onClick={() => setEmailEnabled(!emailEnabled)}
          className={clsx(
            'p-3 laptop:p-4 flex item-center rounded-md mr-0 laptop:mr-6 transition-colors duration-300 cursor-pointer mb-5 laptop:mb-0',
            emailEnabled && !isMobile ? styles.selectedCheckBox : 'bg-anti-flash-white',
          )}
        >
          <Checkbox
            width={isMobile ? 1.25 : 1.563}
            height={isMobile ? 1.25 : 1.563}
            checkedBgColor={isMobile ? '#6AB97A' : ''}
            checkColor={isMobile ? '#FFFFFF' : ''}
            checked={emailEnabled}
            className="pointer-events-none"
          />
          <span
            className={clsx(
              'self-center pl-4 transition-colors duration-300',
              emailEnabled && !isMobile && 'text-white',
            )}
          >
            Email
          </span>
        </div>
        <div
          onClick={() => setSmsEnabled(!smsEnabled)}
          className={clsx(
            'p-3 laptop:p-4 flex item-center rounded-md transition-colors duration-300 cursor-pointer',
            smsEnabled && !isMobile ? styles.selectedCheckBox : 'bg-anti-flash-white',
          )}
        >
          <Checkbox
            width={isMobile ? 1.25 : 1.563}
            height={isMobile ? 1.25 : 1.563}
            checkedBgColor={isMobile ? '#6AB97A' : ''}
            checkColor={isMobile ? '#FFFFFF' : ''}
            checked={smsEnabled}
            className="pointer-events-none"
          />
          <span
            className={clsx('self-center pl-4 transition-colors duration-300', smsEnabled && !isMobile && 'text-white')}
          >
            SMS
          </span>
        </div>
      </div>
    </section>
  );
};

Reminders.propTypes = {
  emailEnabled: PropTypes.bool.isRequired,
  smsEnabled: PropTypes.bool.isRequired,
  setEmailEnabled: PropTypes.func.isRequired,
  setSmsEnabled: PropTypes.func.isRequired,
};

export default React.memo(Reminders);
