import { RedirectRoot } from '@pages/RedirectRoot';

import { AUTH, NOT_FOUND, ROOT_PATH, CREATE_PROFILE } from '@patient/router/paths';
import Auth from '@patient/pages/Auth';

export const AUTH_ROUTES = [
  {
    name: 'checkEmail',
    path: ROOT_PATH,
    exact: true,
    component: Auth.CheckEmail,
  },
  {
    name: 'checkCode',
    path: AUTH.CHECK_CODE,
    exact: true,
    component: Auth.CheckCode,
  },
  {
    name: 'signIn',
    path: AUTH.SIGN_IN,
    exact: true,
    component: Auth.SignIn,
  },
  {
    name: 'signUp',
    path: AUTH.SIGN_UP,
    exact: true,
    component: Auth.SignUp,
  },
  {
    name: 'forgotPassword',
    path: AUTH.RESET_PASSWORD.FORGOT_PASSWORD,
    exact: true,
    component: Auth.ForgotPassword,
  },
  {
    name: 'info',
    path: AUTH.RESET_PASSWORD.INFO,
    exact: true,
    component: Auth.Info,
  },
  {
    name: 'newPassword',
    path: AUTH.RESET_PASSWORD.NEW_PASSWORD,
    exact: true,
    component: Auth.NewPassword,
  },
  {
    name: 'createProfile',
    path: CREATE_PROFILE,
    exact: true,
    component: Auth.CreateProfile,
  },
  {
    path: NOT_FOUND,
    component: () => <RedirectRoot path={ROOT_PATH} />,
  },
];
