import { RESET_MEMORABLE_QUESTION, TERMS_AND_CONDITIONS, PRIVACY_POLICY } from '@router/paths';
import Root from '@pages/Root';

export const ROOT_ROUTES = [
  {
    name: 'resetMemorableQuestion',
    path: RESET_MEMORABLE_QUESTION,
    exact: true,
    component: Root.ResetMemorableQuestion,
  },
  {
    name: 'termsAndConditions',
    path: TERMS_AND_CONDITIONS,
    exact: true,
    component: Root.TermsAndConditions,
  },
  {
    name: 'privacyPolicy',
    path: PRIVACY_POLICY,
    exact: true,
    component: Root.PrivacyPolicy,
  },
];
