import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = ({ fill, ...props }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.53081 22.1667L2.33331 26.25V4.66667C2.33331 4.35725 2.45623 4.0605 2.67502 3.84171C2.89381 3.62292 3.19056 3.5 3.49998 3.5H24.5C24.8094 3.5 25.1061 3.62292 25.3249 3.84171C25.5437 4.0605 25.6666 4.35725 25.6666 4.66667V21C25.6666 21.3094 25.5437 21.6062 25.3249 21.825C25.1061 22.0438 24.8094 22.1667 24.5 22.1667H7.53081ZM4.66665 21.4492L6.72348 19.8333H23.3333V5.83333H4.66665V21.4492ZM12.8333 15.1667H15.1666V17.5H12.8333V15.1667ZM12.8333 8.16667H15.1666V14H12.8333V8.16667Z"
      fill={fill}
    />
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: 'var(--colors-santas-gray)',
};

export default SvgComponent;
