import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ReactPopup from 'reactjs-popup';

import styles from './Popup.module.scss';
import './Popup.scss';

const Popup = ({
  children,
  trigger,
  containerSelector,
  position,
  className,
  closeOnContentClick,
  onOpen,
  onClose,
  offsetY,
  offsetX,
  label,
}) => {
  const onContentClick = closeFn => {
    if (closeOnContentClick) {
      closeFn();
    }
  };

  return (
    <ReactPopup
      className={clsx('custom-popup', className)}
      trigger={trigger}
      keepTooltipInside={containerSelector}
      position={position}
      on="click"
      onClose={onClose}
      onOpen={onOpen}
      offsetY={offsetY}
      offsetX={offsetX}
    >
      {close => (
        <div className={styles.popupContent} onClick={() => onContentClick(close)}>
          {!!label && (
            <div
              className="w-full px-2 laptop:px-3 font-roboto-medium pt-1 laptop:pt-2 mb-2 laptop:mb-3 pointer-events-none cursor-default"
              style={{ color: '#9B9DAE' }}
            >
              <span className="text-xsLh22 laptop:text-base">{label}</span>
            </div>
          )}
          {React.cloneElement(children, { closePopup: close })}
        </div>
      )}
    </ReactPopup>
  );
};

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  className: PropTypes.string,
  closeOnContentClick: PropTypes.bool,
  containerSelector: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  offsetY: PropTypes.number,
  offsetX: PropTypes.number,
  label: PropTypes.string,
};

Popup.defaultProps = {
  className: '',
  closeOnContentClick: true,
  containerSelector: null,
  position: 'bottom left',
  onOpen: () => {},
  onClose: () => {},
  offsetY: 0,
  offsetX: 0,
  label: '',
};

export default Popup;
