import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  setTargetRef: () => {},
  targetRef: {},
};

const HintContext = createContext(initialState);

export const HintContextProvider = ({ children }) => {
  const [targetRef, setTargetRef] = useState(null);

  const context = useMemo(
    () => ({
      setTargetRef,
      targetRef,
    }),
    [setTargetRef, targetRef],
  );

  return <HintContext.Provider value={context}>{children}</HintContext.Provider>;
};

HintContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useHint = () => useContext(HintContext);
