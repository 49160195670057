import { useEffect, useRef, useState, useMemo } from 'react';
import { countries } from 'countries-list';
import Select from 'react-select';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import examples from 'libphonenumber-js/examples.mobile.json';
import { getExampleNumber } from 'libphonenumber-js';

import useDevice from '@hooks/useDevice';
import SmallArrow from '@assets/icons/SmallArrow';
import { useOutside } from '@hooks/useOutside';

import styles from './CountryPhones.module.scss';

const numberPhoneReg = '^\\d+$';

const initialSelectedValue = {
  value: 'GB',
  name: countries.GB.name,
  label: `${countries.GB.name} (+${countries.GB.phone})`,
  internalCode: countries.GB.phone,
};

const allCountries = Object.entries(countries).map(item => ({
  value: item[0],
  name: item[1].name,
  label: `${item[1].name} (+${item[1].phone})`,
  internalCode: item[1].phone,
}));

const selectStyles = {
  control: provided => ({
    ...provided,
    margin: 8,
    borderColor: 'var(--colors-bright-gray)',
    '&:hover': { borderColor: 'var(--colors-bright-gray)' },
    boxShadow: 'none',
  }),
  menu: () => ({
    marginLeft: 8,
    marginRight: 8,
  }),
  container: () => ({ width: '100%' }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
};

const CountryPhones = ({ onChange }) => {
  const wrapperRef = useRef(null);
  const { isMobile } = useDevice();

  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);
  const [visibleSelect, setVisibleSelect] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const internalCode = useMemo(() => `+${selectedValue.internalCode}`, [selectedValue]);
  const placeholder = useMemo(() => getExampleNumber(selectedValue.value, examples).nationalNumber, [selectedValue]);

  useOutside(wrapperRef, setVisibleSelect);

  const handleChange = value => {
    setSelectedValue(value);
  };

  const handleChangePhoneHandler = value => {
    if ((value === '' || value.match(numberPhoneReg)) && value.length <= placeholder.length) {
      setInputValue(value);
    }
  };

  const formatOptionLabel = ({ value, label, name }) => (
    <div className="flex flex-row">
      <div className="flex items-center">
        <img
          className="rounded-md"
          width={36}
          height={26}
          alt={name}
          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`}
        />
      </div>
      <div className="text-dark-charcoal ml-2">{label}</div>
    </div>
  );

  useEffect(() => {
    if (!inputValue) {
      return;
    }

    onChange(`${internalCode}${inputValue}`);
  }, [inputValue, selectedValue]);

  return (
    <div className={clsx('flex flex-row', styles.widthCountryBlock)}>
      <div className="relative w-full">
        <p className="text-xsLh22 laptop:text-base font-roboto-medium ml-2 laptop:ml-4 mb-2">
          <span style={{ color: '#9B9DAE' }}>Phone</span>
        </p>
        <div className="flex flex-col laptop:flex-row relative z-20 w-full">
          <div
            onClick={() => setVisibleSelect(!visibleSelect)}
            className={clsx(
              styles.countryBlock,
              'px-4 cursor-pointer border flex flex-row w-36 h-10 laptop:h-14',
              isMobile && 'rounded-r-lg mb-2',
              visibleSelect ? 'border-iguana-green pointer-events-none' : 'border-bright-gray',
            )}
          >
            {selectedValue && (
              <div className="flex flex-row items-center">
                <img
                  className="rounded-md mr-1"
                  width={36}
                  height={26}
                  alt={selectedValue.name}
                  src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedValue.value}.svg`}
                />
                <span className="text-xsLh22 laptop:text-base text-dark-charcoal">{selectedValue.value}</span>
              </div>
            )}
            <div className="ml-auto">
              <SmallArrow />
            </div>
          </div>
          <div
            className={clsx(
              'bg-white relative flex-1 flex flex-row item-center rounded-r-lg px-4 border-b border-t border-r border-bright-gray w-60 laptop:w-auto py-3 laptop:h-14',
              isMobile && 'border-l rounded-l-lg',
            )}
          >
            <p className="flex items-center mr-1 text-xsLh22 laptop:text-base">
              {selectedValue.value === 'GB' ? `${internalCode} (0)` : internalCode}
            </p>
            <input
              value={inputValue}
              type="text"
              placeholder={placeholder}
              className="outline-none text-text-dark-charcoal text-xsLh22 laptop:text-base"
              onChange={e => handleChangePhoneHandler(e.target.value)}
            />
          </div>
        </div>
        <AnimatePresence>
          {visibleSelect && (
            <motion.div
              ref={wrapperRef}
              style={{
                boxShadow: `0 0.5rem 1.75rem -0.375rem rgba(11, 17, 33, 0.12), 0 1.125rem 5.5rem -0.25rem rgba(11, 17, 33, 0.04)`,
              }}
              className="flex -mt-3 laptop:-mt-2 pt-2 justify-center items-center bg-white absolute z-10 w-1/2 laptop:w-full rounded-bl-md rounded-br-md"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  type: 'easyInOut',
                  stiffness: 300,
                },
              }}
              exit={{
                opacity: 0,
                transition: { duration: 0.6 },
              }}
            >
              <Select
                options={allCountries}
                value={selectedValue}
                formatOptionLabel={formatOptionLabel}
                isSearchable
                autoFocus
                backspaceRemovesValue={false}
                components={{ IndicatorSeparator: null }}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                menuIsOpen
                styles={selectStyles}
                onChange={handleChange}
                placeholder="Search..."
                tabSelectsValue={false}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    text: 'var(--colors-dark-charcoal)',
                    primary25: 'var(--colors-cultured)',
                    primary: 'var(--colors-cultured)',
                    primary50: theme.colors.neutral10,
                  },
                })}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

CountryPhones.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default CountryPhones;
