import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import useDevice from '@hooks/useDevice';
import { useHint } from '@contexts/HintContext';
import clsx from 'clsx';

const TextHintWrapper = ({ children, className, onClick }) => {
  const ref = useRef();
  const { isMobile } = useDevice();
  const { setTargetRef, targetRef } = useHint();

  const handleToggleVisibility = e => {
    if (ref.current.scrollWidth <= ref.current.offsetWidth) {
      return;
    }

    const isVisible = targetRef?.current && targetRef?.current === e.target;

    e.stopPropagation();

    if (isVisible) {
      setTargetRef(null);

      return;
    }

    setTargetRef(ref);
  };

  const handleMobileToggleVisibility = useMemo(
    () => (isMobile ? null : handleToggleVisibility),
    [isMobile, handleToggleVisibility],
  );

  return (
    <span
      className={clsx(className, 'truncate block')}
      onClick={onClick || handleToggleVisibility}
      onMouseOver={handleMobileToggleVisibility}
      onMouseOut={handleMobileToggleVisibility}
      onFocus={handleMobileToggleVisibility}
      onBlur={handleMobileToggleVisibility}
      ref={ref}
    >
      {children}
    </span>
  );
};

TextHintWrapper.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

TextHintWrapper.defaultProps = {
  onClick: null,
  children: '',
  className: '',
};

export default TextHintWrapper;
