import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Property = ({ name, value, horizontal }) => (
  <div className={clsx('flex items-start gap-x-2', horizontal ? 'mr-4' : 'justify-between')}>
    <p className="text-base text-silver-sand whitespace-nowrap">{name}</p>
    <p className="text-base truncate font-semibold text-davy-grey">{!!value && value !== 0 ? value : 'N/A'}</p>
  </div>
);

Property.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  horizontal: PropTypes.bool,
};

Property.defaultProps = {
  horizontal: false,
  value: 'N/A',
};

export default memo(Property);
