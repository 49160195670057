import * as yup from 'yup';
import validator from 'validator/es';
import { isAfter } from 'date-fns';
import Payment from 'payment';

import { passwordRegex } from '@utils/regex';

import {
  MEDICATION_TYPES,
  MEDICATION_CATEGORIES,
  PRESCRIPTION_DEACTIVATION_OPTIONS,
  ORDER_REFUND_OPTIONS,
} from './consts';

yup.addMethod(yup.string, 'integer', function match(message) {
  return this.matches(/^\d+$/, message || 'The field should have digits only');
});
yup.addMethod(yup.string, 'phoneCheck', function phone(message) {
  const phoneRegExp = '^(\\+)?([ 0-9]){7,16}$';
  return this.matches(phoneRegExp, message || 'Invalid phone number');
});

export const validationCreateProfile = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z -]*$/, 'Invalid name')
    .required('First Name is required'),
  lastName: yup
    .string()
    .matches(/^[A-Za-z -]*$/, 'Invalid surname')
    .required('Last Name is required'),
  email: yup.string().required('Email is required'),
  phone: yup.string().required('Phone is required'),
  practitionerType: yup
    .object()
    .nullable()
    .shape({
      value: yup.string(),
    })
    .required('Type is required'),
  qualificationNumber: yup
    .number()
    .positive('Must be a positive number')
    .integer('Must be an integer number')
    .typeError('Must be a number')
    .required('Number is required'),
  clinicEmail: yup.string().email('Must be a valid email'),
  clinicName: yup.string().required('Name is required'),
  clinicPhone: yup
    .string()
    .required('Phone is required')
    .test('phoneNumberValidation', 'Number is invalid', val =>
      validator.isMobilePhone(val, 'any', { strictMode: true }),
    ),
  country: yup
    .object()
    .shape({
      value: yup.string(),
    })
    .required('Country is required'),
  city: yup.string().required('City is required'),
  address: yup
    .object()
    .shape({
      label: yup.string().required('Address is required'),
    })
    .nullable()
    .required('Address is required'),
  postcode: yup.string().required('Postcode is required'),
  securityAnswer: yup.string().required('Security answer is required'),
  otpByEmail: yup.string().required(),
  otpBySms: yup.string().required(),
});

export const validationResetMemorableQuestion = yup.object().shape({
  securityAnswer: yup.string().required('Security answer is required'),
});

export const validationUpdateProfile = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z -]*$/, 'Invalid name')
    .required('First Name is required'),
  lastName: yup
    .string()
    .matches(/^[A-Za-z -]*$/, 'Invalid name')
    .required('Last Name is required'),
  email: yup.string().required('Email is required'),
  phone: yup
    .string()
    .test('phoneNumberValidation', 'Number is invalid', val =>
      validator.isMobilePhone(val, 'any', { strictMode: true }),
    )
    .required('Phone is required'),
  practitionerType: yup
    .object()
    .shape({
      value: yup.string(),
    })
    .required('Type is required'),
  qualificationNumber: yup
    .number()
    .positive('Must be a positive number')
    .integer('Must be an integer number')
    .typeError('Must be a number')
    .required('Number is required'),
  clinicEmail: yup.string().email('Must be a valid email'),
  clinicName: yup.string().required('Name is required'),
  clinicPhone: yup
    .string()
    .test('phoneNumberValidation', 'Number is invalid', val =>
      validator.isMobilePhone(val, 'any', { strictMode: true }),
    )
    .required('Phone is required'),
  country: yup
    .object()
    .shape({
      value: yup.string(),
    })
    .required('Country is required'),
  city: yup.string().required('City is required'),
  address: yup
    .object()
    .shape({
      label: yup.string().required('Address is required'),
    })
    .nullable()
    .required('Address is required'),
  postcode: yup.string().required('Postcode is required'),
  otpByEmail: yup.string().required(),
  otpBySms: yup.string().required(),
});

export const validationUpdateDoctorProfile = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z -]*$/, 'Invalid name')
    .required('First Name is required'),
  lastName: yup
    .string()
    .matches(/^[A-Za-z -]*$/, 'Invalid name')
    .required('Last Name is required'),
  email: yup.string().required('Email is required'),
  phone: yup
    .string()
    .test('phoneNumberValidation', 'Number is invalid', val =>
      validator.isMobilePhone(val, 'any', { strictMode: true }),
    )
    .required('Phone is required'),
  practitionerType: yup
    .object()
    .shape({
      value: yup.string(),
    })
    .required('Type is required'),
  qualificationNumber: yup
    .number()
    .positive('Must be a positive number')
    .integer('Must be an integer number')
    .typeError('Must be a number')
    .required('Number is required'),
  clinicEmail: yup.string().email('Must be a valid email'),
  clinicName: yup.string().required('Name is required'),
  clinicPhone: yup
    .string()
    .test('phoneNumberValidation', 'Number is invalid', val =>
      validator.isMobilePhone(val, 'any', { strictMode: true }),
    )
    .required('Phone is required'),
  country: yup
    .object()
    .shape({
      value: yup.string(),
    })
    .required('Country is required'),
  city: yup.string().required('City is required'),
  address: yup
    .object()
    .shape({
      label: yup.string().required('Address is required'),
    })
    .nullable()
    .required('Address is required'),
  postcode: yup.string().required('Postcode is required'),
});

export const validationCreateAllergy = yup.object().shape({
  name: yup.string().required('Name is required'),
});

export const validationCreateIngredient = yup.object().shape({
  name: yup.string().required('Name is required'),
  types: yup.array().of(yup.string()).min(1, 'At least one type is required').required('Types are required'),
});

export const validationCreateType = yup.object().shape({
  name: yup.string().required('Name is required'),
  measurement: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
    )
    .min(1, 'At least one measurement is required')
    .required('Measurements are required'),
  packageSizes: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
    )
    .min(1, 'At least one package size is required')
    .required('Package sizes are required'),
  dosagePerUsage: yup.string().optional(),
});

export const validationCreateMedication = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .test('len', 'Must be less than 250 characters long', val => !val || val.length < 250),
  formulation: yup
    .string()
    .required('Formulation is required')
    .test('len', 'Must be less than 250 characters long', val => !val || val.length < 250),
  category: yup.string().required('Category is required'),
  packageSize: yup
    .string()
    .integer('Package Size must be an integer')
    .required('Package Size is required')
    .test('max-int', 'Must be less than 999999', val => !!val && Number(val) < 999999)
    .test('min-int', 'Must be greater than 0', val => !!val && Number(val) > 0),
  strength: yup
    .string()
    .optional()
    .test('len', 'Must be less than 36 characters long', val => !val || val.length < 36),
});

export const validationResendCode = yup.object().shape({
  first: yup.string().required(),
  second: yup.string().required(),
  third: yup.string().required(),
  forth: yup.string().required(),
  fifth: yup.string().required(),
  sixth: yup.string().required(),
});

const mapRules = (map, rule) =>
  Object.keys(map).reduce(
    (newMap, key) => ({
      ...newMap,
      [key]: rule.fields[key],
    }),
    {},
  );

export const validationSchema = yup.lazy(map =>
  yup.object(
    mapRules(
      map,
      yup.object({
        email: yup.string().email('Invalid email address').required(),
        password: yup
          .string()
          .test('len', '8 characters long', val => val?.length >= 8)
          .matches(passwordRegex, '1 uppercase, 1 lowercase, 1 number, 1 special characters'),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref('password'), null], "Passwords don't match")
          .required('Field is required'),
        name: yup.string().required(),
      }),
    ),
  ),
);

export const validationCreatePrescriptionItem = yup.object().shape({
  medicationType: yup.string().required('Medication type is required'),
  medicationObject: yup
    .object()
    .test('is-full-medication-obj', 'Medication is required', function validate(value, ctx) {
      if (!value || !ctx?.parent.medicationType) {
        return false;
      }

      let result = true;
      switch (ctx.parent.medicationType) {
        case MEDICATION_TYPES.COMPOUNDED: {
          const typeHasRequiredValues = !!value.type?.id && value.type?.name;
          if (!typeHasRequiredValues) {
            return this.createError({
              message: 'Type is required',
              path: 'medicationObject.type',
            });
          }

          const dosageIngredientIds = Object.keys(value.ingredientsDosage);
          if (!dosageIngredientIds.length || !value.ingredients.length) {
            return this.createError({
              message: 'Ingredients are required',
              path: 'medicationObject.ingredients',
            });
          }

          const ingredientsAreEqualToDosage = value.ingredients.every(
            ingredient => dosageIngredientIds.includes(ingredient.id) && !!ingredient.name,
          );

          const dosagesAreGreaterThanZero = dosageIngredientIds.every(
            id => Number(value.ingredientsDosage[id]?.value) > 0 && !!value.ingredientsDosage[id]?.measurement,
          );

          if (!dosagesAreGreaterThanZero) {
            return this.createError({
              message: 'Dosages must be greater than zero',
              path: 'medicationObject.ingredientsDosage',
            });
          }

          result = typeHasRequiredValues && ingredientsAreEqualToDosage && dosagesAreGreaterThanZero;
          break;
        }
        case MEDICATION_TYPES.LICENSED:
          result = !!value?.id && !!value?.name && value?.category === MEDICATION_CATEGORIES.LICENSED;
          break;
        case MEDICATION_TYPES.UNLICENSED:
          result = !!value?.id && !!value?.name && value?.category === MEDICATION_CATEGORIES.UNLICENSED;
          break;
        case MEDICATION_TYPES.VITAMIN:
          result = !!value?.id && !!value?.name && value?.category === MEDICATION_CATEGORIES.VITAMIN;
          break;
        default:
          result = false;
      }

      return result;
    }),
  directions: yup
    .string()
    .required('Directions is required')
    .test('len', 'Must be less that 250 characters long', val => val?.length <= 250),
  repeatsNum: yup
    .string()
    .integer('Must be an integer')
    .test('max-int', 'Must be less than 999999', val => !!val && Number(val) < 999999)
    .test('min-int', 'Must be greater positive or 0', val => val !== undefined && Number(val) >= 0),
  quantity: yup
    .string()
    .integer('Must be an integer')
    .test('max-int', 'Must be less than 999999', val => !!val && Number(val) < 999999)
    .test('min-int', 'Must be greater positive or 0', val => val !== undefined && Number(val) >= 0),
  packageSize: yup.string().required('Package size is required'),
  additionalNote: yup.string().test('len', 'Must be less than 250 characters long', val => !val || val.length < 250),
});

export const validationCreatePatientPanel = yup.lazy(map => yup.object(mapPatientRules(map)));

const mapPatientRules = map =>
  Object.keys(map).reduce(newMap => {
    return {
      ...newMap,
      address: yup
        .object()
        .shape({
          label: yup.string().required('Address is required'),
        })
        .nullable()
        .required('Address is required'),
      allergies: yup.array().of(
        yup.object().shape({
          name: yup.string(),
          description: yup.string().max(255, 'Should be less than 255 symbols'),
        }),
      ),
      birthday: yup
        .string()
        .required('Date of birth is required')
        .test('max-date', 'Must be less than now', val => !!val && isAfter(Date.now(), new Date(val))),
      country: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.string().required('Country is required'),
          countryISO: yup.string(),
          key: yup.string(),
        })
        .required('Country is required'),
      city: yup.string().required('City is required'),
      email: yup.string().email().required('Email is required'),
      firstName: yup
        .string()
        .matches(/^[A-Za-z -]*$/, 'Invalid name')
        .required('First name is required'),
      lastName: yup
        .string()
        .matches(/^[A-Za-z ]*$/, 'Invalid name')
        .required('Last name is required'),
      gender: yup.string().required('Gender at birth is required'),
      phone: yup
        .string()
        .test('phoneNumberValidation', 'Number is invalid', val =>
          validator.isMobilePhone(val, 'any', { strictMode: true }),
        )
        .required('Phone is required'),
      postcode: yup.string().required('Postcode is required'),
    };
  }, {});

export const validationDeactivationReason = yup.object().shape({
  option: yup
    .object()
    .shape({
      label: yup.string().required('Option is required'),
    })
    .nullable()
    .required('Option is required'),
  customMessage: yup.string().test('is-custom-message-filled', 'Reason is required', function validate(value, ctx) {
    if (ctx?.parent.option?.value === PRESCRIPTION_DEACTIVATION_OPTIONS.OTHER.value && !value) {
      return false;
    }

    return true;
  }),
});

export const validationRefundOrderReason = yup.object().shape({
  option: yup
    .object()
    .shape({
      label: yup.string().required('Option is required'),
    })
    .nullable()
    .required('Option is required'),
  customMessage: yup.string().test('is-custom-message-filled', 'Reason is required', function validate(value, ctx) {
    if (ctx?.parent.option?.value === ORDER_REFUND_OPTIONS.OTHER.value && !value) {
      return false;
    }

    return true;
  }),
});

export const validationAddresses = yup.object().shape({
  deliveryAddress: yup
    .object()
    .shape({
      label: yup.string().required('Address is required'),
    })
    .nullable()
    .required('Address is required'),
  billingAddress: yup
    .object()
    .shape({
      label: yup.string().required('Address is required'),
    })
    .nullable()
    .required('Address is required'),
});

export const validationCreatePatientProfile = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z -]*$/, 'Invalid name')
    .required('First Name is required'),
  lastName: yup
    .string()
    .matches(/^[A-Za-z -]*$/, 'Invalid name')
    .required('Last Name is required'),
  email: yup.string().required('Email is required'),
  phone: yup.string().required('Phone is required').required('Phone is required'),
  deliveryAddress: yup
    .array()
    .min(1)
    .of(
      yup
        .object()
        .shape({
          address: yup.object().defined().required('Address is required'),
          country: yup.object().defined().required('Country is required'),
          postcode: yup.string().defined().required('Postcode is required'),
        })
        .required('Address is required'),
    )
    .required('Address is required'),
  billingAddress: yup
    .array()
    .min(1)
    .of(
      yup
        .object()
        .shape({
          address: yup.object().defined().required('Address is required'),
          country: yup.object().defined().required('Country is required'),
          postcode: yup.string().defined().required('Postcode is required'),
        })
        .required('Address is required'),
    )
    .required('Address is required'),
  reminderByEmail: yup.string().required(),
  reminderBySms: yup.string().required(),
});

export const validationUpdatePatientProfile = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z -]*$/, 'Invalid name')
    .required('First Name is required'),
  lastName: yup
    .string()
    .matches(/^[A-Za-z -]*$/, 'Invalid name')
    .required('Last Name is required'),
  organizationName: yup.string().matches(/^[A-Za-z -]*$/, 'Invalid name'),
  discount: yup.string().matches(/^[0-9]{1,2}%$/, 'Discount must be in the range from 0 to 99%'),
  defaultEmail: yup.string().required('Email is required'),
  defaultPhone: yup
    .string()
    .test('phoneNumberValidation', 'Number is invalid', val =>
      validator.isMobilePhone(val, 'any', { strictMode: true }),
    )
    .required('Phone is required'),
  deliveryAddresses: yup
    .array()
    .min(1)
    .of(
      yup
        .object()
        .shape({
          address: yup.object().defined().required('Address is required'),
          country: yup.object().defined().required('Country is required'),
          postcode: yup.string().defined().required('Postcode is required'),
        })
        .required('Address is required'),
    )
    .required('Address is required'),
  billingAddresses: yup
    .array()
    .min(1)
    .of(
      yup
        .object()
        .shape({
          address: yup.object().defined().required('Address is required'),
          country: yup.object().defined().required('Country is required'),
          postcode: yup.string().defined().required('Postcode is required'),
        })
        .required('Address is required'),
    )
    .required('Address is required'),
  reminderByEmail: yup.string().required(),
  reminderBySms: yup.string().required(),
});

export const validationCreditCardInfo = yup.object().shape({
  cardNumber: yup
    .string()
    .required('Card number is required')
    .test('correct-format', 'Card number format is not correct', value => {
      return Payment.fns.validateCardNumber(value);
    }),
  cardholderName: yup
    .string()
    .matches(/^[a-zA-Z\s]{1,45}$/, 'Name format is not correct')
    .required('Name is required'),
  expiryDate: yup
    .string()
    .required('Date is required')
    .test('correct-format', 'Date format is not correct', value => {
      return Payment.fns.validateCardExpiry(value);
    }),
  securityCode: yup
    .string()
    .required('CVV is required')
    .test('correct-format', 'CVV format is not correct', value => {
      return Payment.fns.validateCardCVC(value);
    }),
});

export const validationEmailAddress = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Email address is required'),
});

export const validationTranscribePrescription = yup.object().shape({
  prescriptionDate: yup.string().required('Prescription date is required'),
  dateReceived: yup.string().required('Date received is required'),
});

export const validationAddVitamin = yup.object().shape({
  vitamin: yup
    .object()
    .nullable()
    .test('is-full-vitamin-obj', 'Vitamin is required', function validate(value) {
      return !!value?.medicationObject.id && !!value?.medicationObject.name;
    }),
  quantity: yup
    .string()
    .integer('Must be an integer')
    .test('max-int', 'Must be less than 999999', val => !!val && Number(val) < 999999)
    .test('min-int', 'Must be greater positive or 0', val => val !== undefined && Number(val) >= 0),
});
