import { useContext } from 'react';
import { renderRoutes } from 'react-router-config';

import { useRouterPaths } from '@hooks/useRouterPaths';
import { UserContext } from '@contexts/User';
import { ResponsiveFontSizeContext } from '@contexts/ResponsiveFontSize';
import { HintContextProvider } from '@contexts/HintContext';
import { Hint, ToastContainer } from '@components/index';
import Root from '@pages/Root';
import './styles/main.scss';
import 'normalize.css';

const App = () => {
  const { isMobile, isContinueOnMobile } = useContext(ResponsiveFontSizeContext);
  const { profile, isMainLoading, user } = useContext(UserContext);
  const router = useRouterPaths(profile?.type, user?.status, isMainLoading);

  if (!isContinueOnMobile && isMobile) {
    return <Root.MobileAdaptation />;
  }

  return (
    <HintContextProvider>
      <ToastContainer />
      <Hint />
      {renderRoutes(router)}
    </HintContextProvider>
  );
};

export default App;
