import PropTypes from 'prop-types';

const SvgComponent = ({ fill, ...props }) => (
  <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.266 26h1.151v-1.833c0-1.3-.428-2.555-1.204-3.572h1.547a3.145 3.145 0 0 1 3.144 3.143V26H26v-2.262a5.237 5.237 0 0 0-5.24-5.238h-4.73a5.68 5.68 0 0 0-1.367-.167H7.76c-1.526 0-2.99.615-4.068 1.709a5.875 5.875 0 0 0-1.685 4.125L2 26h2.301l.006-1.833c0-.929.364-1.819 1.011-2.475a3.428 3.428 0 0 1 2.441-1.025h6.905c.915 0 1.793.368 2.44 1.025a3.525 3.525 0 0 1 1.012 2.475V26h1.15ZM8.57 15.467a6.824 6.824 0 0 0 5.914-.303 6.294 6.294 0 0 0 3.133.836 6.289 6.289 0 0 0 6.288-6.286 6.285 6.285 0 0 0-8.165-5.999 6.822 6.822 0 0 0-7.17-1.182A6.899 6.899 0 0 0 6.33 4.05a7.007 7.007 0 0 0-1.496 2.271 7.084 7.084 0 0 0 0 5.358c.347.85.855 1.62 1.496 2.27a6.898 6.898 0 0 0 2.24 1.518Zm8.646-9.924a4.193 4.193 0 0 1 4.593 4.171 4.19 4.19 0 0 1-5.481 3.987 7.075 7.075 0 0 0 .888-8.158ZM14.466 12.3a4.571 4.571 0 0 1-3.255 1.367A4.571 4.571 0 0 1 7.957 12.3 4.7 4.7 0 0 1 6.609 9a4.7 4.7 0 0 1 1.348-3.3 4.571 4.571 0 0 1 3.254-1.367c1.221 0 2.392.492 3.255 1.367A4.7 4.7 0 0 1 15.814 9a4.7 4.7 0 0 1-1.348 3.3Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: 'var(--colors-santas-gray)',
};

export default SvgComponent;
