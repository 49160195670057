import { memo, useEffect, useMemo, useState } from 'react';
import { formatDistance, millisecondsToHours } from 'date-fns';
import PropTypes from 'prop-types';

import useDevice from '@hooks/useDevice';

import DefaultLayout from '@patient/components/Elements/MedicationCard/DefaultLayout';
import HorizontalLayout from '@patient/components/Elements/MedicationCard/HorizontalLayout';

const MedicationCard = ({ disabled, horizontal, onAdd, onOpenCart, medication, prescription, added, ...props }) => {
  const [quantity, setQuantity] = useState(medication.quantity);
  const isNew = !disabled && millisecondsToHours(Date.now() - new Date(Number(prescription.updatedAt))) / 24 < 31;
  const orderWithin = useMemo(() => formatDistance(new Date(), medication.availableUntil), [medication.availableUntil]);
  const isDisabled = useMemo(() => disabled || orderWithin.includes('about'), [added, disabled, orderWithin]);

  const { isMobile } = useDevice();

  const handleClickAdd = () => {
    onAdd(medication.id, quantity);
  };

  const handleOpenCart = () => {
    onOpenCart(true);
  };

  useEffect(() => {
    setQuantity(medication.quantity);
  }, [medication.quantity]);

  const Component = props => {
    if (horizontal || isMobile) {
      return <HorizontalLayout {...props} />;
    }

    return <DefaultLayout {...props} />;
  };

  return (
    <Component
      added={added}
      onChangeQuantity={setQuantity}
      quantity={quantity}
      recent={isNew}
      disabled={isDisabled}
      onAdd={handleClickAdd}
      onOpenCart={handleOpenCart}
      medication={medication}
      createdAt={prescription.updatedAt}
      orderWithin={orderWithin}
      {...props}
    />
  );
};

MedicationCard.propTypes = {
  horizontal: PropTypes.bool,
  disabled: PropTypes.bool,
  added: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onOpenCart: PropTypes.func.isRequired,
  medication: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    availableUntil: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  }).isRequired,
  prescription: PropTypes.shape({
    id: PropTypes.string.isRequired,
    updatedAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  }).isRequired,
};

MedicationCard.defaultProps = {
  horizontal: false,
  disabled: false,
};

export default memo(MedicationCard);
