import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { PencilSecondary } from '@assets/icons';

const Chip = ({ label, dosage, measurement }) => (
  <motion.div
    className="flex flex-row bg-iguana-green items-center justify-between p-2 rounded-md pointer-events-none"
    initial={{
      opacity: 0,
      scaleY: 0,
      scaleX: 0,
    }}
    animate={{
      opacity: 1,
      scaleY: 1,
      scaleX: 1,
      transition: { duration: 0.2 },
    }}
    exit={{
      opacity: 0,
      scaleY: 0,
      scaleX: 0,
      transition: { duration: 0.2 },
    }}
  >
    <span className="mr-2.5 text-white">
      {`${label}`}
      {dosage ? ` • ${dosage}${measurement}` : ''}
    </span>
    <div className="pb-1">
      <PencilSecondary className="" />
    </div>
  </motion.div>
);

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  measurement: PropTypes.string.isRequired,
  dosage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Chip.defaultProps = {
  dosage: 0,
};

export default React.memo(Chip);
