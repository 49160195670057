import { Button } from '@components/Form';

import { ShoppingBag } from '@patient/assets/icons';
import PropTypes from 'prop-types';

const CardButton = ({ added, disabled, onClick }) => {
  if (added) {
    return (
      <Button
        className="w-full border-2 px-4 py-4 text-iguana-green font-semibold rounded-md border-iguana-green"
        isDisabled={disabled}
        variant="custom"
        onClick={onClick}
      >
        <span className="w-auto whitespace-nowrap">Go to cart</span>
      </Button>
    );
  }

  return (
    <Button
      className="w-full"
      isDisabled={disabled}
      variant="primary"
      LeftIcon={ShoppingBag}
      iconProps={{ stroke: disabled ? 'var(--colors-silver-sand)' : 'white' }}
      onClick={onClick}
    >
      <span className="whitespace-nowrap">Add to cart</span>
    </Button>
  );
};

CardButton.propTypes = {
  added: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

CardButton.defaultProps = {
  added: false,
  disabled: false,
};

export default CardButton;
