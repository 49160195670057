import React from 'react';

const SvgComponent = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 4.9H9V3.1H5V4.9ZM9.1 5V9H10.9V5H9.1ZM9 9.1H5V10.9H9V9.1ZM4.9 9V5H3.1V9H4.9ZM5 9.1C4.94477 9.1 4.9 9.05523 4.9 9H3.1C3.1 10.0493 3.95066 10.9 5 10.9V9.1ZM9.1 9C9.1 9.05523 9.05523 9.1 9 9.1V10.9C10.0493 10.9 10.9 10.0493 10.9 9H9.1ZM9 4.9C9.05523 4.9 9.1 4.94477 9.1 5H10.9C10.9 3.95066 10.0493 3.1 9 3.1V4.9ZM5 3.1C3.95066 3.1 3.1 3.95066 3.1 5H4.9C4.9 4.94477 4.94477 4.9 5 4.9V3.1ZM5 14.9H9V13.1H5V14.9ZM9.1 15V19H10.9V15H9.1ZM9 19.1H5V20.9H9V19.1ZM4.9 19V15H3.1V19H4.9ZM5 19.1C4.94477 19.1 4.9 19.0552 4.9 19H3.1C3.1 20.0493 3.95066 20.9 5 20.9V19.1ZM9.1 19C9.1 19.0552 9.05523 19.1 9 19.1V20.9C10.0493 20.9 10.9 20.0493 10.9 19H9.1ZM9 14.9C9.05523 14.9 9.1 14.9448 9.1 15H10.9C10.9 13.9507 10.0493 13.1 9 13.1V14.9ZM5 13.1C3.95066 13.1 3.1 13.9507 3.1 15H4.9C4.9 14.9448 4.94477 14.9 5 14.9V13.1ZM15 4.9H19V3.1H15V4.9ZM19.1 5V9H20.9V5H19.1ZM19 9.1H15V10.9H19V9.1ZM14.9 9V5H13.1V9H14.9ZM15 9.1C14.9448 9.1 14.9 9.05523 14.9 9H13.1C13.1 10.0493 13.9507 10.9 15 10.9V9.1ZM19.1 9C19.1 9.05523 19.0552 9.1 19 9.1V10.9C20.0493 10.9 20.9 10.0493 20.9 9H19.1ZM19 4.9C19.0552 4.9 19.1 4.94477 19.1 5H20.9C20.9 3.95066 20.0493 3.1 19 3.1V4.9ZM15 3.1C13.9507 3.1 13.1 3.95066 13.1 5H14.9C14.9 4.94477 14.9448 4.9 15 4.9V3.1ZM15 14.9H19V13.1H15V14.9ZM19.1 15V19H20.9V15H19.1ZM19 19.1H15V20.9H19V19.1ZM14.9 19V15H13.1V19H14.9ZM15 19.1C14.9448 19.1 14.9 19.0552 14.9 19H13.1C13.1 20.0493 13.9507 20.9 15 20.9V19.1ZM19.1 19C19.1 19.0552 19.0552 19.1 19 19.1V20.9C20.0493 20.9 20.9 20.0493 20.9 19H19.1ZM19 14.9C19.0552 14.9 19.1 14.9448 19.1 15H20.9C20.9 13.9507 20.0493 13.1 19 13.1V14.9ZM15 13.1C13.9507 13.1 13.1 13.9507 13.1 15H14.9C14.9 14.9448 14.9448 14.9 15 14.9V13.1Z"
      fill="#9B9DAE"
    />
  </svg>
);

export default SvgComponent;
