import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRowSelect, useTable } from 'react-table';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { ResponsiveFontSizeContext } from '@contexts/ResponsiveFontSize';

import TableContext from '@patient/contexts/TableContext';
import './Table.scss';
import Checkbox from './Checkbox';

const getFullColumnsWidth = cols => {
  return cols.reduce((prev, { offsetWidth }) => prev + offsetWidth, 0);
};

const Table = ({
  columns,
  minCellWidth,
  data,
  rightHeader,
  templateColumns,
  onRowClick,
  selectedRow,
  compactHeaders,
  variant,
  disableResize,
  verticalSeparator,
  headerBottomBorder,
  withHeader,
  withCheckboxColumn,
  highlightedLastRow,
}) => {
  const { htmlFontSize } = useContext(ResponsiveFontSizeContext);
  const [tableHeight, setTableHeight] = useState(`${80 / htmlFontSize}rem`);
  const [activeIndex, setActiveIndex] = useState(null);
  const tableElement = useRef(null);
  const columnsRef = useRef([]);

  const { setSelectedRows } = useContext(TableContext);

  /* eslint-disable react/prop-types */
  const { getTableProps, getTableBodyProps, headerGroups, allColumns, rows, prepareRow, selectedFlatRows } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          accessor: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className="w-full flex items-center justify-center">
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div className="w-full flex items-center justify-center">
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    },
  );
  /* eslint-enable react/prop-types */

  const lastRow = useMemo(() => data[data.length - 1], []);

  const mouseDown = index => {
    setActiveIndex(index);
  };

  const mouseMove = useCallback(
    e => {
      const filteredColumns = columnsRef.current.filter(Boolean);

      const initialWidth = getFullColumnsWidth(filteredColumns);
      const gridColumns = columns.map((__col, i) => {
        if (!filteredColumns[i]) {
          return '0rem';
        }

        if (i === activeIndex) {
          const diff = initialWidth - filteredColumns[i].offsetWidth;
          const width = e.clientX - filteredColumns[i].offsetLeft;

          if (width >= minCellWidth && diff + width >= tableElement.current.offsetWidth) {
            return `${width / htmlFontSize}rem`;
          }
        }
        return `${filteredColumns[i].offsetWidth / htmlFontSize}rem`;
      });

      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
    },
    [activeIndex, columns, minCellWidth, htmlFontSize],
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  const initialHeaderStyle = useMemo(() => {
    return data.length ? {} : { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: 'none' };
  }, [data]);

  useEffect(() => {
    allColumns[0].toggleHidden(!withCheckboxColumn);
  }, [templateColumns, columns, withCheckboxColumn]);

  useEffect(() => {
    setSelectedRows(selectedFlatRows);
  }, [selectedFlatRows]);

  useEffect(() => {
    setTableHeight(`${tableElement.current.offsetHeight / htmlFontSize}rem`);
  }, [htmlFontSize]);

  return (
    <div className="w-full">
      <div className={clsx('table-wrapper', variant)} style={initialHeaderStyle}>
        <table
          {...getTableProps()}
          className={clsx(
            'resizeable-table',
            'commonTable',
            verticalSeparator && 'verticalSeparator',
            compactHeaders && 'compactTable',
            headerBottomBorder && 'headerBottomBorder',
            variant,
          )}
          ref={tableElement}
        >
          {withHeader && (
            <thead {...headerGroups[0].getHeaderGroupProps()}>
              <tr style={{ gridTemplateColumns: templateColumns }}>
                {headerGroups[0].headers.map((column, i) => (
                  <th
                    ref={el => {
                      columnsRef.current[i] = el;
                    }}
                    key={i} // eslint-disable-line react/no-array-index-key
                  >
                    {column.render('Header')}
                    {!disableResize && (
                      <div
                        style={{ height: tableHeight }}
                        onMouseDown={() => mouseDown(i)}
                        className={`resize-handle ${activeIndex === i ? 'active' : 'idle'}`}
                      />
                    )}
                    {!!rightHeader && i === headerGroups[0].headers.length - 1 && (
                      <div className="absolute top-6 right-6">{rightHeader}</div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);

              const isSelected = selectedRow && row.original.id && selectedRow?.id === row.original.id && 'bg-black';

              return (
                <tr
                  style={{ gridTemplateColumns: templateColumns }}
                  className={clsx(
                    'transition-colors duration-300',
                    !!onRowClick && 'cursor-pointer hover:bg-white-lilac',
                  )}
                  onClick={onRowClick ? () => onRowClick(row) : null}
                  key={i} // eslint-disable-line react/no-array-index-key
                >
                  {row.cells.map((cell, j) => {
                    const isHighlighted = highlightedLastRow && lastRow === row.original;

                    return (
                      <td
                        {...cell.getCellProps()}
                        key={j} // eslint-disable-line react/no-array-index-key
                        className={clsx(
                          'transition-colors duration-300 truncate',
                          isSelected && 'bg-anti-flash-white',
                          isHighlighted && 'bg-anti-flash-white',
                          j === 0 && 'relative',
                        )}
                      >
                        <AnimatePresence>
                          {isSelected && j === 0 && (
                            <motion.div
                              className="bg-iguana-green left-0 top-0 absolute h-full w-1"
                              initial={{
                                opacity: 0,
                                scaleY: 0,
                              }}
                              animate={{
                                opacity: 1,
                                scaleY: 1,
                                transition: { duration: 0.3 },
                              }}
                              exit={{
                                opacity: 0,
                                scaleY: 0,
                                transition: { duration: 0.3 },
                              }}
                            />
                          )}
                        </AnimatePresence>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

Table.propTypes = {
  minCellWidth: PropTypes.number,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
    }),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.any),
  selectedRow: PropTypes.shape({
    id: PropTypes.string,
  }),
  rightHeader: PropTypes.node,
  templateColumns: PropTypes.string.isRequired,
  onRowClick: PropTypes.func,
  compactHeaders: PropTypes.bool,
  variant: PropTypes.string,
  disableResize: PropTypes.bool,
  verticalSeparator: PropTypes.bool,
  headerBottomBorder: PropTypes.bool,
  selectedRows: PropTypes.arrayOf(PropTypes.shape({})),
  setSelectedRows: PropTypes.func,
  withHeader: PropTypes.bool,
  withCheckboxColumn: PropTypes.bool,
  highlightedLastRow: PropTypes.bool,
};

Table.defaultProps = {
  minCellWidth: 60,
  data: [],
  rightHeader: null,
  onRowClick: null,
  selectedRow: null,
  selectedRows: null,
  setSelectedRows: null,
  compactHeaders: false,
  variant: '',
  disableResize: true, // TODO: this is temporary change, because disableResize is not working now
  verticalSeparator: true,
  headerBottomBorder: true,
  withHeader: true,
  withCheckboxColumn: false,
  highlightedLastRow: false,
};

export default Table;
