import { createContext, useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

export const ToastContext = createContext({
  isOpen: false,
  message: '',
  type: '',
  setHideTime: () => {},
  open: () => {},
  close: () => {},
});

const ToastProvider = ({ children }) => {
  const timeoutRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [hideTime, setHideTime] = useState(4000);

  const [message, setMessage] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const open = useCallback((message, type) => {
    setMessage(message);
    setType(type);
    setIsOpen(true);

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, hideTime);
  }, []);

  const close = useCallback(() => {
    clearTimeout(timeoutRef.current);
    setIsOpen(false);
  }, []);

  const value = {
    open,
    close,
    isOpen,
    setHideTime,
    message,
    type,
  };

  return <ToastContext.Provider value={{ ...value }}>{children}</ToastContext.Provider>;
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ToastProvider;
