import { useState } from 'react';

const useSafeState = (initialValue, isUnmountedRef) => {
  const [value, setValue] = useState(initialValue);

  const safeSetValue = newValue => {
    if (!isUnmountedRef.current) {
      setValue(newValue);
    }
  };

  return [value, safeSetValue];
};

export default useSafeState;
