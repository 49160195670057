import loadable from '@loadable/component';
import { loadableOptions } from '@utils/helpers';

const Auth = {
  SignIn: loadable(() => import('./SignIn'), loadableOptions),
  SignUp: loadable(() => import('./SignUp'), loadableOptions),
  ResetPassword: loadable(() => import('./ResetPassword'), loadableOptions),
  CreateProfile: loadable(() => import('./CreateProfile'), loadableOptions),
};

export default Auth;
