import { renderRoutes } from 'react-router-config';
import { useRouteMatch } from 'react-router-dom';

import { Sidebar } from '@components/index';
import { routerType } from '@router/types';
import { COMMON, RESET_MEMORABLE_QUESTION, TERMS_AND_CONDITIONS, PRIVACY_POLICY } from '@router/paths';

const RootLayout = ({ route }) => {
  const isPrescription = useRouteMatch(COMMON.PRESCRIPTION);
  const isTermsAndConditions = useRouteMatch(TERMS_AND_CONDITIONS);
  const isPrivacyPolicy = useRouteMatch(PRIVACY_POLICY);
  const isResetMemorableQuestion = useRouteMatch(RESET_MEMORABLE_QUESTION);

  return (
    <div className="flex flex-row h-full w-full bg-white">
      {!isPrescription && !isResetMemorableQuestion && !isTermsAndConditions && !isPrivacyPolicy && <Sidebar />}
      <>{renderRoutes(route.routes)}</>
    </div>
  );
};

RootLayout.propTypes = routerType;

export default RootLayout;
