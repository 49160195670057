import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { add, format } from 'date-fns';
import { countries } from 'countries-list';

import { Button, Counter, Input, Select, SidePanel } from '@components/index';
import { Cross as BigCross } from '@assets/icons';
import { ADDRESS_TYPE, DELIVERY_TYPES, TIME_OFFSET } from '@utils/consts';
import { useProfile } from '@hooks/profile';
import { getMedicationName } from '@utils/medication';

import { Cross } from '@patient/assets/icons';
import { generateDeliveryOptions } from '@patient/utils/generateData';
import ItemsTable from './ItemsTable';

const templateColumns = '2fr 1fr 0.5fr 0.1fr';
const initialDeliveryOptions = generateDeliveryOptions({ count: 2 });

const allCountries = Object.keys(countries).map(countryISO => ({
  label: countries[countryISO].name,
  value: countryISO,
  key: countryISO,
  countryISO,
}));

const CartModal = ({ visible, profileId, setIsVisible, data, onDelete, onSubmit, onChangeQuantity }) => {
  const orderItems = Object.values(data).map(item => ({ ...item }));
  const orderItemsTotalPrice = orderItems.reduce(
    (acc, currentItem) => Math.round((acc + currentItem.price * currentItem.quantity) * 100) / 100,
    0,
  );
  const tableItemsData = [
    ...orderItems,
    {
      id: 'totalItem',
      name: 'Total',
      quantity: 1,
      price: orderItemsTotalPrice,
    },
  ];

  const deliveryOptions = useMemo(() => {
    if (orderItemsTotalPrice < 200) {
      return initialDeliveryOptions;
    }

    return [
      {
        value: DELIVERY_TYPES.ROYAL,
        duration: null,
        price: null,
        label: DELIVERY_TYPES.ROYAL,
      },
    ];
  }, [orderItemsTotalPrice]);

  const inputRef = useRef();
  const [profile, { isLoading }] = useProfile(profileId);
  const [country, setCountry] = useState('');
  const [deliveryOption, setDeliveryOption] = useState(deliveryOptions[0]);

  const order = {
    totalPrice: Math.round((orderItemsTotalPrice + deliveryOption.price) * 100) / 100,
    createdAt: new Date(),
    delivery: {
      price: deliveryOption.price,
      duration: deliveryOption.duration,
    },
  };

  const despatchDate = useMemo(
    () => format(add(new Date(order.createdAt), { days: TIME_OFFSET.DELIVERY_DISPATCH }), 'PP'),
    [],
  );

  const handleDelete = useCallback(id => {
    onDelete(id);
  }, []);

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        Header: 'Product / composition',
        accessor: 'product',
        Cell: ({ row }) => (
          <div className="h-full flex flex-row w-full align-middle items-center">
            <span className="text-davy-grey">{getMedicationName(row.original) || row.original.name}</span>
          </div>
        ),
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: ({ row }) => (
          <Counter
            value={row.original.quantity}
            onChange={value => onChangeQuantity(row.original.id, value)}
            maxValue={row.original.maxPackageCount}
            className="-ml-6"
          />
        ),
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ row }) => (
          <div className="flex flex-row h-full items-center justify-start">
            <span className="text-davy-grey ml-1">
              {Math.round(row.original.price * row.original.quantity * 100) / 100}
            </span>
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ row }) => (
          <div className="flex h-full items-center justify-end" onClick={() => handleDelete(row.original.id)}>
            <Cross className="cursor-pointer hover:bg-anti-flash-white rounded-full" />
          </div>
        ),
      },
    ],
    [onChangeQuantity, handleDelete],
  );
  /* eslint-enable react/prop-types */

  const InfoBlock = useCallback(
    ({ className, title, description }) => (
      <dl className={className}>
        <dt className="text-sm font-normal text-santas-gray">{title}</dt>
        <dd className="text-base font-medium text-davy-grey mt-2">{description}</dd>
      </dl>
    ),
    [],
  );

  const handleSubmit = () => {
    const submittableItemsData = [
      ...tableItemsData.slice(0, -1),
      {
        id: 'deliveryItem',
        name: 'Delivery',
        quantity: 1,
        price: order.delivery.price,
      },
      ...tableItemsData.slice(-1),
    ];

    submittableItemsData[submittableItemsData.length - 1].price = order.totalPrice;

    const submittableOrder = { ...order };

    submittableOrder.delivery.option = deliveryOption.value;
    submittableOrder.delivery.country = country;
    submittableOrder.note = inputRef.current?.value;

    onSubmit({ order: submittableOrder, orderItems: submittableItemsData, userId: profile.id });
  };

  const isError =
    (deliveryOption.label === DELIVERY_TYPES.COURIER || deliveryOption.label === DELIVERY_TYPES.INTERNATIONAL) &&
    'Call the patient care team to place the order';

  useEffect(() => {
    if (!Object.values(data).length) {
      setIsVisible(false);
    }
  }, [Object.values(data).length]);

  useEffect(() => {
    setDeliveryOption(deliveryOptions[0]);
  }, [deliveryOptions]);

  useEffect(() => {
    const profileCountry = profile?.address.find(address => address.type === ADDRESS_TYPE.DELIVERY)?.country;

    if (profileCountry) {
      setCountry(allCountries.find(_country => _country.countryISO === profileCountry));
    }
  }, [profile]);

  if (isLoading) {
    return null;
  }

  return (
    <SidePanel className={clsx('cart-panel')} openModal={visible} setOpenModal={setIsVisible}>
      <article className={clsx('form p-16 bg-white overflow-y-scroll justify-between')}>
        <header className="flex flex-row justify-between items-center">
          <h1 className="text-h3 font-roboto-medium">Order details</h1>
          <BigCross
            onClick={() => setIsVisible(false)}
            className="cursor-pointer stroke-current stroke-silver-sand w-4 h-4"
          />
        </header>
        <div className="mt-8 flex flex-row items-center">
          <div className="w-5 h-5 rounded-xl bg-iguana-green opacity-60" />
          <p className="text-lg font-semibold text-iguana-green ml-2.5">Despatch date: {despatchDate}</p>
        </div>
        <div className="grid grid-cols-4">
          <InfoBlock className="mt-6" title="Request date" description="22/05/2020" />
          <InfoBlock className="mt-6" title="Customer message" description="1 capsule in the morning" />
        </div>
        <ItemsTable columns={columns} data={tableItemsData} templateColumns={templateColumns} />
        <p className="text-2xl mt-12">Delivery estimates</p>
        <Select
          className="mt-6"
          value={country}
          options={allCountries}
          onChange={setCountry}
          isSearchable
          label="Country"
          placeholder="Select country"
        />
        <Select
          error={isError}
          className="mt-6"
          value={deliveryOption}
          options={deliveryOptions}
          onChange={setDeliveryOption}
          isSearchable
          label="Delivery option"
          placeholder="Select delivery option"
        />
        <p className="text-2xl mt-12">Order note</p>
        <p className={clsx('small-title ml-4 mt-6 text-base')}>Additional Note (optional)</p>
        <Input ref={inputRef} className="mt-2" placeholder="Write a note" />
        <p className="text-2xl mt-12">Sub Total</p>
        <div className="flex mt-6 flex-row justify-between items-center">
          <p className="ml-4 text-lg">Items Total</p>
          <p className="ml-4 text-lg w-28">£{orderItemsTotalPrice}</p>
        </div>
        {order.delivery?.price && (
          <div className="flex mt-6 flex-row justify-between items-center">
            <p className="ml-4 text-lg">Delivery</p>
            <p className="ml-4 text-lg w-28">£{order.delivery.price}</p>
          </div>
        )}
        <div className="border-b mt-6" />
        <div className="flex flex-row justify-between items-center mt-6">
          <p className="text-2xl">Total</p>
          <p className="text-2xl w-28">£{order.totalPrice}</p>
        </div>
        <Button isDisabled={!!isError} onClick={handleSubmit} className="px-6 py-3 mt-10" variant="primary">
          Check out
        </Button>
      </article>
    </SidePanel>
  );
};

CartModal.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  setIsVisible: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  profileId: PropTypes.string.isRequired,
};

CartModal.defaultProps = {
  visible: false,
};

export default CartModal;
