import clsx from 'clsx';
import PropTypes from 'prop-types';

const SvgComponent = ({ className, ...props }) => (
  <svg
    className={clsx('w-10 h-10', className)}
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#BABAC6">
      <path d="m11.569 18.25-6.022 6.022a5.956 5.956 0 0 0-1.73 4.18.954.954 0 1 0 1.909 0c0-1.054.427-2.085 1.171-2.83l6.021-6.021a.954.954 0 1 0-1.35-1.35Z" />
      <path d="m33.935 18.006 1.397-1.397a9.666 9.666 0 0 0 2.85-6.88c0-5.365-4.365-9.729-9.73-9.729a9.666 9.666 0 0 0-6.879 2.85L2.85 21.573A9.665 9.665 0 0 0 0 28.453c0 5.364 4.364 9.729 9.73 9.729a9.666 9.666 0 0 0 6.879-2.85l1.397-1.397C19.793 38.747 24.332 42 29.59 42c6.842 0 12.41-5.567 12.41-12.41-.001-5.258-3.254-9.797-8.066-11.584ZM22.923 4.199a7.878 7.878 0 0 1 5.53-2.29c4.312 0 7.82 3.508 7.82 7.82a7.878 7.878 0 0 1-2.29 5.53l-9.165 9.164-11.06-11.06L22.924 4.2ZM15.26 33.982a7.878 7.878 0 0 1-5.53 2.29c-4.312 0-7.82-3.507-7.82-7.82 0-2.057.835-4.073 2.29-5.529l8.21-8.21 11.06 11.06-8.21 8.21Zm14.332 6.109c-4.751 0-8.806-3.136-10.073-7.669L32.422 19.52c4.533 1.266 7.669 5.32 7.669 10.072 0 5.79-4.71 10.5-10.5 10.5Z" />
      <path d="M34.644 23.19 23.19 34.643a.954.954 0 1 0 1.35 1.35l11.454-11.455a.954.954 0 1 0-1.35-1.35Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h42v42H0z" />
      </clipPath>
    </defs>
  </svg>
);

SvgComponent.propTypes = {
  className: PropTypes.string,
};

SvgComponent.defaultProps = {
  className: '',
};

export default SvgComponent;
