import PropTypes from 'prop-types';

const SvgComponent = ({ fill, ...props }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.5833 4.66675H6.41667C5.77233 4.66675 5.25 5.18908 5.25 5.83341V24.5001C5.25 25.1444 5.77233 25.6667 6.41667 25.6667H21.5833C22.2277 25.6667 22.75 25.1444 22.75 24.5001V5.83341C22.75 5.18908 22.2277 4.66675 21.5833 4.66675Z"
      stroke={fill}
      fill="transparent"
      strokeWidth="2.2"
      strokeLinejoin="round"
    />
    <path
      d="M9.33331 20.4167H14M10.5 2.33337V5.83337V2.33337ZM17.5 2.33337V5.83337V2.33337ZM9.33331 11.0834H18.6666H9.33331ZM9.33331 15.75H16.3333H9.33331Z"
      stroke={fill}
      strokeWidth="2.2"
      fill="transparent"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: 'var(--colors-santas-gray)',
};

export default SvgComponent;
