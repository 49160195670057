import { useRef } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';

import Cross from '@assets/icons/Cross';
import { useOutside } from '@hooks/useOutside';
import useDevice from '@hooks/useDevice';

const customStyles = {
  content: {
    width: '100%',
    height: '100%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    overflow: 'initial',
    background: 'transparent',
  },
  overlay: {
    zIndex: 30,
    background: 'transparent',
    width: '100%',
    height: '100%',
  },
};

const CustomModal = ({ openModal, setOpenModal, className, children }) => {
  const modalRef = useRef();
  const { isMobile } = useDevice();

  useOutside(modalRef, isMobile ? setOpenModal : null);

  return (
    <AnimatePresence>
      {openModal && (
        <Modal isOpen={openModal} ariaHideApp={false} style={customStyles} onRequestClose={() => setOpenModal(false)}>
          <motion.div
            className="bg-black w-full h-full flex justify-center items-center bg-opacity-40"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                type: 'easyInOut',
                stiffness: 300,
              },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.4 },
            }}
          >
            <motion.div
              className="relative z-50 w-full laptop:w-auto px-5 laptop:px-0"
              initial={{
                opacity: 0,
                y: 60,
                scale: 0.5,
              }}
              animate={{
                opacity: 1,
                y: 0,
                scale: 1,
              }}
              exit={{
                opacity: 0,
                scale: 0.5,
                transition: { duration: 0.6 },
              }}
            >
              <div ref={modalRef} className={clsx('shadow', className)}>
                <Cross onClick={() => setOpenModal(false)} className="absolute right-8 top-8 cursor-pointer" />
                {children}
              </div>
            </motion.div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
};

CustomModal.propTypes = {
  className: PropTypes.string,
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

CustomModal.defaultProps = {
  className: '',
};

export default CustomModal;
