import * as React from 'react';

function CheckIcon(props) {
  return (
    <svg fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 17.333A8.333 8.333 0 119 .666a8.333 8.333 0 010 16.667zm0-1.667A6.667 6.667 0 109 2.333a6.667 6.667 0 000 13.333zm-.831-3.333L4.633 8.797 5.81 7.619l2.358 2.357 4.713-4.714L14.06 6.44l-5.892 5.893z"
        fill="#6AB97A"
      />
    </svg>
  );
}

export default CheckIcon;
