import PropTypes from 'prop-types';

const SvgComponent = ({ disabled, ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={disabled ? 0.2 : 1}
      d="M12.0833 14.168L7.91659 10.0013L12.0833 5.83464"
      stroke="#333333"
      strokeWidth="1.8"
    />
  </svg>
);

SvgComponent.propTypes = {
  disabled: PropTypes.bool,
};

SvgComponent.defaultProps = {
  disabled: false,
};

export default SvgComponent;
