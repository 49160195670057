import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Button } from '@components/index';
import { DownArrow, UpArrow } from '@assets/icons';
import { ResponsiveFontSizeContext } from '@contexts/ResponsiveFontSize';

import styles from './ViewMoreBlock.module.scss';

const ViewMoreBlock = ({ children, expanded, className, visibleRowsCount }) => {
  const { htmlFontSize } = useContext(ResponsiveFontSizeContext);

  const [isExpanded, setIsExpanded] = useState(expanded);
  const [isShowButton, setIsShowButton] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  const visibleHeight = useMemo(() => visibleRowsCount * htmlFontSize * 1.5, []);

  const measuredRef = useCallback(node => {
    if (node !== null) {
      const { height } = node.getBoundingClientRect();

      setContentHeight(height);

      if (height > visibleHeight) {
        setIsShowButton(true);
      }
    }
  }, []);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const buttonContent = useMemo(() => {
    if (isExpanded) {
      return (
        <>
          <span className="text-santas-gray text-default font-normal">Hide</span>
          <UpArrow />
        </>
      );
    }

    return (
      <>
        <span className="font-medium text-default text-iguana-green">View more</span>
        <DownArrow />
      </>
    );
  }, [isExpanded]);

  return (
    <div>
      <p
        style={contentHeight ? { height: isExpanded ? contentHeight : visibleHeight } : null}
        className={clsx(
          'transition-all text-ellipsis overflow-hidden',
          isShowButton && !isExpanded && styles.text,
          className,
        )}
        ref={measuredRef}
      >
        {children}
      </p>
      {isShowButton && (
        <Button className="-ml-4 -mb-4" variant="empty" onClick={() => setIsExpanded(!isExpanded)}>
          {buttonContent}
        </Button>
      )}
    </div>
  );
};

ViewMoreBlock.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool,
  visibleRowsCount: PropTypes.number,
  className: PropTypes.string,
};

ViewMoreBlock.defaultProps = {
  children: '',
  className: '',
  expanded: false,
  visibleRowsCount: 2,
};

export default memo(ViewMoreBlock);
