import config from '@config/config';

import ApiService from './api';

class UserApi extends ApiService {
  constructor() {
    if (UserApi.instance) {
      return UserApi.instance;
    }

    super(config.authApiUrl);

    UserApi.instance = this;
  }

  async getUserById(userId) {
    const response = await this.http.get(`/accounts/${this.mainAccountId}/users/${userId}`);

    return response.data;
  }

  async createSecurityAnswer(userId, data, options) {
    const { token } = options || {};

    const response = await this.http.post(
      `/accounts/${this.mainAccountId}/users/${userId}/securityAnswer`,
      {
        accountId: this.mainAccountId,
        userId,
        questionId: data.securityQuestion,
        answer: data.securityAnswer,
      },
      {
        headers: {
          'x-reset-token': token || '',
        },
      },
    );

    return response.data;
  }

  async resetSecurityAnswer(userId) {
    const response = await this.http.delete(`/accounts/${this.mainAccountId}/users/${userId}/securityAnswer`);

    return response.data;
  }

  async createUserConfig(userId, data) {
    const response = await this.http.post(`/accounts/${this.mainAccountId}/users/${userId}/config`, {
      accountId: this.mainAccountId,
      userId,
      name: 'main',
      key: data.key,
      value: data.value,
    });

    return response.data;
  }

  async getUserConfig(userId) {
    const response = await this.http.get(`/accounts/${this.mainAccountId}/users/${userId}/config`);

    return response.data;
  }

  async deleteUserConfig(userId, key) {
    const response = await this.http.delete(
      `/accounts/${this.mainAccountId}/users/${userId}/config?name=main&key=${key}`,
    );

    return response.data;
  }

  async updateUserById(userId, data) {
    const response = await this.http.put(`/accounts/${this.mainAccountId}/users/${userId}`, data);

    return response.data;
  }
}

const userApiInstance = new UserApi();
export default userApiInstance;
