import PropTypes from 'prop-types';

const SvgComponent = ({ fill, ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.5 18.5V17.7H4.7V18.5H5.5ZM18.5 5.5V4.7H17.7V5.5H18.5ZM8.5 20.7C7.28497 20.7 6.3 19.715 6.3 18.5H4.7C4.7 20.5987 6.40132 22.3 8.5 22.3V20.7ZM18.5 20.7H8.5V22.3H18.5V20.7ZM20.7 18.5C20.7 19.715 19.715 20.7 18.5 20.7V22.3C20.5987 22.3 22.3 20.5987 22.3 18.5H20.7ZM20.7 8.5V18.5H22.3V8.5H20.7ZM18.5 6.3C19.715 6.3 20.7 7.28497 20.7 8.5H22.3C22.3 6.40132 20.5987 4.7 18.5 4.7V6.3ZM17.7 5.5V15.5H19.3V5.5H17.7ZM17.7 15.5C17.7 16.715 16.715 17.7 15.5 17.7V19.3C17.5987 19.3 19.3 17.5987 19.3 15.5H17.7ZM15.5 17.7H5.5V19.3H15.5V17.7Z"
      fill={fill}
    />
    <rect x="2.5" y="2.5" width="16" height="16" rx="3" stroke={fill} strokeWidth="1.6" />
    <path
      d="M6.5 16.4994V15.1992C6.5 14.0946 7.39543 13.1992 8.5 13.1992H12.5C13.6046 13.1992 14.5 14.0946 14.5 15.1992V16.4994"
      stroke={fill}
      strokeWidth="1.6"
    />
    <ellipse cx="10.4978" cy="8.25018" rx="2.85714" ry="2.75018" stroke="#9B9DAE" strokeWidth="1.6" />
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: 'var(--colors-santas-gray)',
};

export default SvgComponent;
