import * as React from 'react';

function DoctorMaskIcon({ ...props }) {
  return (
    <svg fill="none" viewBox="0 0 28 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m14.566.64 9.05 2.264c.52.128.884.595.884 1.131v1.13h1.167A2.34 2.34 0 0 1 28 7.5V11a3.5 3.5 0 0 1-3.5 3.5h-.491a7.001 7.001 0 0 1-3.379 3.684l-5.587 2.795a2.335 2.335 0 0 1-2.086 0l-5.588-2.795A7.006 7.006 0 0 1 3.992 14.5H3.5A3.5 3.5 0 0 1 0 11V7.5a2.333 2.333 0 0 1 2.333-2.333H3.5V4.034c0-.534.364-1 .883-1.132L13.434.64c.371-.093.76-.093 1.132 0ZM14 2.904 5.833 4.945v6.977a4.67 4.67 0 0 0 2.58 4.175L14 18.89l5.587-2.793a4.668 4.668 0 0 0 2.58-4.174V4.946L14 2.905v-.001ZM3.5 7.499H2.333V11c0 .644.523 1.167 1.167 1.167V7.499Zm22.167 0H24.5v4.667c.644 0 1.167-.523 1.167-1.167V7.5Z" />
    </svg>
  );
}

export default DoctorMaskIcon;
