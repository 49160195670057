import clsx from 'clsx';
import PropTypes from 'prop-types';

const SvgComponent = ({ className, ...props }) => (
  <svg
    className={clsx('w-5 h-5', className)}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 1.666a8.333 8.333 0 1 1-8.334 8.333h1.667a6.667 6.667 0 1 0 1.154-3.75h2.179v1.667h-5v-5h1.667v2.083a8.317 8.317 0 0 1 6.666-3.333Z"
      fill="#09121F"
    />
    <path
      d="M9.166 12.498h1.667v1.667H9.166v-1.667Zm1.667-1.37v.537H9.166v-1.25A.833.833 0 0 1 10 9.582a1.25 1.25 0 1 0-1.225-1.495l-1.635-.328a2.917 2.917 0 1 1 3.694 3.368Z"
      fill="#09121F"
    />
  </svg>
);

SvgComponent.propTypes = {
  className: PropTypes.string,
};

SvgComponent.defaultProps = {
  className: '',
};

export default SvgComponent;
