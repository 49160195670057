import PropTypes from 'prop-types';

export const routeType = PropTypes.shape({
  name: PropTypes.string,
  path: PropTypes.string,
  exact: PropTypes.bool,
  component: PropTypes.node,
}).isRequired;

export const routerType = PropTypes.shape({
  component: PropTypes.node,
  routes: PropTypes.arrayOf(routeType),
}).isRequired;
