import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Button, Table, Popup, PopupButton, TextHintWrapper } from '@components/index';
import { PRESCRIPTION_STATUSES } from '@utils/consts';
import { getMedicationName, getMedicationFormulation } from '@utils/medication';
import { Pills, Plus, Pencil, Block, VerticalDots } from '@assets/icons';

const MedicationsTable = ({ prescription, medications, onAddMedication, onEditMedication, onDeleteMedication }) => {
  const PopupTrigger = useMemo(
    () => (
      <div className="cursor-pointer p-2 rounded-full hover:bg-cultured transition-all duration-300 popupDotsTrigger">
        <VerticalDots />
      </div>
    ),
    [],
  );

  /* eslint-disable react/prop-types */
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Product (composition)',
        accessor: 'product',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
      {
        Header: 'Formulation',
        accessor: 'formulation',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
      {
        Header: 'Packsize',
        accessor: 'packageSize',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
      {
        Header: 'No. of Repeats',
        accessor: 'repeatsNum',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
      {
        Header: 'Directions',
        accessor: 'directions',
        Cell: ({ cell: { value }, row }) => (
          <div className="flex flex-row w-full items-center justify-between">
            <TextHintWrapper className="ml-2">{value}</TextHintWrapper>
            {prescription?.status === PRESCRIPTION_STATUSES.DRAFT && (
              <Popup position={['left']} trigger={PopupTrigger} offsetY={90} offsetX={-32} label="Select Option">
                <>
                  <PopupButton
                    className="flex flex-row items-center"
                    onClick={() => onEditMedication(row.original.originalMedication)}
                  >
                    <Pencil className="mr-2" />
                    <span className="text-dark-charcoal">Edit</span>
                  </PopupButton>
                  <PopupButton
                    className="flex flex-row items-center"
                    onClick={() => onDeleteMedication(row.original.originalMedication)}
                  >
                    <Block className="w-5 h-5" />
                    <span className="ml-2 text-light-carmine-pink">Delete</span>
                  </PopupButton>
                </>
              </Popup>
            )}
          </div>
        ),
      },
    ],
    [prescription?.status],
  );
  /* eslint-enable react/prop-types */

  const tableData = useMemo(() => {
    if (!medications?.length) {
      return [];
    }

    return medications.map(medication => ({
      id: medication.id,
      product: getMedicationName(medication),
      formulation: getMedicationFormulation(medication),
      packageSize: medication.packageSize,
      quantity: medication.quantity,
      repeatsNum: medication.repeatsNum,
      directions: medication.directions,
      originalMedication: { ...medication },
    }));
  }, [medications]);

  if (!tableData.length) {
    return (
      <TablePlaceholder
        prescriptionStatus={prescription.status === PRESCRIPTION_STATUSES.DRAFT ? prescription.status : prescription.id}
        onAddMedication={onAddMedication}
      />
    );
  }

  return (
    <div className="flex flex-col">
      <div
        className="flex flex-row justify-between py-6 px-10 rounded-t-md"
        style={{ background: '#F7F7FA', border: '1px solid #E9E9E9', borderBottom: 'none' }}
      >
        <span className="text-dark-charcoal">
          {prescription.status === PRESCRIPTION_STATUSES.DRAFT ? prescription.status : prescription.id}
        </span>
        {prescription.status === PRESCRIPTION_STATUSES.DRAFT && <CreateMedication onClick={onAddMedication} />}
      </div>
      <Table
        columns={tableColumns}
        data={tableData}
        templateColumns="4fr 2fr 2fr 2fr 2fr 3fr"
        compactHeaders
        variant="prescriptionMedications"
        disableResize
      />
    </div>
  );
};
const TablePlaceholder = ({ prescriptionStatus, onAddMedication }) => (
  <div className="rounded-lg h-full overflow-hidden flex flex-col" style={{ border: '1px solid #E9E9E9' }}>
    <div className="py-6 px-10" style={{ background: '#F7F7FA' }}>
      <span>{prescriptionStatus}</span>
    </div>
    <div className="py-6 px-10 flex flex-col justify-center items-center flex-grow" style={{ background: '#FBFBFB' }}>
      <Pills />
      <p className="text-davy-grey text-xl mt-4 font-normal">No Medications</p>
      {prescriptionStatus === PRESCRIPTION_STATUSES.DRAFT && (
        <>
          <p className="text-silver-sand font-light text-base mt-0.5">Click the button ‘’Add Medication’’</p>
          <Button variant="primary" className="h-12 py-3 px-6 mt-6" onClick={onAddMedication}>
            <span>Add Medication</span>
          </Button>
        </>
      )}
    </div>
  </div>
);

const CreateMedication = React.memo(({ onClick }) => (
  <div className="flex flex-row items-center cursor-pointer" onClick={onClick}>
    <Plus />
    <span className="text-iguana-green ml-3 text-md font-roboto-medium">Add medications</span>
  </div>
));

CreateMedication.propTypes = {
  onClick: PropTypes.func.isRequired,
};

TablePlaceholder.propTypes = {
  onAddMedication: PropTypes.func.isRequired,
  prescriptionStatus: PropTypes.string.isRequired,
};

MedicationsTable.propTypes = {
  prescription: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  medications: PropTypes.arrayOf(PropTypes.shape({})),
  onAddMedication: PropTypes.func.isRequired,
  onEditMedication: PropTypes.func.isRequired,
  onDeleteMedication: PropTypes.func.isRequired,
};

MedicationsTable.defaultProps = {
  medications: [],
};

export default React.memo(MedicationsTable);
