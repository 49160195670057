import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { Input, Button } from '@components/index';
import { Cross } from '@assets/icons';

import styles from './Chips.module.scss';

const Chip = ({ label, onRemove }) => (
  <motion.div
    className="flex flex-row bg-iguana-green items-center justify-between p-2 rounded-md"
    initial={{
      opacity: 0,
      scaleY: 0,
      scaleX: 0,
    }}
    animate={{
      opacity: 1,
      scaleY: 1,
      scaleX: 1,
      transition: { duration: 0.2 },
    }}
    exit={{
      opacity: 0,
      scaleY: 0,
      scaleX: 0,
      transition: { duration: 0.2 },
    }}
  >
    <span className="mr-3 text-white">{label}</span>
    <Cross onClick={onRemove} className="cursor-pointer stroke-current text-white w-4 h-4" />
  </motion.div>
);

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

const defaultPadding = 16;

const Chips = ({ containerClassName, chips, onAdd, onRemove, error, ...props }) => {
  const chipsContainer = useRef();
  const inputRef = useRef();
  const transitionTimeout = useRef();

  const [offset, setOffset] = useState(0);
  const [bottomOffset, setBottomOffset] = useState(0);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setOffset(chipsContainer.current.offsetWidth + defaultPadding);
    setBottomOffset((inputRef.current.offsetHeight - chipsContainer.current.offsetHeight) / 2);
  }, []);

  useEffect(() => {
    if (transitionTimeout.current) {
      clearTimeout(transitionTimeout.current);
    }

    transitionTimeout.current = setTimeout(() => {
      setOffset(chipsContainer.current.offsetWidth + defaultPadding);
    }, 200);
  }, [chips]);

  const onInputValueChange = event => {
    setInputValue(event.target.value);
  };

  const onAddClick = () => {
    onAdd(inputValue);
    setInputValue('');
  };

  return (
    <div className={clsx('relative w-full', containerClassName)}>
      <Input
        {...props}
        ref={inputRef}
        style={{ paddingLeft: offset, transition: 'linear', transitionDuration: '.2s' }}
        value={inputValue}
        onChange={onInputValueChange}
      />
      <div
        className="absolute flex flex-row left-1.5 gap-1.5 h-10"
        ref={chipsContainer}
        style={{ bottom: bottomOffset }}
      >
        <AnimatePresence>
          {chips.map(m => (
            <Chip key={m.label} label={m.label} onRemove={() => onRemove(m)} />
          ))}
        </AnimatePresence>
      </div>
      <Button
        variant="primary"
        className={clsx('absolute right-1.5 p-2 rounded-md', styles.addButton)}
        onClick={onAddClick}
        isDisabled={!inputValue}
        style={{ bottom: bottomOffset }}
      >
        <span>Add</span>
      </Button>
      {!!error && (
        <span className="text-light-carmine-pink absolute font-light-rubik -bottom-6 left-4 text-sm">{error}</span>
      )}
    </div>
  );
};

Chips.propTypes = {
  containerClassName: PropTypes.string,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  error: PropTypes.string,
  chips: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ),
};

Chips.defaultProps = {
  containerClassName: '',
  onAdd: () => {},
  onRemove: () => {},
  chips: [],
  error: '',
};

export default Chips;
