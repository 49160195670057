import React from 'react';
import clsx from 'clsx';
import { countries } from 'countries-list';
import PropTypes from 'prop-types';

import { Select, Address, Input } from '@components/index';
import { useAddressInfo } from '@hooks/geolocation';

const allCountries = Object.keys(countries).map(countryISO => ({
  label: countries[countryISO].name,
  value: countryISO,
  key: countryISO,
  countryISO,
}));

const AddressComponent = ({
  country,
  city,
  address,
  postcode,
  errors,
  setCountry,
  setAddress,
  setPostcode,
  setCity,
  setShortAddress,
  countryDisabled,
}) => {
  useAddressInfo(address?.value, country?.countryISO, addressInfo => {
    if (!addressInfo?.postcode) {
      return;
    }

    setPostcode(addressInfo?.postcode);
    setCity(addressInfo?.city);
    setShortAddress(addressInfo?.shortAddress);
  });

  const handleCountryChange = value => {
    if (value.label !== country?.label) {
      setCity('');
      setAddress(null);
      setPostcode('');
    }

    setCountry(value);
  };

  const handleAddressChange = value => {
    setAddress(value);

    if (!value) {
      setPostcode('');
    }
  };

  const handlePostcodeChange = e => {
    setPostcode(e.target.value);
  };

  const handleCityChange = e => {
    setCity(e.target.value);
  };

  return (
    <div className={clsx('grid grid-cols-1 laptop:grid-cols-5 gap-x-5 laptop:gap-x-8 gap-y-5 laptop:gap-y-7')}>
      <Select
        className="col-span-2 laptop:col-span-1"
        value={country}
        error={errors.country}
        options={allCountries}
        onChange={handleCountryChange}
        isSearchable
        label="Country"
        isRequired
        placeholder="Select country"
        isDisabled={countryDisabled}
      />
      <Input
        className="col-span-2 laptop:col-span-1"
        value={city || ''}
        label="City"
        isRequired
        placeholder="Enter city"
        variant="small"
        error={errors.city}
        onChange={handleCityChange}
      />
      <Address
        className="col-span-2"
        value={address}
        error={errors.address?.label || errors.address}
        onChange={handleAddressChange}
        label="Address"
        isRequired
        placeholder="Enter address"
        countryISO={country?.countryISO}
        isDisabled={!country}
      />
      <Input
        className="col-span-2 laptop:col-span-1"
        value={postcode || ''}
        label="Postcode"
        isRequired
        placeholder="Enter postcode"
        variant="small"
        error={errors.postcode}
        onChange={handlePostcodeChange}
      />
    </div>
  );
};

AddressComponent.propTypes = {
  country: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    countryISO: PropTypes.string,
  }),
  city: PropTypes.string,
  address: PropTypes.shape({
    value: PropTypes.string,
  }),
  postcode: PropTypes.string,
  errors: PropTypes.shape({
    country: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.shape({
      label: PropTypes.string,
    }),
    postcode: PropTypes.string,
  }),
  setCountry: PropTypes.func.isRequired,
  setAddress: PropTypes.func.isRequired,
  setPostcode: PropTypes.func.isRequired,
  setCity: PropTypes.func.isRequired,
  setShortAddress: PropTypes.func.isRequired,
  countryDisabled: PropTypes.bool,
};

AddressComponent.defaultProps = {
  country: null,
  city: '',
  address: null,
  postcode: '',
  errors: {},
  countryDisabled: false,
};

export default React.memo(AddressComponent);
