import { useEffect, useMemo, useCallback, useContext, useRef } from 'react';

import { UserContext } from '@contexts/User';
import MedicationService from '@services/api/medication';
import useSafeState from '@hooks/useSafeState';

export const useTypeCreate = () => {
  const isUnmounted = useRef();
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const createType = useCallback(
    async data => {
      setIsLoading(true);

      try {
        const newType = await MedicationService.createIngredientType(data);
        if (!isUnmounted.current) {
          setIsLoading(false);
        }

        return newType;
      } catch (err) {
        setError(err);
        setIsLoading(false);

        return null;
      }
    },
    [user],
  );

  return [createType, { isLoading, error, setError }];
};

export const useTypeUpdate = () => {
  const isUnmounted = useRef();
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const updateType = useCallback(
    async (typeId, data) => {
      setIsLoading(true);

      try {
        const newType = await MedicationService.updateIngredientTypeById(typeId, data);
        setIsLoading(false);

        return newType;
      } catch (err) {
        setError(err);
        setIsLoading(false);

        return null;
      }
    },
    [user],
  );

  return [updateType, { isLoading, error, setError }];
};

export const useTypeDelete = () => {
  const isUnmounted = useRef();
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const deleteType = useCallback(
    async typeId => {
      setIsLoading(true);

      try {
        await MedicationService.deleteIngredientTypeById(typeId);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    },
    [user],
  );

  return [deleteType, { isLoading, error, setError }];
};

export const useTypeList = filterFn => {
  const isUnmounted = useRef();
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const [types, setTypes] = useSafeState([], isUnmounted);
  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const [create, { isLoading: isCreating }] = useTypeCreate();
  const [update, { isLoading: isUpdating }] = useTypeUpdate();
  const [remove, { isLoading: isDeleting }] = useTypeDelete();

  useEffect(async () => {
    setIsLoading(true);

    try {
      let typesResponse = await MedicationService.getAllIngredientTypes();
      if (filterFn && typeof filterFn === 'function') {
        typesResponse = typesResponse.filter(filterFn);
      }

      setTypes(typesResponse);
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }, []);

  const createType = useCallback(async data => {
    const newType = await create(data);
    if (newType) {
      setTypes(prevTypes => [...prevTypes, newType]);
    }

    return newType;
  }, []);

  const setTypeActivation = useCallback(
    async (typeId, isActive) => {
      const initialType = types.find(({ id }) => id === typeId);
      const newType = await update(typeId, {
        ...initialType,
        isActive,
      });

      if (!newType) {
        return newType;
      }

      setTypes(prevTypes =>
        prevTypes.map(type => {
          if (type.id === newType.id) {
            return { ...type, ...newType };
          }

          return type;
        }),
      );

      return newType;
    },
    [types],
  );

  const updateType = useCallback(
    async (typeId, updatedType) => {
      const newType = await update(typeId, updatedType);
      if (!newType) {
        return newType;
      }

      setTypes(prevTypes =>
        prevTypes.map(type => {
          if (type.id === newType.id) {
            return { ...type, ...newType };
          }

          return type;
        }),
      );

      return newType;
    },
    [types],
  );

  const deleteType = useCallback(async typeId => {
    await remove(typeId);
    setTypes(prevTypes => prevTypes.filter(type => type.id !== typeId));
  }, []);

  return useMemo(
    () => [
      types,
      {
        isLoading,
        isCreating,
        isUpdating,
        error,
        setError,
        setTypes,
        createType,
        setTypeActivation,
        updateType,
        deleteType,
      },
    ],
    [types, isLoading, isCreating, isUpdating, deleteType, isDeleting, setTypeActivation, createType, updateType],
  );
};
