/* eslint-disable no-nested-ternary */
import React, { forwardRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { EyeIcon, Lens, SmallArrow, Calendar } from '@assets/icons';
import useDevice from '@hooks/useDevice';

import styles from './Input.module.scss';

// Add new icon to this object
const icons = {
  eye: EyeIcon,
  lens: Lens,
  smallArrow: SmallArrow,
  calendar: Calendar,
};

const Icon = ({ icon, ...props }) => {
  const ViewIcon = icons[icon];
  return <ViewIcon {...props} />;
};

// eslint-disable-next-line react/display-name
const Input = forwardRef(
  (
    {
      className,
      type = 'text',
      label,
      icon,
      error,
      helpMessage,
      isRequired,
      variant = 'default',
      multiline,
      iconPosition,
      iconClassName,
      hideErrorText = false,
      renderRightElement,
      ...props
    },
    ref,
  ) => {
    const [visible, setVisible] = useState(type !== 'password');
    const inputIcon = icon || (type === 'password' ? 'eye' : null);
    const { isMobile } = useDevice();
    const [variantDependsOnDevice, setVariantDependsOnDevice] = useState(variant);

    useEffect(() => {
      if (isMobile && variant === 'default') {
        setVariantDependsOnDevice('small');
        return;
      }
      setVariantDependsOnDevice(variant);
    }, [isMobile]);

    const onKeyDown = event => {
      if (type !== 'number') {
        return true;
      }

      return event.keyCode !== 69;
    };

    return (
      <div className={clsx(className, 'border-transparent relative flex flex-col')}>
        {label && (
          <p className="text-xsLh22 laptop:text-base font-roboto-medium ml-2 laptop:ml-4 mb-2">
            <span style={{ color: '#9B9DAE' }}>{label}</span>
            {isRequired && <span className="text-light-carmine-pink">*</span>}
          </p>
        )}
        <div className="relative flex-1">
          {inputIcon && iconPosition === 'left' && (
            <div
              onClick={() => icon === 'eye' && setVisible(!visible)}
              aria-hidden="true"
              className="absolute flex items-center justify-center transform -translate-y-2/4 left-0 inset-y-2/4 cursor-pointer w-12 h-full"
            >
              <Icon icon={inputIcon} />
            </div>
          )}
          {multiline ? (
            <div className="flex flex-row items-center">
              <textarea
                rows={5}
                ref={ref}
                {...props}
                className={clsx(
                  styles.input,
                  styles[variantDependsOnDevice],
                  'py-3 laptop:py-4',
                  error && 'border-light-carmine-pink',
                )}
              />
              {renderRightElement && renderRightElement()}
            </div>
          ) : (
            <div className="flex flex-row items-center justify-center">
              <input
                ref={ref}
                type={visible && type === 'password' ? 'text' : type}
                onKeyDown={onKeyDown}
                {...props}
                className={clsx(
                  'self-center',
                  styles.input,
                  styles[variantDependsOnDevice],
                  'py-3 laptop:py-4',
                  error && 'border-light-carmine-pink',
                )}
              />
              {renderRightElement && renderRightElement()}
            </div>
          )}
          {inputIcon && iconPosition !== 'left' && (
            <div
              onClick={() => icon === 'eye' && setVisible(!visible)}
              aria-hidden="true"
              className={clsx(
                'absolute flex items-center justify-center transform -translate-y-2/4 right-0 inset-y-2/4 cursor-pointer w-12 h-full',
                iconClassName,
              )}
            >
              <Icon icon={inputIcon} />
            </div>
          )}
          {!hideErrorText ? (
            error ? (
              <span className="text-light-carmine-pink absolute font-roboto-regular -bottom-4 laptop:-bottom-6 left-2 laptop:left-4 text-xs laptop:text-sm">
                {error}
              </span>
            ) : helpMessage && !error ? (
              <span className="text-silver-sand absolute font-roboto-regular -bottom-4 laptop:-bottom-6 left-2 laptop:left-4 text-xs laptop:text-sm">
                {helpMessage}
              </span>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  },
);

Icon.propTypes = {
  icon: PropTypes.oneOf(['eye', 'lens', 'smallArrow', 'calendar']),
};

Icon.defaultProps = {
  icon: 'eye',
};

Input.propTypes = {
  multiline: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.oneOf(['eye', 'lens', 'smallArrow', 'calendar']),
  type: PropTypes.oneOf(['text', 'password', 'email', 'number', 'date', 'tel', 'file', 'hidden']),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  helpMessage: PropTypes.string,
  variant: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  iconClassName: PropTypes.string,
  hideErrorText: PropTypes.bool,
  renderRightElement: PropTypes.func,
};

Input.defaultProps = {
  className: '',
  icon: null,
  type: 'text',
  error: '',
  label: '',
  helpMessage: '',
  isRequired: false,
  variant: 'default',
  multiline: false,
  iconPosition: 'right',
  iconClassName: '',
  hideErrorText: false,
  renderRightElement: null,
};

export default Input;
