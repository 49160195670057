import { useLayoutEffect, useState } from 'react';

const useResize = () => {
  const [size, setSize] = useState({ windowWidth: 0, windowHeight: 0 });

  const updateSize = () => {
    setSize({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export default useResize;
