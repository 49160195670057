import clsx from 'clsx';
import PropTypes from 'prop-types';

const SvgComponent = ({ className, ...props }) => (
  <svg
    className={clsx('w-5 h-5', className)}
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m13.107 9.072-1.179-1.179-7.761 7.762v1.178h1.178l7.762-7.761Zm1.178-1.179 1.178-1.178-1.178-1.178-1.178 1.178 1.178 1.178ZM6.035 18.5H2.5v-3.536L13.696 3.768a.833.833 0 0 1 1.178 0l2.358 2.358a.833.833 0 0 1 0 1.178L6.036 18.5h-.001Z"
      fill="#fff"
    />
  </svg>
);

SvgComponent.propTypes = {
  className: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

SvgComponent.defaultProps = {
  className: '',
};

export default SvgComponent;
