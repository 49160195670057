import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { Input, Select } from '@components/index';
import { useOutside } from '@hooks/useOutside';
import { SmallArrow } from '@assets/icons';

import Chip from './Chip';
import styles from './IngredientsDosageSelect.module.scss';

const maxIngredientDosage = 999999;

const Option = ({ label, value, className, measurement, onChange }) => {
  const [options, setOptions] = useState([]);
  const [currentMeasurement, setCurrentMeasurement] = useState(null);

  useEffect(() => {
    const newOptions = measurement.map(m => ({ value: m, label: m }));

    setOptions(newOptions);
    setCurrentMeasurement(newOptions[0]);
  }, [measurement]);

  useEffect(() => {
    if (value?.measurement) {
      setCurrentMeasurement({ value: value?.measurement, label: value?.measurement });
    }
  }, [value?.measurement]);

  const onCountChange = e => {
    onChange(e.target.value, currentMeasurement?.value);
  };

  const onMeasurementChange = newMeasurement => {
    onChange(value?.value || '', newMeasurement.value);
  };

  return (
    <div
      className={clsx(
        'flex flex-row justify-between items-center cursor-pointer px-3 py-2 transition-all duration-300 rounded-lg group hover:bg-bright-gray',
        className,
        styles.option,
      )}
    >
      <div className="w-44">
        <span className="text-dark-charcoal text-base">{label}</span>
      </div>
      <div className="flex flex-row flex-grow items-center w-auto">
        <Input
          className="mr-2"
          value={value?.value || ''}
          variant="ingredientsDosage"
          placeholder="00"
          type="number"
          onChange={onCountChange}
          max={maxIngredientDosage}
        />
        <Select
          className={clsx('bg-white group-hover:bg-bright-gray rounded-lg w-20', styles.width)}
          value={currentMeasurement}
          onChange={onMeasurementChange}
          options={options}
          placeholder="Unit"
          variant="ingredientsDosage"
        />
      </div>
    </div>
  );
};

Option.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  measurement: PropTypes.arrayOf(PropTypes.string),
  inputRef: PropTypes.shape({}),
  value: PropTypes.shape({
    value: PropTypes.string,
    measurement: PropTypes.string,
  }),
};

Option.defaultProps = {
  onChange: () => {},
  className: '',
  measurement: [],
  inputRef: null,
  value: null,
};

const IngredientsDosageSelect = ({
  ingredients,
  onChange,
  className,
  error,
  label,
  isRequired,
  placeholder,
  measurement,
  initialValue,
  isDisabled,
}) => {
  const [ingredientsDosage, setIngredientsDosage] = useState(initialValue || {});

  const containerRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();

  useOutside(dropdownRef, setIsOpen);

  useEffect(() => {
    if (!initialValue) {
      return;
    }

    setIngredientsDosage(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!ingredients.length) {
      return;
    }

    const newDosage = {};
    ingredients.forEach(ingredient => {
      newDosage[ingredient.id] = ingredientsDosage[ingredient.id] || {};
    });

    setIngredientsDosage(newDosage);
    onChange(newDosage);
  }, [ingredients]);

  const onClick = event => {
    if (isDisabled) {
      return;
    }

    if (event.target === containerRef.current) {
      setIsOpen(!isOpen);
    }
  };

  const onKeyPress = event => {
    if (isDisabled) {
      return;
    }

    if (event.key === 'Enter') {
      setIsOpen(!isOpen);
    }
  };

  const onDosageChange = (ingredientId, value, measure) => {
    const newValue = { ...ingredientsDosage, [ingredientId]: { value, measurement: measure } };
    setIngredientsDosage(newValue);
    onChange(newValue);
  };

  const showPlaceholder = !ingredients || !ingredients.length;

  return (
    <div className={clsx(className, 'border-transparent relative', error && 'border-light-carmine-pink')}>
      {label && (
        <p className="font-roboto-medium ml-4 mb-2">
          <span style={{ color: '#9B9DAE' }}>{label}</span>
          {isRequired && <span className="text-light-carmine-pink">*</span>}
        </p>
      )}
      <div className="relative">
        <div
          ref={containerRef}
          tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
          placeholder={placeholder}
          className={clsx(
            'cursor-pointer flex-row relative max-w-full',
            styles.input,
            isDisabled && styles.disabled,
            isOpen && 'pointer-events-none',
            showPlaceholder ? 'pl-3.5 py-3.5' : 'pl-1.5 py-1.5',
          )}
          onKeyPress={onKeyPress}
          onClick={onClick}
        >
          {showPlaceholder && <span className="text-silver-sand pointer-events-none">{placeholder}</span>}
          <div className="flex flex-row flex-wrap left-1.5 gap-1.5 pointer-events-none">
            <AnimatePresence>
              {ingredients.map(m => (
                <Chip
                  key={m.id}
                  label={m.name}
                  dosage={ingredientsDosage[m.id]?.value || 0}
                  measurement={ingredientsDosage[m.id]?.measurement || measurement[0]}
                />
              ))}
            </AnimatePresence>
          </div>
          <div className="absolute right-6 bottom-0 mb-auto flex h-full items-center pointer-events-none">
            <SmallArrow className={clsx('transform transition-transform', isOpen ? '' : 'rotate-180')} />
          </div>
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              ref={dropdownRef}
              className={clsx(styles.dropdown, 'w-full absolute py-2 z-50')}
              style={{ originY: 0 }}
              initial={{
                opacity: 0,
                scaleY: 0,
              }}
              animate={{
                opacity: 1,
                scaleY: 1,
                transition: { duration: 0.3 },
              }}
              exit={{
                opacity: 0,
                scaleY: 0,
                transition: { duration: 0.3 },
              }}
            >
              {ingredients.map(ingredient => (
                <Option
                  key={ingredient.id}
                  label={ingredient.name}
                  className="mx-2"
                  measurement={measurement}
                  value={ingredientsDosage[ingredient.id]}
                  onChange={(count, measure) => {
                    if (count > maxIngredientDosage) {
                      return;
                    }

                    if (!measure) {
                      return;
                    }

                    onDosageChange(ingredient.id, count, measure);
                  }}
                />
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {/* eslint-disable-next-line no-nested-ternary */}
      {!!error && !isOpen && (
        <span className="text-light-carmine-pink absolute font-light-rubik -bottom-6 left-4 text-sm">{error}</span>
      )}
    </div>
  );
};

IngredientsDosageSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  measurement: PropTypes.arrayOf(PropTypes.string),
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  initialValue: PropTypes.shape({}),
  isDisabled: PropTypes.bool,
};

IngredientsDosageSelect.defaultProps = {
  ingredients: [],
  className: '',
  error: '',
  label: '',
  isRequired: false,
  placeholder: '',
  measurement: [],
  initialValue: null,
  isDisabled: false,
};

export default IngredientsDosageSelect;
