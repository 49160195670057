import { useRef, useContext, useCallback, useEffect, useMemo } from 'react';

import { UserContext } from '@contexts/User';
import ProfileService from '@services/api/profile';
import UserService from '@services/api/user';
import { USER_ROLES } from '@utils/consts';
import useSafeState from '@hooks/useSafeState';

export const useCreateOwnProfile = () => {
  const isUnmounted = useRef();
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const { user } = useContext(UserContext);

  const [profile, setProfile] = useSafeState(null, isUnmounted);
  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const create = useCallback(
    async data => {
      setIsLoading(true);

      try {
        const profileResponse = await ProfileService.createProfile(user.id, data);

        setIsLoading(false);
        setProfile(profileResponse);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    },
    [user],
  );

  return [create, { isLoading, error, setError, profile }];
};

export const usePatientCreate = () => {
  const isUnmounted = useRef();

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const createPatient = useCallback(
    async data => {
      setIsLoading(true);

      try {
        const newPatient = await ProfileService.createProfile(null, data);

        if (!isUnmounted.current) {
          setIsLoading(false);
        }

        return newPatient;
      } catch (err) {
        setError(err);
        setIsLoading(false);

        return null;
      }
    },
    [user],
  );

  return [createPatient, { isLoading, error, setError }];
};

export const useProfileList = (role = USER_ROLES.PRESCRIBER, options) => {
  const isUnmounted = useRef();
  const isPopulateUsers = !!options?.isPopulateUsers;

  const [profiles, setProfiles] = useSafeState([], isUnmounted);
  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  useEffect(async () => {
    setIsLoading(true);

    try {
      const profilesResponse = await (async () => {
        const userIdExists = options?.userIdExists;
        const associatedUserId = options?.associatedUserId;

        if (associatedUserId) {
          return ProfileService.getProfilesByAssociatedUserId(associatedUserId);
        }

        return ProfileService.getProfilesByType(role, userIdExists);
      })();

      if (!isPopulateUsers) {
        setIsLoading(false);
        setProfiles(profilesResponse);

        return;
      }

      // TODO: Add API to get users by array of ids to improve performance
      const userPromises = profilesResponse.map(profile => {
        return new Promise((resolve, reject) => {
          UserService.getUserById(profile.userId)
            .then(userData => {
              profile.userData = userData; // eslint-disable-line no-param-reassign
              resolve(userData);
            })
            .catch(err => reject(err));
        });
      });

      await Promise.all(userPromises);

      setIsLoading(false);
      setProfiles(profilesResponse);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }, [role, isPopulateUsers]);

  return useMemo(() => [profiles, { isLoading, error, setError, setProfiles }], [profiles]);
};

export const useProfileUpdate = () => {
  const isUnmounted = useRef();

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const updateProfile = useCallback(
    async (profileId, data) => {
      setIsLoading(true);

      try {
        const updatedProfile = await ProfileService.updateProfileById(profileId, data);

        setIsLoading(false);

        return updatedProfile;
      } catch (err) {
        setError(err);
        setIsLoading(false);

        return null;
      }
    },
    [user],
  );

  return [updateProfile, { isLoading, error, setError }];
};

export const useProfileDelete = () => {
  const isUnmounted = useRef();

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const deleteProfile = useCallback(
    async profileId => {
      setIsLoading(true);

      try {
        await ProfileService.deleteProfileById(profileId);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    },
    [user],
  );

  return [deleteProfile, { isLoading, error, setError }];
};

export const usePatientList = () => {
  const isUnmounted = useRef();

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const [patients, setPatients] = useSafeState([], isUnmounted);
  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const [create, { isLoading: isCreating }] = usePatientCreate();
  const [update, { isLoading: isUpdating }] = useProfileUpdate();
  const [remove, { isLoading: isDeleting }] = useProfileDelete();

  useEffect(async () => {
    setIsLoading(true);

    try {
      const patientsResponse = await ProfileService.getOwnPatients();

      setPatients(patientsResponse);
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }, []);

  const createPatient = useCallback(async data => {
    const newPatient = await create(data);

    setPatients(prevPatients => [...prevPatients, newPatient]);

    return newPatient;
  }, []);

  const updatePatient = useCallback(async (patientId, data) => {
    const newPatient = await update(patientId, data);

    setPatients(prevPatients =>
      prevPatients.map(patient => {
        if (patient.id === newPatient.id) {
          return { ...patient, ...newPatient };
        }

        return patient;
      }),
    );

    return newPatient;
  }, []);

  const deletePatient = useCallback(async patientId => {
    await remove(patientId);

    setPatients(prevPatients =>
      prevPatients
        .map(patient => {
          if (patient.id === patientId) {
            return null;
          }

          return patient;
        })
        .filter(patient => !!patient),
    );
  }, []);

  return useMemo(
    () => [
      patients,
      {
        isLoading,
        isCreating,
        isUpdating,
        isDeleting,
        error,
        setError,
        setPatients,
        createPatient,
        updatePatient,
        deletePatient,
      },
    ],
    [patients, isLoading, isCreating, isUpdating, isDeleting],
  );
};

export const useProfile = (id, isUserId = false) => {
  const isUnmounted = useRef();

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const [profile, setProfile] = useSafeState(null, isUnmounted);
  const [isLoading, setIsLoading] = useSafeState(true, isUnmounted);
  const [isError, setIsError] = useSafeState(false, isUnmounted);

  useEffect(() => {
    if (!id) {
      setIsLoading(false);

      return;
    }

    const init = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        let profileResponse;

        if (isUserId) {
          profileResponse = await ProfileService.getProfileByUserId(id);
        } else {
          profileResponse = await ProfileService.getProfileById(id);
        }

        setProfile(profileResponse);
        setIsLoading(false);
      } catch (err) {
        setIsError(true);
        setIsLoading(false);
      }
    };

    init();
  }, [id, isUserId]);

  return useMemo(() => [profile, { isLoading, isError }], [profile, isLoading, isError]);
};

export const useGetProfile = () => {
  const isUnmounted = useRef();

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const getProfile = useCallback(async (id, isUserId = false) => {
    setIsLoading(true);

    try {
      let profileResponse;

      if (isUserId) {
        profileResponse = await ProfileService.getProfileByUserId(id);
      } else {
        profileResponse = await ProfileService.getProfileById(id);
      }

      setIsLoading(false);

      return profileResponse;
    } catch (err) {
      setError(err);
      setIsLoading(false);

      return null;
    }
  }, []);

  return [getProfile, { isLoading, error, setError }];
};
