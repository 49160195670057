import { MutableRefObject, useEffect } from 'react';
import PropTypes from 'prop-types';

import { DigitInput } from '@components/Form';

const DigitalBlock = ({ code, digitInputsRefs, onChangeDigit, onPressKeyDown, error }) => {
  useEffect(() => {
    digitInputsRefs[0].current.focus();
  }, []);

  const isError = !!error;

  return (
    <>
      <div className="flex flex-row justify-between">
        {code.map((digit, index) => {
          return (
            <DigitInput
              index={index}
              /* eslint-disable-next-line react/no-array-index-key */
              key={index.toString()}
              ref={digitInputsRefs[index]}
              onKey
              onChange={event => onChangeDigit(index, event)}
              onKeyDown={event => onPressKeyDown(index, event)}
              value={code[index] || digit}
            />
          );
        })}
      </div>
      {isError && <span className="text-light-carmine-pink absolute mt-2 font-roboto-regular text-sm">{error}</span>}
    </>
  );
};

DigitalBlock.propTypes = {
  error: PropTypes.string,
  code: PropTypes.arrayOf(PropTypes.string).isRequired,
  digitInputsRefs: PropTypes.arrayOf(PropTypes.shape(MutableRefObject)).isRequired,
  onChangeDigit: PropTypes.func.isRequired,
  onPressKeyDown: PropTypes.func.isRequired,
};

DigitalBlock.defaultProps = {
  error: null,
};

export default DigitalBlock;
