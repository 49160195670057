const keys = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  user: 'user',
  profile: 'profile',
  welcomeLayoutVisibility: 'welcomeLayoutVisibility',
};

class LocalStorageService {
  static getAccessToken() {
    const accessToken = localStorage.getItem(keys.accessToken) || '';
    return accessToken;
  }

  static setAccessToken(accessToken) {
    localStorage.setItem(keys.accessToken, accessToken);
  }

  static removeAccessToken() {
    localStorage.removeItem(keys.accessToken);
  }

  static getRefreshToken() {
    const refreshToken = localStorage.getItem(keys.refreshToken) || '';
    return refreshToken;
  }

  static setRefreshToken(refreshToken) {
    localStorage.setItem(keys.refreshToken, refreshToken);
  }

  static removeRefreshToken() {
    localStorage.removeItem(keys.refreshToken);
  }

  static getUser() {
    let user = localStorage.getItem(keys.user) || null;
    if (user) {
      user = JSON.parse(user);
    }

    return user;
  }

  static setUser(user) {
    const serialized = JSON.stringify(user);
    localStorage.setItem(keys.user, serialized);
  }

  static getProfile() {
    let profile = localStorage.getItem(keys.profile) || null;
    if (profile) {
      profile = JSON.parse(profile);
    }

    return profile;
  }

  static setProfile(profile) {
    const serialized = JSON.stringify(profile);
    localStorage.setItem(keys.profile, serialized);
  }

  static getWelcomeLayoutVisibility() {
    return JSON.parse(localStorage.getItem(keys.welcomeLayoutVisibility));
  }

  static setWelcomeLayoutVisibility(visible) {
    localStorage.setItem(keys.welcomeLayoutVisibility, visible);
  }

  static removeWelcomeLayoutVisibility() {
    localStorage.removeItem(keys.welcomeLayoutVisibility);
  }
}

export default LocalStorageService;
