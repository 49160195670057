import loadable from '@loadable/component';
import { loadableOptions } from '@utils/helpers';

const Auth = {
  CheckEmail: loadable(() => import('./CheckEmail'), loadableOptions),
  CheckCode: loadable(() => import('./CheckCode'), loadableOptions),
  SignIn: loadable(() => import('./SignIn'), loadableOptions),
  SignUp: loadable(() => import('./SignUp'), loadableOptions),
  ForgotPassword: loadable(() => import('./ResetPassword/ForgotPassword'), loadableOptions),
  Info: loadable(() => import('./ResetPassword/Info'), loadableOptions),
  NewPassword: loadable(() => import('./ResetPassword/NewPassword'), loadableOptions),
  CreateProfile: loadable(() => import('./CreateProfile'), loadableOptions),
};

export default Auth;
