import clsx from 'clsx';
import PropTypes from 'prop-types';

const SvgComponent = ({ className, ...props }) => (
  <svg className={clsx('w-5 h-5', className)} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m9.999 8.822 4.125-4.125 1.178 1.178L11.177 10l4.125 4.125-1.178 1.178-4.125-4.125-4.125 4.125-1.179-1.178L8.82 10 4.695 5.875l1.179-1.178 4.125 4.125Z"
      fill="#E34A4A"
    />
  </svg>
);

SvgComponent.propTypes = {
  className: PropTypes.string,
};

SvgComponent.defaultProps = {
  className: '',
};

export default SvgComponent;
