import { useRef, useEffect, useCallback, useMemo } from 'react';

import PrescriptionService from '@services/api/prescription';
import useSafeState from '@hooks/useSafeState';

const usePrescriptionSign = () => {
  const isUnmounted = useRef();
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const signPrescription = useCallback(async (prescriptionId, password) => {
    setIsLoading(true);

    try {
      const token = await PrescriptionService.signPrescription(prescriptionId, password);
      setIsLoading(false);

      return token;
    } catch (err) {
      setIsLoading(false);
      setError(err);
      return null;
    }
  }, []);

  return useMemo(() => [signPrescription, { isLoading, error }], [signPrescription, isLoading, error]);
};

export default usePrescriptionSign;
