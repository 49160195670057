import { useContext } from 'react';
import clsx from 'clsx';

import { ToastContext } from '@contexts/Toast';
import useDevice from '@hooks/useDevice';
import Toast from './Toast';

const ToastContainer = () => {
  const { isMobile } = useDevice();
  const { isOpen, message, close, type } = useContext(ToastContext);

  return (
    <div
      className={clsx(
        isMobile ? 'flex items-center justify-center' : '',
        'flex items-center justify-center w-screen h-screen absolute top-0 left-0 overflow-hidden pointer-events-none z-50',
      )}
    >
      <Toast isOpen={isOpen} message={message} onClick={close} type={type} />
    </div>
  );
};

export default ToastContainer;
