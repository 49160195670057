import { useEffect, useMemo, useCallback, useContext, useRef } from 'react';

import { UserContext } from '@contexts/User';
import MedicationService from '@services/api/medication';
import useSafeState from '@hooks/useSafeState';

export const useMedicationCreate = () => {
  const isUnmounted = useRef();
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const createMedication = useCallback(
    async data => {
      setIsLoading(true);

      try {
        const newMedication = await MedicationService.createMedication(data);
        if (!isUnmounted.current) {
          setIsLoading(false);
        }

        return newMedication;
      } catch (err) {
        setError(err);
        setIsLoading(false);

        return null;
      }
    },
    [user],
  );

  return [createMedication, { isLoading, error, setError }];
};

export const useMedicationUpdate = () => {
  const isUnmounted = useRef();
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const updateMedication = useCallback(
    async (medicationId, data) => {
      setIsLoading(true);

      try {
        const newMedication = await MedicationService.updateMedicationById(medicationId, data);
        setIsLoading(false);

        return newMedication;
      } catch (err) {
        setError(err);
        setIsLoading(false);

        return null;
      }
    },
    [user],
  );

  return [updateMedication, { isLoading, error, setError }];
};

export const useMedicationList = filterFn => {
  const isUnmounted = useRef();
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const [medications, setMedications] = useSafeState([], isUnmounted);
  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const [create, { isLoading: isCreating }] = useMedicationCreate();
  const [update, { isLoading: isUpdating }] = useMedicationUpdate();

  useEffect(async () => {
    setIsLoading(true);

    try {
      let medicationsResponse = await MedicationService.getAllMedications();
      if (filterFn && typeof filterFn === 'function') {
        medicationsResponse = medicationsResponse.filter(filterFn);
      }

      setMedications(medicationsResponse);
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }, []);

  const createMedication = useCallback(async data => {
    const newMedication = await create(data);
    if (newMedication) {
      setMedications(prevMedications => [...prevMedications, newMedication]);
    }

    return newMedication;
  }, []);

  const updateMedication = useCallback(async (medicationId, data) => {
    const newMedication = await update(medicationId, data);
    if (!newMedication) {
      return newMedication;
    }

    setMedications(prevMedications =>
      prevMedications.map(medication => {
        if (medication.id === newMedication.id) {
          return { ...medication, ...newMedication };
        }

        return medication;
      }),
    );

    return newMedication;
  }, []);

  return useMemo(
    () => [
      medications,
      {
        isLoading,
        isCreating,
        isUpdating,
        error,
        setError,
        setMedications,
        createMedication,
        updateMedication,
      },
    ],
    [medications, isLoading, isCreating, isUpdating],
  );
};
