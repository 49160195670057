import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { Button, Input } from '@components/index';
import { validationResendCode } from '@utils/validation';

const initialValues = {
  first: '',
  second: '',
  third: '',
  forth: '',
  fifth: '',
  sixth: '',
};

const ResendCode = ({ setEnterCodeModal, onSubmit, onResend }) => {
  const [phoneError, setPhoneError] = useState(null);
  const onCodeSubmit = async form => {
    const code = `${form.first}${form.second}${form.third}${form.forth}${form.fifth}${form.sixth}`;

    try {
      await onSubmit(code);
    } catch (err) {
      setPhoneError(err);
    }
  };

  const { handleSubmit, setFieldValue, values, errors, setValues } = useFormik({
    initialValues,
    validationSchema: validationResendCode,
    onSubmit: onCodeSubmit,
  });

  const itemsRef = useRef({});

  const sendCodeNext = () => {
    onResend();
    setValues(initialValues);
    Object.keys(initialValues).forEach(key => {
      itemsRef.current[key].value = '';
    });
  };

  const submitEditing = (item, index, value) => {
    setFieldValue(item, value);

    if (value.length === 1 && index < Object.keys(values).length - 1) {
      const next = Object.keys(values);

      itemsRef.current[next[index + 1]].focus();
    }
  };

  const isPhoneError = !!phoneError;

  return (
    <form onSubmit={handleSubmit}>
      <h4 className="text-default laptop:text-xl text-dark-charcoal font-roboto-medium mb-10">Enter your code</h4>
      <p className="text-xsLh22 laptop:text-base font-roboto-medium mb-2" style={{ color: '#9B9DAE' }}>
        Code from SMS
      </p>
      <div className="mb-4 laptop:mb-6">
        <div className="flex flex-row flex-shrink items-center justify-between gap-2">
          {Object.keys(values).map((item, index) => (
            <Input
              key={item}
              defaultValue={values[item]}
              ref={element => {
                itemsRef.current[item] = element;
              }}
              variant="code"
              maxLength={1}
              onChange={e => submitEditing(item, index, e.target.value)}
              error={isPhoneError && phoneError.toString()}
              hideErrorText
            />
          ))}
        </div>
        {isPhoneError && (
          <div className="text-xsLh22 laptop:text-base text-light-carmine-pink laptop:mt-2">
            <span>Invalid code</span>
          </div>
        )}
      </div>
      <div onClick={sendCodeNext} className="text-xsLh22 laptop:text-base text-silver-sand font-light mb-8">
        Did not receive code?{' '}
        <span className="text-xsLh22 laptop:text-base text-iguana-green cursor-pointer">Send again</span>
      </div>
      <div className="flex flex-row justify-end">
        <Button onClick={() => setEnterCodeModal(false)} className="px-4 py-3 mr-6" variant="empty">
          <span className="text-xsLh22 laptop:text-base">Cancel</span>
        </Button>
        <Button type="submit" disabled={!!Object.values(errors).length} variant="primary">
          <span className="text-xsLh22 laptop:text-base">Confirm</span>
        </Button>
      </div>
    </form>
  );
};

ResendCode.propTypes = {
  setEnterCodeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
};

export default ResendCode;
