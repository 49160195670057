import { createContext, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import config from '@config/config';
import AuthService from '@services/api/auth';
import ProfileService from '@services/api/profile';
import UserService from '@services/api/user';
import LocalStorageService from '@services/localStorage';
import { ROOT_PATH, CREATE_PROFILE as PRESCRIBER_CREATE_PROFILE } from '@router/paths';
import { CREATE_PROFILE as PATIENT_CREATE_PROFILE } from '@patient/router/paths';
import { PROFILE_STATUSES } from '@utils/consts';

const CREATE_PROFILE = config.isPatientApp ? PATIENT_CREATE_PROFILE : PRESCRIBER_CREATE_PROFILE;

export const UserContext = createContext({
  user: null,
  profile: null,
  isMainLoading: true,
  setProfile: () => {},
  login: async () => {},
  logout: async () => {},
  register: async () => {},
  checkEmail: async () => {},
  forgotPassword: async () => {},
  resetPassword: async () => {},
  updateCurrentProfile: async () => {},
  updateCurrentUserPhone: async () => {},
  deactivateCurrentUser: () => {},
  refetchUserData: async () => {},
});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);

  const [isMainLoading, setIsMainLoading] = useState(true);

  const history = useHistory();

  const setProfileFromUser = useCallback(async userData => {
    const profileResponse = await ProfileService.getProfileByUserId(userData.id);
    setProfile(profileResponse);
  }, []);

  useEffect(async () => {
    setIsMainLoading(true);

    const accessToken = LocalStorageService.getAccessToken();
    if (!accessToken) {
      setIsMainLoading(false);
      return;
    }

    try {
      let userResponse;

      try {
        userResponse = await AuthService.getCurrentUser();
        setUser(userResponse);
      } catch (err) {
        // Try to refresh token if current one is invalid
        const result = await AuthService.refreshToken();

        LocalStorageService.setAccessToken(result.accessToken);
        LocalStorageService.setRefreshToken(result.refreshToken);

        // Set current user after token refresh
        userResponse = await AuthService.getCurrentUser();
        setUser(userResponse);
      }

      try {
        await setProfileFromUser(userResponse);
        if (userResponse?.status === PROFILE_STATUSES.REJECTED) {
          history.push(CREATE_PROFILE);
        }
      } catch (err) {
        history.push(CREATE_PROFILE);
      }
    } catch (err) {
      LocalStorageService.removeAccessToken();
      LocalStorageService.removeRefreshToken();
      LocalStorageService.removeWelcomeLayoutVisibility();
    } finally {
      setIsMainLoading(false);
    }
  }, []);

  const checkEmail = useCallback(async email => {
    const result = await AuthService.checkEmail(email);
    return result.isExist;
  }, []);

  const login = useCallback(async (email, password, otp) => {
    const result = await AuthService.emailLogin(email, password, otp);
    if (!result?.accessToken) {
      return false;
    }

    LocalStorageService.setAccessToken(result.accessToken);
    LocalStorageService.setRefreshToken(result.refreshToken);

    const userResponse = await AuthService.getCurrentUser();
    setUser(userResponse);

    try {
      await setProfileFromUser(userResponse);

      // If profile was rejected - redirect user to create profile page
      if (userResponse?.status === PROFILE_STATUSES.REJECTED) {
        history.push(CREATE_PROFILE);
        return true;
      }
    } catch (err) {
      // If profile is not created yet - redirect to create profile page
      history.push(CREATE_PROFILE);
      return true;
    }

    history.push(ROOT_PATH);
    return true;
  }, []);

  const register = useCallback(async (email, password, token, userCallback) => {
    const result = await AuthService.emailRegistration(email, password, token);
    if (!result?.accessToken) {
      return false;
    }

    LocalStorageService.setAccessToken(result.accessToken);
    LocalStorageService.setRefreshToken(result.refreshToken);

    const userResponse = await AuthService.getCurrentUser();
    setUser(userResponse);

    if (userCallback && typeof userCallback === 'function') {
      await userCallback(userResponse);
    }

    history.push(CREATE_PROFILE);
    return true;
  }, []);

  const forgotPassword = useCallback(async (email, answer) => {
    const result = await AuthService.forgotPassword(email, answer);
    return result;
  }, []);

  const resetPassword = useCallback(async (newPassword, token) => {
    const result = await AuthService.resetPassword(newPassword, token);

    LocalStorageService.setAccessToken(result.accessToken);
    LocalStorageService.setRefreshToken(result.refreshToken);

    const userResponse = await AuthService.getCurrentUser();
    setUser(userResponse);

    try {
      await setProfileFromUser(userResponse);
      // If profile was rejected - redirect user to create profile page
      if (userResponse?.status === PROFILE_STATUSES.REJECTED) {
        history.push(CREATE_PROFILE);
        return;
      }
    } catch (err) {
      // If profile is not created yet - redirect to create profile page
      history.push(CREATE_PROFILE);
      return;
    }

    history.push(ROOT_PATH);
  }, []);

  const refetchUserData = useCallback(async () => {
    const accessToken = LocalStorageService.getAccessToken();
    if (!accessToken) {
      return;
    }

    try {
      let userResponse;

      try {
        userResponse = await AuthService.getCurrentUser();
        setUser(userResponse);
      } catch (err) {
        // Try to refresh token if current one is invalid
        const result = await AuthService.refreshToken();

        LocalStorageService.setAccessToken(result.accessToken);
        LocalStorageService.setRefreshToken(result.refreshToken);

        // Set current user after token refresh
        userResponse = await AuthService.getCurrentUser();
        setUser(userResponse);
      }

      try {
        await setProfileFromUser(userResponse);

        if (userResponse?.status === PROFILE_STATUSES.REJECTED) {
          history.push(CREATE_PROFILE);
          return;
        }
      } catch (err) {
        history.push(CREATE_PROFILE);
        return;
      }

      history.push(ROOT_PATH);
    } catch (err) {
      LocalStorageService.removeAccessToken();
      LocalStorageService.removeRefreshToken();
      LocalStorageService.removeWelcomeLayoutVisibility();
      history.push(ROOT_PATH);
    }
  }, []);

  const logout = useCallback(async () => {
    await AuthService.logout();

    LocalStorageService.removeAccessToken();
    LocalStorageService.removeRefreshToken();
    LocalStorageService.removeWelcomeLayoutVisibility();

    setUser(null);
    setProfile(null);

    history.push(ROOT_PATH);
  }, []);

  const updateCurrentProfile = useCallback(
    async profileData => {
      const newProfile = {
        ...profile,
        ...profileData,
      };

      await ProfileService.updateProfileById(profile.id, newProfile);

      if (newProfile.birthday) {
        const [year, month, day] = newProfile.birthday.split('-');
        newProfile.birthday = new Date(year, month + 1, day).toISOString();
      }

      setProfile(newProfile);
    },
    [profile],
  );

  const updateCurrentUserPhone = useCallback(
    async phone => {
      const newUser = {
        ...user,
        phone,
      };

      await UserService.updateUserById(user.id, newUser);
      setUser(newUser);
    },
    [user],
  );

  const deactivateCurrentUser = useCallback(() => {
    setUser(prevUser => ({
      ...prevUser,
      status: PROFILE_STATUSES.DISABLED,
    }));
  }, [user]);

  const value = {
    user,
    isMainLoading,
    profile,
    checkEmail,
    setProfile,
    login,
    logout,
    register,
    forgotPassword,
    resetPassword,
    refetchUserData,
    updateCurrentProfile,
    updateCurrentUserPhone,
    deactivateCurrentUser,
  };

  return <UserContext.Provider value={{ ...value }}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
