const SvgComponent = props => (
  <svg width={16} height={16} fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 10.333v3.111A1.556 1.556 0 0 1 13.444 15H2.556A1.556 1.556 0 0 1 1 13.444v-3.111M11.89 4.889 8 1 4.111 4.889M8 1v9.333"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
