const SvgComponent = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5398_24542)">
      <path
        d="M5.71373 14.2857L14.2852 9.99995L5.71373 5.71423"
        stroke="#333333"
        strokeWidth="1.28571"
        strokeLinejoin="round"
      />
      <circle cx="4.85714" cy="4.85714" r="1.5" fill="#F4F4F4" stroke="#333333" strokeWidth="1.28571" />
      <circle cx="14.2859" cy="9.99996" r="1.5" fill="#F4F4F4" stroke="#333333" strokeWidth="1.28571" />
      <circle cx="4.85714" cy="15.1429" r="1.5" fill="#F4F4F4" stroke="#333333" strokeWidth="1.28571" />
    </g>
    <defs>
      <clipPath id="clip0_5398_24542">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
