const config = {
  authApiUrl: process.env.REACT_APP_AUTH_API_URL,
  accountApiUrl: process.env.REACT_APP_ACCOUNT_API_URL,
  medicationApiUrl: process.env.REACT_APP_MEDICATION_API_URL,
  geolocationApiUrl: process.env.REACT_APP_GEOLOCATION_API_URL,
  prescriptionApiUrl: process.env.REACT_APP_PRESCRIPTION_API_URL,
  prescriberHelpdeskUrl: process.env.REACT_APP_PRESCRIBER_HELPDESK_URL,
  paymentApiUrl: process.env.REACT_APP_PAYMENT_API_URL,
  orderApiUrl: process.env.REACT_APP_ORDER_API_URL,
  resourceApiUrl: process.env.REACT_APP_RESOURCE_API_URL,
  royalMailTrackingUrl: process.env.REACT_APP_ROYAL_MAIL_TRACKING_URL,
  gmcUKDoctorUrl: process.env.REACT_APP_GMC_UK_DOCTOR_URL,
  pharmacyRegulationPharmacistUrl: process.env.REACT_APP_PHARMACY_REGULATION_PHARMACIST_URL,
  isPatientApp: process.env.REACT_APP_TYPE === 'patient',
  zendeskKey: process.env.REACT_APP_ZENDESK_KEY,
};

export default config;
