import { v4 as uuid } from 'uuid';
import { differenceInHours } from 'date-fns';

import { MainLoader } from '@components/index';
import { ORDER_STATUSES } from '@utils/consts';

export const setFieldForm = (method, errorMethod, nameField, val) => {
  method(nameField, val, false);
  errorMethod(nameField, undefined);
};

export const checkErrorsBySubmit = (errors, fields) =>
  !!Object.keys(errors).length || Object.values(fields).includes('');

export const generateUUID = () => uuid();

export const getCountableStringFromNumber = number => {
  let ord = 'th';

  if (number % 10 === 1 && number % 100 !== 11) {
    ord = 'st';
  } else if (number % 10 === 2 && number % 100 !== 12) {
    ord = 'nd';
  } else if (number % 10 === 3 && number % 100 !== 13) {
    ord = 'rd';
  }

  return `${number}${ord}`;
};

export const isOrderCanBeRefunded = order => {
  return order.status === ORDER_STATUSES.DISPATCHED && differenceInHours(Date.now(), Number(order.createdAt)) < 4;
};

export const loadableOptions = {
  fallback: <MainLoader />,
};
