import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import config from '@config/config';
import ResponsiveFontSizeProvider from '@contexts/ResponsiveFontSize';
import UserProvider from './contexts/User';
import ToastProvider from './contexts/Toast';
import PrescriberApp from './App';
import PatientApp from './_patient/App';

const App = config.isPatientApp ? PatientApp : PrescriberApp;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ResponsiveFontSizeProvider>
        <UserProvider>
          <ToastProvider>
            <App />
          </ToastProvider>
        </UserProvider>
      </ResponsiveFontSizeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
