import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { countries } from 'countries-list';

import { Button, CustomModal } from '@components/index';
import { ADDRESS_TYPE } from '@utils/consts';

import { Address } from '@patient/pages/Auth/CreateProfile/components';

const NewAddressModal = ({ visible, setIsVisible, addressType, onAddNewAddress, countryISO }) => {
  const [fullAddress, setFullAddress] = useState({});

  const getCountry = useCallback(
    () => ({
      key: countryISO,
      value: countryISO,
      countryISO,
      label: countries[countryISO].name,
    }),
    [countryISO],
  );

  const setInitialAddress = useCallback(() => {
    setFullAddress(countryISO ? { country: getCountry() } : {});
  }, [countryISO, getCountry]);

  useEffect(() => {
    setInitialAddress();
  }, [setInitialAddress]);

  const handleAddNewAddress = useCallback(() => {
    onAddNewAddress(fullAddress, addressType);
    setIsVisible(false);
    setInitialAddress();
  }, [fullAddress, addressType, onAddNewAddress, setInitialAddress]);

  const handleCancel = useCallback(() => {
    setIsVisible(false);
    setInitialAddress();
  }, [setInitialAddress]);

  return (
    <CustomModal openModal={visible} setOpenModal={setIsVisible}>
      <div className="w-full h-80 laptop:h-full p-8 bg-white rounded-md overflow-y-auto">
        <p className="font-semibold text-xl laptop:text-3xl mb-8 laptop:mb-16">Add new {addressType} address</p>
        <Address
          address={fullAddress.address}
          country={fullAddress.country}
          city={fullAddress.city}
          postcode={fullAddress.postcode}
          setCountry={country => setFullAddress(prevState => ({ ...prevState, country }))}
          setAddress={address => setFullAddress(prevState => ({ ...prevState, address }))}
          setPostcode={postcode => setFullAddress(prevState => ({ ...prevState, postcode }))}
          setCity={city => setFullAddress(prevState => ({ ...prevState, city }))}
          setShortAddress={shortAddress => setFullAddress(prevState => ({ ...prevState, shortAddress }))}
          countryDisabled={!!countryISO}
        />
        <div className="flex justify-end mt-20">
          <Button className="w-28 mr-1" variant="empty" onClick={handleCancel}>
            <span className="text-xsLh22 laptop:text-base">Cancel</span>
          </Button>
          <Button className="w-28" variant="primary" onClick={handleAddNewAddress}>
            <span className="text-xsLh22 laptop:text-base">Add</span>
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

NewAddressModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  addressType: PropTypes.oneOf(Object.values(ADDRESS_TYPE)).isRequired,
  onAddNewAddress: PropTypes.func.isRequired,
  countryISO: PropTypes.string,
};

NewAddressModal.defaultProps = {
  countryISO: null,
};

export default NewAddressModal;
