import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './DigitInput.module.scss';

const DigitInput = forwardRef(({ value, onChange, onKeyDown, className, index }, ref) => {
  return (
    <input
      onKeyDown={onKeyDown}
      data-index={String(index)}
      ref={ref}
      maxLength={1}
      placeholder="-"
      className={clsx(styles.digitInput, className)}
      type="tel"
      value={value}
      onChange={onChange}
    />
  );
});

DigitInput.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  className: PropTypes.string,
};

DigitInput.defaultProps = {
  className: '',
};

export default DigitInput;
