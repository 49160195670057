import React, { forwardRef, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { CounterMinus, CounterPlus } from '@assets/icons';

import styles from './Counter.module.scss';

const Counter = forwardRef(({ className, defaultValue, maxValue, disabled, value, onChange }, ref) => {
  const inputRef = ref || useRef();

  const formatValue = newValue => {
    let formattedValue;

    if (Number(newValue) < 0 || disabled) {
      return undefined;
    }

    formattedValue = newValue.toString().replace(/\D/g, '') || 0;

    if (Number(newValue) > maxValue) {
      formattedValue = maxValue;
      return undefined;
    }

    return Number(formattedValue);
  };

  const handleChange = useCallback(newValue => {
    inputRef.current.value = formatValue(newValue) ?? inputRef?.current.value;
  }, []);

  const controlledChangeValue = newValue => {
    onChange(formatValue(newValue) ?? value);
  };

  if (value !== undefined && onChange) {
    return (
      <div className={clsx(styles.root, className)}>
        <CounterMinus
          className={disabled ? styles.disabledElement : styles.activeElement}
          disabled={disabled}
          onClick={() => controlledChangeValue(value - 1)}
        />
        <input
          type="text"
          value={value}
          disabled={disabled}
          onChange={event => controlledChangeValue(event.target.value)}
          className={styles.input}
        />
        <CounterPlus
          className={disabled ? styles.disabledElement : styles.activeElement}
          disabled={disabled}
          onClick={() => controlledChangeValue(value + 1)}
        />
      </div>
    );
  }

  return (
    <div className={clsx(styles.root, className)}>
      <CounterMinus
        className={disabled ? styles.disabledElement : styles.activeElement}
        disabled={disabled}
        onClick={() => handleChange(Number(inputRef?.current.value) - 1)}
      />
      <input
        ref={inputRef}
        type="text"
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={event => handleChange(event.target.value)}
        className={styles.input}
      />
      <CounterPlus
        className={disabled ? styles.disabledElement : styles.activeElement}
        disabled={disabled}
        onClick={() => handleChange(Number(inputRef?.current.value) + 1)}
      />
    </div>
  );
});

Counter.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.number,
  maxValue: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

Counter.defaultProps = {
  className: '',
  defaultValue: 0,
  disabled: false,
  value: undefined,
  onChange: undefined,
};

export default React.memo(Counter);
