import * as React from 'react';

const Svg = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 5.00002L12 12M12 12L19 19M12 12L19 5.00002M12 12L5 19"
      stroke="#5A5B61"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Svg;
