import PropTypes from 'prop-types';

const MobileCardItem = ({ item, last }) => (
  <div className="flex w-full flex-col">
    <div className="flex flex-row justify-between">
      <div className="grid grid-cols-2 gap-y-3 gap-x-12">
        <div className="flex flex-col">
          {item.map(i => (
            <>
              <span className="text-sm leading-6 font-normal text-santas-gray">{i.label}</span>
              <span className="text-sm leading-6 font-medium text-davy-grey">{i.value}</span>
            </>
          ))}
        </div>
      </div>
    </div>
    {!last && <div className="w-full h-px my-4" style={{ background: '#EAEAEA' }} />}
  </div>
);

MobileCardItem.propTypes = {
  item: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  last: PropTypes.bool,
};

MobileCardItem.defaultProps = {
  last: false,
};

export default MobileCardItem;
