import { useLayoutEffect, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import LocalStorageService from '@services/localStorage';
import useDevice from '@hooks/useDevice';
import { BackIcon, Logo, LogoWhite } from '@assets/icons';
import { routerType } from '@router/types';
import { Button } from '@components/index';

import { CREATE_PROFILE, PRIVACY_POLICY, ROOT_PATH, TERMS_AND_CONDITIONS } from '@patient/router/paths';

const AuthLayout = ({ route }) => {
  const { isMobile } = useDevice();
  const history = useHistory();
  const location = useLocation();

  const isRootPath = location.pathname === ROOT_PATH;
  const isCreateProfile = useRouteMatch(CREATE_PROFILE);

  const [isVisibleMobileWelcomeLayout, setIsVisibleMobileWelcomeLayout] = useState(
    isRootPath && isMobile && (LocalStorageService.getWelcomeLayoutVisibility() ?? true),
  );

  const handleContinueClick = () => {
    LocalStorageService.setWelcomeLayoutVisibility(false);
    setIsVisibleMobileWelcomeLayout(false);
  };

  useLayoutEffect(() => {
    setIsVisibleMobileWelcomeLayout(
      isRootPath && isMobile && (LocalStorageService.getWelcomeLayoutVisibility() ?? true),
    );
  }, [isRootPath, isMobile]);

  if (isCreateProfile) {
    return isMobile ? (
      <div className="flex h-full w-full pt-20 bg-ghost-white">
        <div className="absolute top-14 left-5">
          <Logo width={9} height={2.5} />
        </div>
        {renderRoutes(route.routes)}
      </div>
    ) : (
      <>{renderRoutes(route.routes)}</>
    );
  }

  return (
    <div className="h-screen max-h-screen grid grid-cols-1 laptop:grid-cols-2">
      {!isMobile && (
        <div
          className="h-full w-full flex justify-center items-center relative flex-col px-5 laptop:px-0"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/prescriber-auth-back.png)`,
            backgroundSize: 'cover',
          }}
        >
          <h1 className="text-h4 laptop:text-h1 font-roboto-medium text-white mb-3 laptop:mb-10 laptop:max-w-lg">
            Welcome to Patient Portal!
          </h1>
          <p className="text-xsLh22 laptop:text-md text-white laptop:max-w-lg opacity-90">
            Specialist Pharmacy is the first compounding pharmacy of its kind, producing personalised custom-made
            medications for a wide range of medical conditions. We are dedicated to helping prescribers offer
            personalised formulations for more effective treatment, and giving patients access to better medication.
          </p>
          {isMobile && (
            <div className="w-full laptop:max-w-lg">
              <Button onClick={handleContinueClick} variant="primary" className="py-3 mt-7 w-full">
                <span className="text-md text-white">Continue</span>
              </Button>
            </div>
          )}
          <p className="absolute bottom-7 text-xs text-philippine-silver">
            © 2022 All Rights Reserved. London Specialist Pharmacy Limited
          </p>
        </div>
      )}
      <div className="h-full w-full flex justify-center items-center laptop:items-center">
        <div className="absolute flex justify-between items-center laptop:w-1/2 w-full top-10 laptop:left-1/2 px-5">
          {isRootPath ? (
            <div />
          ) : (
            <Button onClick={history.goBack} variant="empty">
              <p className="flex flex-row items-center font-bold text-santas-gray text-xsLh22 -ml-5 laptop:ml-0 laptop:text-md">
                <BackIcon /> <span className="ml-2">Get Back</span>
              </p>
            </Button>
          )}
          <Logo width={isMobile ? 9 : 13} height={isMobile ? 2.5 : 3.625} />
        </div>
        {isVisibleMobileWelcomeLayout ? (
          <>
            <div className="z-40 absolute left-0 top-0 p-5">
              <LogoWhite />
            </div>
            <div
              className="h-full w-full flex justify-center items-center relative flex-col px-5 laptop:px-0"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/img/prescriber-auth-back.png)`,
                backgroundSize: 'cover',
              }}
            >
              <h1 className="text-h4 laptop:text-h1 font-roboto-medium text-white mb-3 laptop:mb-10 laptop:max-w-lg">
                Welcome to Patient Portal!
              </h1>
              <p className="text-xsLh22 laptop:text-md text-white laptop:max-w-lg opacity-90">
                Specialist Pharmacy is the first compounding pharmacy of its kind, producing personalised custom-made
                medications for a wide range of medical conditions. We are dedicated to helping prescribers offer
                personalised formulations for more effective treatment, and giving patients access to better medication.
              </p>

              <div className="w-full laptop:max-w-lg">
                <Button onClick={handleContinueClick} variant="primary" className="py-3 mt-7 w-full">
                  <span className="text-md text-white">Continue</span>
                </Button>
              </div>
              <p className="absolute bottom-7 text-xs text-philippine-silver">
                © 2022 All Rights Reserved. London Specialist Pharmacy Limited
              </p>
            </div>
          </>
        ) : (
          <div className="flex items-center flex-grow">{renderRoutes(route.routes)}</div>
        )}
        {!isVisibleMobileWelcomeLayout && (
          <div className="absolute bottom-9 flex flex-row gap-8">
            <Link to={TERMS_AND_CONDITIONS}>
              <span className="text-xsLh22 laptop:text-base text-santas-gray">Terms & Conditions</span>
            </Link>
            <Link to={PRIVACY_POLICY}>
              <span className="text-xsLh22 laptop:text-base text-santas-gray">Privacy Policy</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

AuthLayout.propTypes = routerType;

export default AuthLayout;
