import PrescriberCommon from '@pages/Common';
import { RedirectRoot } from '@pages/RedirectRoot';

import { COMMON, NOT_FOUND, PCT } from '@patient/router/paths';
import Pct from '@patient/pages/Pct';
import { Pill, Sheet, Users } from '@patient/assets/icons';
import PatientCommon from '@patient/pages/Common';

export const PCT_ROUTES = [
  {
    name: 'Patients',
    path: PCT.PATIENTS,
    exact: true,
    component: Pct.Patients,
    menuIcon: Users,
  },
  {
    name: 'Patient',
    path: PCT.PATIENT,
    exact: true,
    component: Pct.Patient,
    hiddenInSidebar: true,
  },
  {
    name: 'Medications',
    path: PCT.MEDICATIONS,
    exact: true,
    component: PatientCommon.Medications,
    hiddenInSidebar: true,
  },
  {
    name: 'Checkout',
    path: PCT.CHECKOUT,
    exact: true,
    component: PatientCommon.Checkout,
    hiddenInSidebar: true,
  },
  {
    name: 'Prescriptions',
    path: PCT.PRESCRIPTIONS,
    exact: true,
    component: Pct.Prescriptions,
    menuIcon: Pill,
  },
  {
    name: 'Create Prescription',
    path: PCT.CREATE_PRESCRIPTION,
    exact: true,
    component: Pct.CreatePrescription,
    hiddenInSidebar: true,
  },
  {
    name: 'Prescription',
    path: COMMON.PRESCRIPTION,
    exact: true,
    component: PrescriberCommon.Prescription,
    hiddenInSidebar: true,
  },
  {
    name: 'Orders',
    path: PCT.ORDERS,
    exact: true,
    component: Pct.Orders,
    menuIcon: Sheet,
  },
  {
    name: 'Profile',
    path: PCT.PROFILE,
    exact: true,
    component: PatientCommon.Profile,
    hiddenInSidebar: true,
  },
  {
    path: NOT_FOUND,
    component: () => <RedirectRoot path={PCT.PATIENTS} />,
  },
];
