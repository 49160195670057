import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { getCountableStringFromNumber } from '@utils/helpers';

import Address from '../Address/Address';
import AddAddress from '../AddAddress';
import DeleteAddress from '../DeleteAddress';

// TODO: Get rid of data mutation
const Addresses = ({ value, setValue, className, title }) => {
  const handleCountryChange = (index, country) => {
    const newValue = [...value];
    newValue[index].country = country;

    setValue(newValue);
  };

  const handleAddressChange = (index, address) => {
    const newValue = [...value];
    newValue[index].address = address;

    setValue(newValue);
  };

  const handlePostcodeChange = (index, postcode) => {
    const newValue = [...value];
    newValue[index].postcode = postcode;

    setValue(newValue);
  };

  const handleShortAddressChange = (index, shortAddress) => {
    const newValue = [...value];
    newValue[index].shortAddress = shortAddress;

    setValue(newValue);
  };

  const handleCityChange = (index, city) => {
    const newValue = [...value];
    newValue[index].city = city;

    setValue(newValue);
  };

  const handleAddAddress = () => {
    setValue([...value, {}]);
  };

  const handleDeleteAddress = index => {
    setValue([...value.slice(0, index), ...value.slice(index + 1, value.length)]);
  };

  return (
    <div className={clsx('w-full', className)}>
      <h2 className="text-default laptop:text-xl font-roboto-medium mb-4 laptop:mb-6">{title} address</h2>
      {value.map((address, index) => {
        const label = getCountableStringFromNumber(index + 1);

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={clsx('grid grid-cols-1 divide-y bg-gray-white mb-5 laptop:mb-6')}>
            <div className={clsx('flex flex-row justify-between items-center mb-2 laptop:mb-4')}>
              <p className="text-xsLh22 laptop:text-base font-roboto-medium">
                <span style={{ color: '#9B9DAE' }}>{`${index === 0 ? 'Default' : label} address`}</span>
              </p>
              {index !== 0 && <DeleteAddress label={label} onClick={() => handleDeleteAddress(index)} />}
            </div>
            <div className={clsx('pt-4')}>
              <Address
                country={address.country}
                city={address.city}
                address={address.address}
                postcode={address.postcode}
                setCountry={country => handleCountryChange(index, country)}
                setAddress={address => handleAddressChange(index, address)}
                setPostcode={postcode => handlePostcodeChange(index, postcode)}
                setCity={city => handleCityChange(index, city)}
                setShortAddress={shortAddress => handleShortAddressChange(index, shortAddress)}
                errors={{}}
              />
            </div>
          </div>
        );
      })}
      <AddAddress onClick={handleAddAddress} />
    </div>
  );
};

Addresses.propTypes = {
  title: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.shape({}),
      address: PropTypes.shape({}),
      postcode: PropTypes.string,
    }),
  ).isRequired,
  setValue: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Addresses.defaultProps = {
  title: 'Home',
  className: '',
};

export default React.memo(Addresses);
