import { USER_ROLES } from '@utils/consts';
import { NOT_FOUND } from '@router/paths';

import { AUTH_ROUTES, PATIENT_ROUTES, ROOT_ROUTES } from '@patient/router';
import { AuthLayout, RootLayout } from '@patient/components';
import { PCT_ROUTES } from '@patient/router/pct';
import { RedirectRoot } from '@pages/RedirectRoot';
import { PATIENT, PCT } from '@patient/router/paths';

export const useRouterPaths = (role, isMainLoading) => {
  if (isMainLoading) {
    return [];
  }

  // TODO: Redirect to prescriber portal
  // if ([USER_ROLES.ADMIN, USER_ROLES.PRESCRIBER].includes(role)) {
  //   return [];
  // }

  if (role === USER_ROLES.PCT) {
    return [
      ...ROOT_ROUTES,
      {
        component: RootLayout,
        routes: PCT_ROUTES,
      },
      {
        path: NOT_FOUND,
        component: () => <RedirectRoot path={PCT.PATIENTS} />,
      },
    ];
  }

  if (role === USER_ROLES.PATIENT) {
    return [
      ...ROOT_ROUTES,
      {
        component: RootLayout,
        routes: PATIENT_ROUTES,
      },
      {
        path: NOT_FOUND,
        component: () => <RedirectRoot path={PATIENT.MEDICATIONS} />,
      },
    ];
  }

  return [
    ...ROOT_ROUTES,
    {
      component: AuthLayout,
      routes: AUTH_ROUTES,
    },
  ];
};
