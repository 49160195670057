import PropTypes from 'prop-types';

const MobileMedicationItem = ({ item, last }) => (
  <div className="flex w-full flex-col">
    <div className="flex flex-row justify-between">
      <div className="grid grid-cols-2 gap-y-3 gap-x-12">
        <div className="flex flex-col">
          <span className="text-sm leading-6 font-normal text-santas-gray">Product/composition</span>
          <span className="text-sm leading-6 font-medium text-davy-grey">{item.productComposition}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm leading-6 font-normal text-santas-gray">Quantity</span>
          <span className="text-sm leading-6 font-medium text-davy-grey">{item.quantity}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm leading-6 font-normal text-santas-gray">Price</span>
          <span className="text-sm leading-6 font-medium text-davy-grey">{item.price}</span>
        </div>
      </div>
    </div>
    {!last && <div className="w-full h-px my-4 bg-bright-gray" />}
  </div>
);

MobileMedicationItem.propTypes = {
  item: PropTypes.shape({
    productComposition: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.string,
  }).isRequired,
  last: PropTypes.bool,
};

MobileMedicationItem.defaultProps = {
  last: false,
};

export default MobileMedicationItem;
