import React from 'react';
import PropTypes from 'prop-types';

const ColorDot = ({ color }) => <div className="w-2 h-2 rounded-full" style={{ background: color }} />;

ColorDot.propTypes = {
  color: PropTypes.string.isRequired,
};

export default React.memo(ColorDot);
