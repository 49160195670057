export const ROOT_PATH = '/';
export const NOT_FOUND = '*';

export const AUTH = {
  CHECK_EMAIL: `${ROOT_PATH}check-email`,
  SIGN_IN: `${ROOT_PATH}sign-in`,
  SIGN_UP: `${ROOT_PATH}sign-up`,
  CHECK_CODE: `${ROOT_PATH}check-code`,
  RESET_PASSWORD: {
    INFO: `${ROOT_PATH}info`,
    FORGOT_PASSWORD: `${ROOT_PATH}forgot-password`,
    NEW_PASSWORD: `${ROOT_PATH}reset-password`,
  },
};

export const CREATE_PROFILE = `${ROOT_PATH}create-profile`;

export const PATIENT = {
  MEDICATIONS: `${ROOT_PATH}medications`,
  ORDERS: `${ROOT_PATH}orders`,
  FAQ: `${ROOT_PATH}faq`,
  CONTACT: `${ROOT_PATH}contact`,
  FEEDBACK: `${ROOT_PATH}feedback`,
  CHECKOUT: `${ROOT_PATH}checkout`,
  PROFILE: `${ROOT_PATH}profile`,
};

export const PCT = {
  PATIENTS: `${ROOT_PATH}patients`,
  PATIENT: `${ROOT_PATH}patients/:id`,
  PROFILE: `${ROOT_PATH}patients/:id/profile`,
  PRESCRIPTIONS: `${ROOT_PATH}prescriptions`,
  CREATE_PRESCRIPTION: `${ROOT_PATH}prescriptions/create`,
  ORDERS: `${ROOT_PATH}orders`,
  MEDICATIONS: `${ROOT_PATH}patients/:id/medications`,
  CHECKOUT: `${ROOT_PATH}checkout`,
};

export const COMMON = {
  PRESCRIPTION: `${ROOT_PATH}prescriptions/:id`,
};

export const TERMS_AND_CONDITIONS = `${ROOT_PATH}terms-and-conditions`;
export const PRIVACY_POLICY = `${ROOT_PATH}privacy-policy`;
