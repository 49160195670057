import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { RightArrow } from '@assets/icons';

import useResizeObserver from 'use-resize-observer';
import styles from './ExpandingBlock.module.scss';

const ExpandingBlock = ({ step, title, complete, children, expanded, className }) => {
  const { ref, height = 1, width = 1 } = useResizeObserver();
  const childrenRef = useRef();

  const [isExpanded, setIsExpanded] = useState(expanded);
  const [contentHeight, setContentHeight] = useState(null);

  const handleClick = () => {
    setIsExpanded(prevState => !prevState);
  };

  useEffect(() => {
    setContentHeight(childrenRef.current?.offsetHeight);
  }, [height, width]);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <div
      ref={ref}
      style={{
        height: isExpanded ? `calc(6rem + ${contentHeight}px)` : '6rem',
      }}
      className={clsx('overflow-hidden rounded-xl border', styles.root, complete && 'border-iguana-green', className)}
    >
      <div onClick={handleClick} className={clsx('flex items-center p-8 cursor-pointer')}>
        <span className="flex items-center justify-center w-7 h-7 rounded-full border-2 border-iguana-green text-iguana-green font-semibold text-xsLh22 laptop:text-base">
          {step}
        </span>
        <span
          className={clsx(
            'inline-block ml-2 laptop:ml-4 mr-0 laptop:mr-10 text-md laptop:text-xl transition-all',
            isExpanded
              ? 'font-semibold font-roboto-medium text-dark-charcoal'
              : 'font-normal font-roboto-regular text-davy-grey',
          )}
        >
          {title}
        </span>
        <RightArrow className={clsx('ml-auto', styles.rightArrow, isExpanded && styles.rightArrow90)} />
      </div>
      <div ref={childrenRef} className="px-5 laptop:px-8 pb-5 laptop:pb-8">
        {children}
      </div>
    </div>
  );
};

ExpandingBlock.propTypes = {
  className: PropTypes.string,
  step: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  complete: PropTypes.bool,
  expanded: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ExpandingBlock.defaultProps = {
  className: '',
  complete: false,
  expanded: false,
};

export default ExpandingBlock;
