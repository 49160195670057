import { useLayoutEffect, useState, useMemo } from 'react';
import useResize from './useResize';

const useDevice = () => {
  const { windowWidth } = useResize();

  const [deviceInfo, setDeviceInfo] = useState({ isMobile: false });

  const { mobile: isMobileAltMethod = false } =
    useMemo(() => navigator?.userAgentData, [navigator?.userAgentData]) || {};

  useLayoutEffect(() => {
    setDeviceInfo({ isMobile: windowWidth < 900 || isMobileAltMethod });
  }, [windowWidth, isMobileAltMethod]);

  return deviceInfo;
};

export default useDevice;
