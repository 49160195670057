import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';

import { ChevronRight, ChevronLeft } from '@patient/assets/icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FileViewer = ({ className, file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const isImage = !!file?.isImage;
  const isPDF = !!file?.isPDF;
  const isLeftArrow = pageNumber > 1;
  const isRightArrow = pageNumber < numPages;

  return (
    <div className={className}>
      {isImage && (
        <div className="w-full overflow-scroll">
          <img src={file?.fileUrl} alt="File" />
        </div>
      )}
      {isPDF && (
        <div className="w-max">
          <Document className="flex flex-col" file={file?.fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
            <div className="flex flex-row items-center justify-center">
              {isLeftArrow && (
                <div className="cursor-pointer" onClick={() => setPageNumber(pageNumber - 1)}>
                  <ChevronLeft />
                </div>
              )}
              <span>
                Page {pageNumber} / {numPages}
              </span>
              {isRightArrow && (
                <div className="cursor-pointer" onClick={() => setPageNumber(pageNumber + 1)}>
                  <ChevronRight />
                </div>
              )}
            </div>
          </Document>
        </div>
      )}
    </div>
  );
};

FileViewer.propTypes = {
  className: PropTypes.string,
  file: PropTypes.shape({
    fileUrl: PropTypes.string,
    isImage: PropTypes.bool,
    isPDF: PropTypes.bool,
  }),
};

FileViewer.defaultProps = {
  className: '',
  file: null,
};

export default FileViewer;
