import React, { useState, useMemo, useReducer, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Select, MultiSelect } from '@components/index';
import { useTypeList } from '@hooks/ingredientType';
import { useIngredientList } from '@hooks/ingredient';
import { useMedicationList } from '@hooks/medication';
import { MEDICATION_CATEGORIES, MEDICATION_TYPES } from '@utils/consts';
import { getNonCompoundedLabel } from '@utils/medication';

import IngredientsDosageSelect from '../IngredientsDosageSelect';
import styles from './MedicationConstructor.module.scss';

const medicationTypesArray = Object.values(MEDICATION_TYPES);
const getIsCornerTab = tab => {
  const index = medicationTypesArray.findIndex(type => tab === type);
  return index === 0 || index === medicationTypesArray.length - 1;
};

const compoundedFormulationInitialValue = {
  type: null,
  ingredients: [],
  ingredientsDosage: {},
};

const compoundedFormulationActions = {
  SET_TYPE: 'SET_TYPE',
  SET_INGREDIENTS: 'SET_INGREDIENTS',
  SET_INGREDIENTS_DOSAGE: 'SET_INGREDIENTS_DOSAGE',
};

const compoundedFormulationReducer = (state, action) => {
  const updatedIngredientsDosage = {};

  switch (action.type) {
    case compoundedFormulationActions.SET_TYPE:
      return { type: action.payload, ingredients: [], ingredientsDosage: {} };
    case compoundedFormulationActions.SET_INGREDIENTS:
      if (!state.ingredientsDosage || !action.payload) {
        return { type: state.type, ingredients: action.payload, ingredientsDosage: {} };
      }

      action.payload.forEach(ingredient => {
        if (state.ingredientsDosage[ingredient.id]) {
          updatedIngredientsDosage[ingredient.id] = state.ingredientsDosage[ingredient.id];
        }
      });

      return { type: state.type, ingredients: action.payload, ingredientsDosage: updatedIngredientsDosage };
    case compoundedFormulationActions.SET_INGREDIENTS_DOSAGE:
      return { type: state.type, ingredients: state.ingredients, ingredientsDosage: action.payload };
    default:
      throw new Error();
  }
};

const MedicationConstructor = ({ medicationType, medicationObject, onChange, error }) => {
  const [activeTab, setActiveTab] = useState(medicationType || MEDICATION_TYPES.COMPOUNDED);

  const [ingredients] = useIngredientList();
  const [types] = useTypeList(type => type.isActive);
  const [medications] = useMedicationList(medication => medication.isActive);

  const [compoundedFormulation, dispatch] = useReducer(
    compoundedFormulationReducer,
    medicationType === MEDICATION_TYPES.COMPOUNDED
      ? { ...medicationObject, ingredients: medicationObject.ingredients.map(ingredient => ingredient.id) }
      : compoundedFormulationInitialValue,
  );

  const [licensedMedication, setLicensedMedication] = useState(
    medicationType === MEDICATION_TYPES.LICENSED ? medicationObject : null,
  );

  const [unlicensedMedication, setUnlicensedMedication] = useState(
    medicationType === MEDICATION_TYPES.UNLICENSED ? medicationObject : null,
  );

  const [vitaminMedication, setVitaminMedication] = useState(
    medicationType === MEDICATION_TYPES.VITAMIN ? medicationObject : null,
  );

  const onValueChange = (setFn, value) => {
    setFn(value);
  };

  useEffect(() => {
    if (activeTab === MEDICATION_TYPES.COMPOUNDED) {
      onChange({
        medicationType: MEDICATION_TYPES.COMPOUNDED,
        medicationObject: {
          code: null,
          type: compoundedFormulation.type,
          ingredients: ingredients.filter(ingredient => compoundedFormulation.ingredients.includes(ingredient.id)),
          ingredientsDosage: compoundedFormulation.ingredientsDosage,
        },
      });

      return;
    }

    if (activeTab === MEDICATION_TYPES.LICENSED) {
      onChange({
        medicationType: MEDICATION_TYPES.LICENSED,
        medicationObject: {
          ...licensedMedication,
        },
      });

      return;
    }

    if (activeTab === MEDICATION_TYPES.UNLICENSED) {
      onChange({
        medicationType: MEDICATION_TYPES.UNLICENSED,
        medicationObject: {
          ...unlicensedMedication,
        },
      });

      return;
    }

    if (activeTab === MEDICATION_TYPES.VITAMIN) {
      onChange({
        medicationType: MEDICATION_TYPES.VITAMIN,
        medicationObject: {
          ...vitaminMedication,
        },
      });
    }
  }, [compoundedFormulation, licensedMedication, unlicensedMedication, vitaminMedication, activeTab]);

  const onSelectIngredientClick = ingredient => {
    if (!compoundedFormulation.ingredients.includes(ingredient.value)) {
      dispatch({
        type: compoundedFormulationActions.SET_INGREDIENTS,
        payload: [...compoundedFormulation.ingredients, ingredient.value],
      });

      return;
    }

    dispatch({
      type: compoundedFormulationActions.SET_INGREDIENTS,
      payload: compoundedFormulation.ingredients.filter(value => value !== ingredient.value),
    });
  };

  const compoundedTypeOptions = useMemo(() => {
    if (!types) {
      return [];
    }

    return types
      .map(type => ({
        ...type,
        label: type.name,
        value: type.id,
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }, [types]);

  const compoundedIngredientOptions = useMemo(() => {
    if (!compoundedFormulation.type) {
      return [];
    }

    return ingredients
      .filter(ingredient => ingredient.types.some(type => type.id === compoundedFormulation.type.id))
      .map(ingredient => ({
        ...ingredient,
        label: ingredient.name,
        value: ingredient.id,
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }, [ingredients, compoundedFormulation.type]);

  const licensedMedicationOptions = useMemo(() => {
    if (!medications) {
      return [];
    }

    return medications
      .filter(medication => medication.category === MEDICATION_CATEGORIES.LICENSED)
      .map(medication => ({
        ...medication,
        label: getNonCompoundedLabel(medication),
        value: medication.id,
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }, [medications]);

  const unlicensedMedicationOptions = useMemo(() => {
    if (!medications) {
      return [];
    }

    return medications
      .filter(medication => medication.category === MEDICATION_CATEGORIES.UNLICENSED)
      .map(medication => ({
        ...medication,
        label: getNonCompoundedLabel(medication),
        value: medication.id,
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }, [medications]);

  const vitaminMedicationOptions = useMemo(() => {
    if (!medications) {
      return [];
    }

    return medications
      .filter(medication => medication.category === MEDICATION_CATEGORIES.VITAMIN)
      .map(medication => ({
        ...medication,
        label: getNonCompoundedLabel(medication),
        value: medication.id,
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }, [medications]);

  const filteredIngredients = useMemo(
    () => ingredients.filter(ingredient => compoundedFormulation.ingredients.includes(ingredient.id)),
    [ingredients, compoundedFormulation.ingredients],
  );

  const renderMedicationValues = () => {
    if (activeTab === MEDICATION_TYPES.COMPOUNDED) {
      return (
        <>
          <Select
            key="compoundedType"
            label="Type"
            placeholder="Select Type"
            options={compoundedTypeOptions}
            value={compoundedFormulation.type}
            onChange={type =>
              onValueChange(() => {
                dispatch({ type: compoundedFormulationActions.SET_TYPE, payload: type });
              }, type)
            }
            error={error?.type}
            isSearchable
          />
          <MultiSelect
            label="Ingredients"
            placeholder="Select Ingredients"
            selectedValues={compoundedFormulation.ingredients}
            onOptionClick={onSelectIngredientClick}
            options={compoundedIngredientOptions}
            error={error?.ingredients}
            isDisabled={!compoundedFormulation.type}
          />
          <IngredientsDosageSelect
            ingredients={filteredIngredients}
            measurement={compoundedFormulation.type?.measurement}
            label="Ingredients dosage"
            placeholder="Select Ingredients dosage"
            initialValue={medicationObject ? medicationObject.ingredientsDosage : null}
            onChange={value =>
              onValueChange(() => {
                dispatch({ type: compoundedFormulationActions.SET_INGREDIENTS_DOSAGE, payload: value });
              }, value)
            }
            error={error?.ingredientsDosage}
            isDisabled={!compoundedFormulation.ingredients?.length}
          />
        </>
      );
    }

    // Make sure we don't use compounded medication error on other
    const errorString = typeof error === 'string' ? error : '';

    if (activeTab === MEDICATION_TYPES.LICENSED) {
      return (
        <Select
          key="licensedMedication"
          label="Medication"
          placeholder="Select Medications"
          options={licensedMedicationOptions}
          value={licensedMedication}
          onChange={medication => onValueChange(setLicensedMedication, medication)}
          error={errorString}
          isSearchable
        />
      );
    }

    if (activeTab === MEDICATION_TYPES.UNLICENSED) {
      return (
        <Select
          key="unlicensedMedication"
          label="Medication"
          placeholder="Select Medications"
          options={unlicensedMedicationOptions}
          value={unlicensedMedication}
          onChange={medication => onValueChange(setUnlicensedMedication, medication)}
          error={errorString}
          isSearchable
        />
      );
    }

    if (activeTab === MEDICATION_TYPES.VITAMIN) {
      return (
        <Select
          key="vitaminMedication"
          label="Medication"
          placeholder="Select Medications"
          options={vitaminMedicationOptions}
          value={vitaminMedication}
          onChange={medication => onValueChange(setVitaminMedication, medication)}
          error={errorString}
          isSearchable
        />
      );
    }

    return <></>;
  };

  const isCornerTab = useMemo(() => getIsCornerTab(activeTab), [activeTab]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        {Object.values(MEDICATION_TYPES).map(typeKey => (
          <div
            key={typeKey}
            className={clsx('p-6 cursor-pointer rounded-t-md')}
            style={{ background: typeKey === activeTab ? '#F7F7FA' : 'transparent' }}
            onClick={() => setActiveTab(typeKey)}
          >
            <span
              className={clsx(
                'text-base font-roboto-medium',
                typeKey === activeTab ? 'text-iguana-green' : 'text-silver-sand',
              )}
            >
              {typeKey}
            </span>
          </div>
        ))}
      </div>
      <div
        className={clsx(
          'grid grid-rows-3 grid-cols-1 p-6 pb-8 gap-6',
          isCornerTab ? 'rounded-b-md' : 'rounded-md',
          styles.inputs,
        )}
        style={{ background: '#F7F7FA' }}
      >
        {renderMedicationValues()}
      </div>
    </div>
  );
};

MedicationConstructor.propTypes = {
  medicationType: PropTypes.oneOf(['', ...Object.values(MEDICATION_TYPES)]),
  medicationObject: PropTypes.shape({
    ingredients: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    ingredientsDosage: PropTypes.shape({}),
  }),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      type: PropTypes.string,
      ingredients: PropTypes.string,
      ingredientsDosage: PropTypes.string,
    }),
  ]),
};

MedicationConstructor.defaultProps = {
  medicationType: MEDICATION_TYPES.COMPOUNDED,
  medicationObject: null,
  error: null,
};

export default React.memo(MedicationConstructor);
