import PropTypes from 'prop-types';

const SvgComponent = ({ active, disabled, ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {active ? (
      <path
        d="M22.3142 10.172L20.8992 11.586L20.1922 10.879L15.9502 15.121L15.2432 18.657L13.8282 20.071L9.58617 15.828L4.63617 20.778L3.22217 19.364L8.17217 14.414L3.92917 10.172L5.34317 8.75704L8.88017 8.05004L13.1222 3.80804L12.4152 3.10104L13.8292 1.68604L22.3142 10.172Z"
        fill="#9B9DAE"
      />
    ) : (
      <path
        d="M13.8282 1.68597L22.3142 10.172L20.8992 11.586L20.1922 10.879L15.9502 15.121L15.2432 18.657L13.8282 20.071L9.58617 15.828L4.63617 20.778L3.22217 19.364L8.17217 14.414L3.92917 10.172L5.34317 8.75697L8.88017 8.04997L13.1222 3.80797L12.4152 3.10097L13.8292 1.68597H13.8282ZM14.5362 5.22197L9.86517 9.89197L7.04317 10.457L13.5432 16.957L14.1072 14.135L18.7782 9.46497L14.5362 5.22197V5.22197Z"
        fill={disabled ? 'rgba(155, 157, 174, 0.4)' : '#9B9DAE'}
      />
    )}
  </svg>
);

SvgComponent.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

SvgComponent.defaultProps = {
  active: false,
  disabled: false,
};

export default SvgComponent;
