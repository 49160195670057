import { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAutocomplete } from '@hooks/geolocation';
import Select from '../Select';

const Address = ({ countryISO, onClickCustomOption, ...props }) => {
  const debounceRef = useRef();
  const { search } = useAutocomplete();

  const handleInputChange = newValue => {
    const newInputValue = newValue.replace(/\W/g, '');
    return newInputValue;
  };

  useEffect(() => {
    return () => clearTimeout(debounceRef.current);
  }, []);

  const loadOptions = useCallback(
    (inputValue, callback) => {
      clearTimeout(debounceRef.current);
      debounceRef.current = setTimeout(async () => {
        const result = await search(countryISO, encodeURIComponent(inputValue));
        const options = result.map(({ id, label, type }) => ({ label, value: id, type }));

        callback(options);
      }, 600);
    },
    [debounceRef.current, countryISO],
  );

  return (
    <Select
      isAsync
      cacheOptions
      loadOptions={loadOptions}
      onInputChange={handleInputChange}
      onClickCustomOption={onClickCustomOption}
      noOptionsMessage={() => 'Type to search'}
      isClearable
      backspaceRemovesValue
      isCreatable
      {...props}
    />
  );
};

Address.propTypes = {
  countryISO: PropTypes.string,
  onClickCustomOption: PropTypes.func,
};

Address.defaultProps = {
  onClickCustomOption: null,
  countryISO: '',
};

export default Address;
