import PropTypes from 'prop-types';

const SvgComponent = ({ disabled, ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={disabled ? 0.2 : 1}
      d="M7.9165 14.168L12.0832 10.0013L7.9165 5.83464"
      stroke="#333333"
      strokeWidth="1.8"
    />
  </svg>
);

SvgComponent.propTypes = {
  disabled: PropTypes.bool,
};

SvgComponent.defaultProps = {
  disabled: false,
};

export default SvgComponent;
