import useDevice from '@hooks/useDevice';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import styles from './Checkbox.module.scss';

const Checkbox = ({ width, height, onChange, label, checked, className, checkedBgColor, checkColor, isDisabled }) => {
  const { isMobile } = useDevice();
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={clsx(styles.checkbox, 'flex items-center relative', className, `w-[${width}rem] h-[${height}rem]`)}
    >
      <span
        className={clsx(styles.checkbox__input, checked && styles.checkbox__input_checked)}
        style={{
          width: `${width}rem`,
          height: `${height}rem`,
          backgroundColor: checked && checkedBgColor ? checkedBgColor : '',
        }}
      >
        <input checked={checked} name="checkbox" type="checkbox" onChange={onChange} disabled={isDisabled} />
        <span
          className={clsx(
            styles.checkbox__control,
            checked && isMobile
              ? 'border-2 border-solid border-iguana-green'
              : checked && styles.checkbox__control_checked,
          )}
          style={{
            width: `${width}rem`,
            height: `${height}rem`,
            backgroundColor: checked && checkedBgColor ? checkedBgColor : '',
          }}
        >
          <svg aria-hidden="true" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.73 12.91l6.37 6.37L22.79 4.59"
              fill="none"
              stroke={checkColor || 'currentColor'}
              strokeWidth="3"
            />
          </svg>
        </span>
      </span>
      {label && <span className="text-sm color-black ml-4">{label}</span>}
    </label>
  );
};

Checkbox.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onChange: PropTypes.func,
  label: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  checkColor: PropTypes.string,
  checkedBgColor: PropTypes.string,
  isDisabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  height: 1.563,
  width: 1.563,
  onChange: () => {},
  label: '',
  checked: false,
  className: '',
  checkColor: '',
  checkedBgColor: '',
  isDisabled: false,
};

export default Checkbox;
