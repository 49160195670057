import clsx from 'clsx';
import PropTypes from 'prop-types';

const SvgComponent = ({ className, ...props }) => (
  <svg
    className={clsx('w-3.5 h-4', className)}
    viewBox="0 0 13 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.15 3.502 8.748.1a.34.34 0 0 0-.241-.1H1.36C.611 0 0 .61 0 1.361v13.611c0 .75.61 1.361 1.361 1.361h9.528c.75 0 1.361-.61 1.361-1.36V3.742a.34.34 0 0 0-.1-.24Zm-3.303-2.34 2.241 2.24h-1.56a.681.681 0 0 1-.68-.68v-1.56Zm2.723 13.81c0 .375-.306.68-.681.68H1.36a.681.681 0 0 1-.68-.68V1.362C.68.985.986.68 1.36.68h6.806v2.041c0 .75.61 1.361 1.36 1.361h2.043v10.89Z"
      fill="#333"
    />
    <path
      d="M8.72 9.602a.341.341 0 0 0-.48.053l-1.002 1.254-1.551-2.172a1.7 1.7 0 0 0 1.118-1.592c0-.938-.763-1.7-1.701-1.7H3.743a.34.34 0 0 0-.34.34v4.763a.34.34 0 1 0 .68 0V8.847h.846l1.867 2.614-1.278 1.597a.34.34 0 0 0 .532.425l1.157-1.446 1.023 1.431a.34.34 0 1 0 .553-.395L7.65 11.485l1.123-1.405a.34.34 0 0 0-.053-.478ZM4.082 8.166V6.125h1.02a1.022 1.022 0 0 1 0 2.041h-1.02Z"
      fill="#333"
    />
  </svg>
);

SvgComponent.propTypes = {
  className: PropTypes.string,
};

SvgComponent.defaultProps = {
  className: '',
};

export default SvgComponent;
