import { memo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import clsx from 'clsx';

import { CustomModal, Button, Input, Select } from '@components/index';
import { ORDER_REFUND_OPTIONS } from '@utils/consts';
import { validationRefundOrderReason } from '@utils/validation';
import { setFieldForm } from '@utils/helpers';

import styles from './RefundOrderModal.module.scss';

const optionsArray = Object.values(ORDER_REFUND_OPTIONS);

const RefundOrderModal = ({ open, setOpenModal, onConfirm, loading }) => {
  const handleFormSubmit = form => {
    const reason =
      form.option?.value === ORDER_REFUND_OPTIONS.OTHER.value ? form.customMessage : form.option?.label || '';

    onConfirm(reason);
  };

  const { handleSubmit, setFieldValue, values, errors, setFieldError } = useFormik({
    initialValues: {
      option: null,
      customMessage: '',
    },
    validationSchema: validationRefundOrderReason,
    onSubmit: handleFormSubmit,
  });

  const isShowCustomInput = values.option?.value === ORDER_REFUND_OPTIONS.OTHER.value;

  return (
    <CustomModal openModal={open} setOpenModal={setOpenModal} className={styles.refundOrderModal}>
      <form className="p-8 bg-white rounded-md" onSubmit={handleSubmit}>
        <h4 className="text-xl text-dark-charcoal font-roboto-medium mb-2">Order refund</h4>
        <p className="text-davy-grey mb-8 pr-2">
          Are you sure you want to refund the order? If so, write down the reason for the refund or pick an option. This
          will help us improve the service performance. Order refund is available only in 4 hours after the order
          creation.
        </p>
        <Select
          className={clsx('flex-1', isShowCustomInput ? 'mb-1.5' : 'mb-4')}
          value={values.option}
          error={!!errors.option}
          options={optionsArray}
          onChange={e => setFieldForm(setFieldValue, setFieldError, 'option', e)}
          label="Order refund reason"
          placeholder="Select a reason"
        />
        {isShowCustomInput && (
          <Input
            className="flex-1 mb-8"
            value={values.customMessage}
            error={errors.customMessage}
            onChange={e => setFieldForm(setFieldValue, setFieldError, 'customMessage', e.target.value)}
            placeholder="Type a reason"
          />
        )}
        <div className="flex flex-row justify-end">
          <Button onClick={() => setOpenModal(false)} className="px-6 py-3 mr-6" variant="empty">
            <span>Cancel</span>
          </Button>
          <Button type="submit" variant="danger" className="px-6 py-3" isLoading={loading}>
            <span>Refund</span>
          </Button>
        </div>
      </form>
    </CustomModal>
  );
};

RefundOrderModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  setOpenModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

RefundOrderModal.defaultProps = {
  open: false,
  loading: false,
};

export default memo(RefundOrderModal);
