import loadable from '@loadable/component';
import { loadableOptions } from '@utils/helpers';

const Admin = {
  Allergies: loadable(() => import('./Allergies'), loadableOptions),
  Doctors: loadable(() => import('./Doctors'), loadableOptions),
  Ingredients: loadable(() => import('./Ingredients'), loadableOptions),
  Medications: loadable(() => import('./Medications'), loadableOptions),
  DoctorInfo: loadable(() => import('./DoctorInfo'), loadableOptions),
};

export default Admin;
