import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = ({ fill, ...props }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.48153 3.99239C7.39299 3.87849 7.28122 3.78473 7.15365 3.71734C7.02608 3.64995 6.88563 3.61047 6.74164 3.60154C6.59764 3.5926 6.45339 3.6144 6.31847 3.6655C6.18354 3.71659 6.06104 3.79581 5.95908 3.8979L4.40813 5.45036C3.68365 6.17634 3.41666 7.20382 3.73315 8.10529C5.04672 11.8366 7.18349 15.2243 9.98496 18.0171C12.7778 20.8185 16.1654 22.9553 19.8966 24.2689C20.7981 24.5854 21.8256 24.3184 22.5516 23.5939L24.1025 22.043C24.2046 21.941 24.2838 21.8185 24.3349 21.6836C24.386 21.5486 24.4078 21.4044 24.3989 21.2604C24.3899 21.1164 24.3505 20.9759 24.2831 20.8484C24.2157 20.7208 24.1219 20.609 24.008 20.5205L20.5476 17.8296C20.4259 17.7352 20.2844 17.6697 20.1337 17.638C19.983 17.6063 19.8271 17.6092 19.6777 17.6466L16.3928 18.467C15.9543 18.5766 15.4949 18.5708 15.0594 18.4502C14.6238 18.3295 14.2269 18.0981 13.9073 17.7786L10.2234 14.0931C9.90361 13.7737 9.67196 13.3769 9.55104 12.9413C9.43012 12.5058 9.42407 12.0463 9.53347 11.6077L10.3554 8.32279C10.3928 8.17339 10.3957 8.01745 10.364 7.86674C10.3323 7.71604 10.2668 7.5745 10.1725 7.45281L7.48153 3.99239ZM4.82662 2.76692C5.0891 2.50435 5.40444 2.30063 5.7517 2.16928C6.09895 2.03793 6.47018 1.98196 6.84073 2.00508C7.21127 2.02821 7.57266 2.1299 7.90089 2.3034C8.22913 2.4769 8.51669 2.71825 8.7445 3.01142L11.4354 6.47033C11.9289 7.10482 12.1029 7.9313 11.9079 8.71128L11.0874 11.9962C11.045 12.1663 11.0473 12.3446 11.0941 12.5136C11.1409 12.6825 11.2305 12.8366 11.3544 12.9607L15.0398 16.6461C15.164 16.7702 15.3183 16.86 15.4876 16.9068C15.6569 16.9536 15.8354 16.9558 16.0058 16.9131L19.2892 16.0926C19.6741 15.9964 20.0758 15.9889 20.464 16.0707C20.8523 16.1526 21.2168 16.3216 21.5301 16.5651L24.989 19.256C26.2324 20.2235 26.3464 22.061 25.2335 23.1724L23.6825 24.7234C22.5726 25.8334 20.9136 26.3208 19.3672 25.7764C15.4091 24.3837 11.8153 22.1177 8.85249 19.1465C5.88152 16.1841 3.61557 12.5909 2.2227 8.63328C1.67972 7.08832 2.1672 5.42786 3.27717 4.31789L4.82812 2.76692H4.82662Z"
      fill={fill}
    />
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: 'var(--colors-santas-gray)',
};

export default SvgComponent;
