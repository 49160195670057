import { Children, cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { CarouselLeftArrow, CarouselRightArrow } from '@assets/icons';

import styles from './Carousel.module.scss';

const Carousel = ({ children, className }) => {
  const [count, setCount] = useState(children?.length);
  const [slide, setSlide] = useState(0);

  const moveRight = () => {
    setSlide(prevState => prevState + 1);
  };

  const moveLeft = () => {
    setSlide(prevState => prevState - 1);
  };

  useEffect(() => {
    if (slide > count - 1) {
      setSlide(0);
    }

    if (slide < 0) {
      setSlide(count - 1);
    }
  }, [slide]);

  useEffect(() => {
    setCount(children.length);
  }, [children?.length]);

  return (
    <div className={clsx(className)}>
      <div className={clsx(styles.transparentBox)}>
        <div
          className={clsx(styles.itemsBox)}
          style={{
            transform: `translateX(-${slide}00%)`,
          }}
        >
          {Children.map(children, child =>
            cloneElement(child, {
              style: {
                height: '100%',
                maxWidth: '100%',
                minWidth: '100%',
                whiteSpace: 'nowrap',
              },
            }),
          )}
        </div>
      </div>
      <div className="flex justify-between mt-10">
        <div className={clsx(styles.dots)}>
          {Array(count)
            .fill(1)
            // eslint-disable-next-line no-unused-vars
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={clsx(styles.dot, index === slide && styles.active)} />
            ))}
        </div>
        <div className={clsx('flex items-center')}>
          <CarouselLeftArrow
            onClick={moveLeft}
            className="rounded-md w-8 h-8 p-1 border cursor-pointer hover:bg-white-lilac transition"
          />
          <CarouselRightArrow
            onClick={moveRight}
            className="ml-2 rounded-md w-8 h-8 p-1 border cursor-pointer hover:bg-white-lilac transition"
          />
        </div>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Carousel.defaultProps = {
  className: '',
};

export default Carousel;
