import * as React from 'react';
import PropTypes from 'prop-types';

function AllergyIcon({ fill, ...props }) {
  return (
    <svg fill="none" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m16.003 2.271 4.357 1.673-.837 2.179-1.09-.42-.87 2.27a7.049 7.049 0 0 1 2.27 2.154l2.22-.989-.476-1.066 2.132-.948 1.898 4.263-2.131.948L23 11.27l-2.22.988a7.024 7.024 0 0 1 .082 3.127l2.268.87.418-1.088 2.18.836-1.674 4.357-2.178-.837.418-1.088-2.268-.872a7.049 7.049 0 0 1-2.154 2.27l.988 2.22 1.067-.476.948 2.132-4.263 1.898-.948-2.131L16.73 23l-.988-2.22a7.024 7.024 0 0 1-3.128.081l-.87 2.27 1.09.417-.837 2.18-4.357-1.674.837-2.178 1.087.418.873-2.268a7.064 7.064 0 0 1-2.27-2.154l-2.22.988.474 1.067-2.131.948-1.898-4.263 2.131-.948.474 1.066 2.22-.99a7.082 7.082 0 0 1-.08-3.126l-2.27-.87-.416 1.088-2.18-.836 1.673-4.355 2.179.836-.42 1.089 2.27.871a7.056 7.056 0 0 1 2.154-2.271l-.989-2.22-1.066.474-.948-2.131 4.263-1.897.948 2.131L11.27 5l.988 2.22a7.027 7.027 0 0 1 3.127-.083l.869-2.269-1.087-.416.836-2.18ZM14 9.333A4.665 4.665 0 0 0 9.333 14 4.665 4.665 0 0 0 14 18.667 4.665 4.665 0 0 0 18.667 14 4.665 4.665 0 0 0 14 9.333Zm-.583 5.677a1.167 1.167 0 1 1-1.168 2.021 1.167 1.167 0 0 1 1.168-2.02Zm2.916-2.177a1.167 1.167 0 1 1 0 2.335 1.167 1.167 0 0 1 0-2.335Zm-2.49-1.437a1.167 1.167 0 1 1-2.021 1.166 1.167 1.167 0 0 1 2.022-1.166Z"
        fill={fill || 'var(--colors-cadet)'}
      />
    </svg>
  );
}

AllergyIcon.propTypes = {
  fill: PropTypes.string,
};

AllergyIcon.defaultProps = {
  fill: 'var(--colors-cadet)',
};

export default AllergyIcon;
