import * as React from 'react';
import PropTypes from 'prop-types';

function BlockIcon({ fill, ...props }) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
      <path
        d="M9 17.333A8.333 8.333 0 119 .666a8.333 8.333 0 010 16.667zm0-1.667A6.667 6.667 0 109 2.333a6.667 6.667 0 000 13.333zm4.075-9.564l-6.973 6.973a5.032 5.032 0 01-1.178-1.178l6.973-6.973c.455.324.854.722 1.178 1.178z"
        fill={fill || '#DA6B6F'}
      />
    </svg>
  );
}

BlockIcon.propTypes = {
  fill: PropTypes.string,
};

BlockIcon.defaultProps = {
  fill: '#DA6B6F',
};

export default BlockIcon;
