import { forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './Checkbox.module.scss';

const Checkbox = forwardRef(({ indeterminate, ...props }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label>
      <input className={clsx(styles.hiddenCheckbox)} type="checkbox" ref={resolvedRef} {...props} />
      <span className={clsx(styles.checkbox)} />
    </label>
  );
});

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool.isRequired,
};

export default Checkbox;
