import loadable from '@loadable/component';
import { loadableOptions } from '@utils/helpers';

const Pct = {
  Patients: loadable(() => import('./Patients'), loadableOptions),
  Patient: loadable(() => import('./Patient'), loadableOptions),
  Orders: loadable(() => import('./Orders'), loadableOptions),
  Prescriptions: loadable(() => import('./Prescriptions'), loadableOptions),
  CreatePrescription: loadable(() => import('./CreatePrescription'), loadableOptions),
};

export default Pct;
