import loadable from '@loadable/component';
import { loadableOptions } from '@utils/helpers';

const Root = {
  MobileAdaptation: loadable(() => import('./MobileAdaptation'), loadableOptions),
  PrivacyPolicy: loadable(() => import('./PrivacyPolicy'), loadableOptions),
  ResetMemorableQuestion: loadable(() => import('./ResetMemorableQuestion'), loadableOptions),
  TermsAndConditions: loadable(() => import('./TermsAndConditions'), loadableOptions),
};

export default Root;
