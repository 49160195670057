import { useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { countries } from 'countries-list';

import config from '@config/config';
import { CustomModal, Table, TextHintWrapper } from '@components/index';
import { ORDER_STATUSES } from '@utils/consts';
import { getMedicationName } from '@utils/medication';
import useDevice from '@hooks/useDevice';

import { Label } from '@patient/components';
import MobileMedications from './MobileMedications';

const InfoBlock = ({ className, title, description, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={className}>
      <p className="text-xs laptop:text-sm font-normal text-santas-gray">{title}</p>
      {Array.isArray(description) ? (
        description.map(item => (
          <p key={item.id} className="text-xsLh22 laptop:text-base font-medium text-davy-grey mt-2 break-words">
            {item.value}
          </p>
        ))
      ) : (
        <div onClick={handleClick}>
          <p
            className={clsx(
              'text-xsLh22 laptop:text-base font-medium text-davy-grey mt-2 break-words',
              !!onClick && 'text-iguana-green underline cursor-pointer',
            )}
          >
            {description}
          </p>
        </div>
      )}
    </div>
  );
};

InfoBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
};

InfoBlock.defaultProps = {
  className: '',
  description: '',
  onClick: null,
};

const OrderDetailsModal = ({ selectedOrder, setSelectedOrderId }) => {
  const { isMobile } = useDevice();

  /* eslint-disable react/prop-types */
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Product / composition',
        accessor: 'productComposition',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
    ],
    [],
  );
  /* eslint-enable react/prop-types */

  const tableData = useMemo(() => {
    if (!selectedOrder) {
      return [];
    }

    return selectedOrder?.orderItems.map(orderItem => ({
      productComposition: getMedicationName(orderItem),
      id: orderItem.id,
      quantity: orderItem?.quantity,
      price: orderItem?.price,
    }));
  }, [selectedOrder]);

  const handleOpenModal = isVisible => {
    if (!isVisible) {
      setSelectedOrderId(null);
    }
  };

  const handleTrackingNumberClick = () => {
    window.open(`${config.royalMailTrackingUrl}${selectedOrder?.trackingNumber}`);
  };

  return (
    <CustomModal className="flex h-96 laptop:h-auto" openModal={!!selectedOrder} setOpenModal={handleOpenModal}>
      <div className="p-10 bg-white rounded-lg flex flex-col h-full w-full overflow-y-auto overflow-x-hidden">
        <p className="text-md laptop:text-2xl font-medium text-dark-charcoal">Order details</p>
        <div className="flex flex-row justify-between items-start mt-2 laptop:mt-4 mb-4 laptop:mb-6">
          <Label className="border-none font-normal" disabled={selectedOrder?.status !== ORDER_STATUSES.IN_PRODUCTION}>
            {selectedOrder?.status}
          </Label>
        </div>
        {isMobile ? (
          <div className="flex max-h-10 overflow-y-hidden">
            <MobileMedications medications={tableData} />
          </div>
        ) : (
          <Table
            verticalSeparator={false}
            headerBottomBorder={false}
            columns={tableColumns}
            data={tableData}
            templateColumns="3fr 1fr 1fr"
          />
        )}
        <p className="text-default laptop:text-md font-medium text-dark-charcoal mt-10">Delivery information</p>
        <div className="grid grid-cols-2 gap-6 mt-6">
          <InfoBlock title="Country" description={countries[selectedOrder?.deliveryData?.address.country]?.name} />
          <InfoBlock title="Postcode" description={selectedOrder?.deliveryData?.address.postcode} />
          <InfoBlock title="Address" description={selectedOrder?.deliveryData?.address.address} />
          <InfoBlock title="Delivery date" description="March 24" />
        </div>
        <div className="grid grid-cols-2 gap-6 mt-6">
          <InfoBlock className="mt-6" title="Delivery option" description={selectedOrder?.deliveryData?.option} />
          <InfoBlock
            className="w-1/2 mt-6"
            title="Tracking number"
            description={selectedOrder?.trackingNumber}
            onClick={handleTrackingNumberClick}
          />
          <InfoBlock
            className="mt-6"
            title="Order notes"
            description={selectedOrder?.orderNotes?.map(orderNote => ({ id: orderNote.id, value: orderNote.note }))}
          />
        </div>
      </div>
    </CustomModal>
  );
};

OrderDetailsModal.propTypes = {
  selectedOrder: PropTypes.shape({
    status: PropTypes.oneOf(Object.values(ORDER_STATUSES)),
    note: PropTypes.string,
    trackingNumber: PropTypes.string,
    createdAt: PropTypes.string,
    deliveryData: PropTypes.shape({
      option: PropTypes.string,
      address: PropTypes.shape({
        country: PropTypes.string,
        postcode: PropTypes.string,
        address: PropTypes.string,
      }),
    }),
    orderItems: PropTypes.arrayOf(
      PropTypes.shape({
        medicationObject: PropTypes.shape({
          name: PropTypes.string,
          quantity: PropTypes.number,
          price: PropTypes.number,
        }),
      }),
    ),
    orderNotes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        note: PropTypes.string,
      }),
    ),
  }),
  setSelectedOrderId: PropTypes.func.isRequired,
};

OrderDetailsModal.defaultProps = {
  selectedOrder: null,
};

export default OrderDetailsModal;
