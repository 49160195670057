import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';

import { CheckSecondary } from '@assets/icons';
import useDevice from '@hooks/useDevice';

const color = {
  danger: 'light-carmine-pink',
  success: 'iguana-green',
};

const Toast = ({ message, type, isOpen, onClick }) => {
  const { isMobile } = useDevice();
  // TODO fix bug: on first render isMobile = false and motion.div initial prop gets initialStyle with left = 50%
  // and translateX = -50% but after changing isMobile on second render to true initial prop keeps values
  // from render and ignore that initialStyle changed
  const initialStyle = useMemo(
    () => ({
      left: !isMobile ? '50%' : '',
      translateX: !isMobile ? '-50%' : '',
      opacity: 0.5,
      y: 126,
      scale: 0,
    }),
    [isMobile],
  );

  return (
    <AnimatePresence>
      {!!isOpen && (
        <motion.div
          onClick={onClick}
          className={clsx(
            !isMobile && 'left-1/2 transform -translate-x-1/2',
            `mx-4 pointer-events-auto flex flex-row items-center justify-between p-4 bg-${color[type]} absolute bottom-14 cursor-pointer rounded-lg`,
          )}
          initial={initialStyle}
          animate={{
            opacity: 1,
            y: 0,
            scale: 1,
            transition: { type: 'spring', duration: 0.3, stiffness: 700, damping: 30 },
          }}
          exit={{
            opacity: 0,
            y: 40,
            transition: { duration: 0.3 },
          }}
        >
          <CheckSecondary className="w-6 h-6" />
          <span className="text-white text-xsLh22 laptop:text-md ml-3 laptop:ml-5 mr-8 laptop:mr-24">{message}</span>
          <span className="text-white text-xsLh22 laptop:text-md opacity-50">Close</span>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  isOpen: PropTypes.bool,
};

Toast.defaultProps = {
  type: 'success',
  isOpen: false,
  onClick: () => {},
};

export default Toast;
