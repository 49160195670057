import PropTypes from 'prop-types';

const CounterMinus = ({ disabled, ...props }) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.58325 11H17.4166"
      stroke={disabled ? 'var(--colors-bright-gray)' : '#333333'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CounterMinus.propTypes = {
  disabled: PropTypes.bool,
};

CounterMinus.defaultProps = {
  disabled: false,
};

export default CounterMinus;
