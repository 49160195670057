import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import clsx from 'clsx';

import { SidePanel, Button, Input, Select, Divider } from '@components/index';
import { setFieldForm } from '@utils/helpers';
import { MEDICATION_TYPES } from '@utils/consts';
import { validationCreatePrescriptionItem } from '@utils/validation';
import { Cross } from '@assets/icons';

import MedicationConstructor from '../MedicationConstructor';
import styles from './CreateMedicationPanel.module.scss';

const CreateMedicationPanel = ({ isOpen, setIsOpen, onFormSubmit, selectedMedication, isLoading }) => {
  const onSubmit = form => {
    onFormSubmit({
      medicationType: form.medicationType,
      medicationObject: form.medicationObject,
      repeatsNum: form.repeatsNum,
      quantity: form.quantity,
      packageSize: form.packageSize,
      additionalNote: form.additionalNote,
      directions: form.directions,
    });
  };

  const { handleSubmit, setFieldValue, values, errors, setFieldError, setErrors } = useFormik({
    initialValues: {
      medicationType: '',
      medicationObject: null,
      directions: '',
      repeatsNum: 0,
      quantity: 1,
      packageSize: null,
      additionalNote: '',
    },
    validationSchema: validationCreatePrescriptionItem,
    onSubmit,
  });

  const toggleOpen = state => {
    if (state) {
      setIsOpen(true);
      return;
    }

    setFieldForm(setFieldValue, setFieldError, 'medicationType', '');
    setFieldForm(setFieldValue, setFieldError, 'medicationObject', null);
    setFieldForm(setFieldValue, setFieldError, 'directions', '');
    setFieldForm(setFieldValue, setFieldError, 'repeatsNum', 0);
    setFieldForm(setFieldValue, setFieldError, 'quantity', 1);
    setFieldForm(setFieldValue, setFieldError, 'packageSize', null);
    setFieldForm(setFieldValue, setFieldError, 'additionalNote', '');

    setIsOpen(false);
  };

  useEffect(() => {
    setFieldForm(
      setFieldValue,
      setFieldError,
      'medicationType',
      selectedMedication ? selectedMedication.medicationType : '',
    );

    setFieldForm(
      setFieldValue,
      setFieldError,
      'medicationObject',
      selectedMedication ? selectedMedication.medicationObject : null,
    );

    setFieldForm(setFieldValue, setFieldError, 'directions', selectedMedication ? selectedMedication.directions : '');
    setFieldForm(setFieldValue, setFieldError, 'repeatsNum', selectedMedication ? selectedMedication.repeatsNum : 0);
    setFieldForm(setFieldValue, setFieldError, 'quantity', selectedMedication ? selectedMedication.quantity : 1);

    setFieldForm(
      setFieldValue,
      setFieldError,
      'packageSize',
      selectedMedication ? selectedMedication.packageSize : null,
    );

    setFieldForm(
      setFieldValue,
      setFieldError,
      'additionalNote',
      selectedMedication ? selectedMedication.additionalNote : '',
    );

    if (isOpen) {
      setErrors({});
    }
  }, [selectedMedication, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setErrors({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (values.medicationType === MEDICATION_TYPES.COMPOUNDED) {
      setFieldForm(setFieldValue, setFieldError, 'quantity', selectedMedication ? selectedMedication.quantity : 1);
    }
  }, [values.medicationType]);

  const onMedicationChange = value => {
    if (value.medicationType !== values.medicationType) {
      setFieldForm(setFieldValue, setFieldError, 'packageSize', '');
    }

    setFieldForm(setFieldValue, setFieldError, 'medicationType', value.medicationType);
    setFieldForm(setFieldValue, setFieldError, 'medicationObject', value.medicationObject);
  };

  const packageSizeOptions = useMemo(() => {
    switch (values.medicationType) {
      case MEDICATION_TYPES.COMPOUNDED:
        return values.medicationObject?.type?.packageSizes.map(size => ({ label: size, value: size })) || [];
      case MEDICATION_TYPES.LICENSED:
      case MEDICATION_TYPES.UNLICENSED:
      case MEDICATION_TYPES.VITAMIN:
        return values.medicationObject?.packageSize
          ? [{ label: values.medicationObject?.packageSize, value: values.medicationObject?.packageSize }]
          : [];
      default:
        return [];
    }
  }, [values.medicationType, values.medicationObject]);

  const onRepeatsNumChange = e => {
    const { value } = e.target;

    const newValue = Number(value);
    if (newValue >= 0 && Number.isInteger(newValue)) {
      setFieldForm(setFieldValue, setFieldError, 'repeatsNum', newValue);
    }
  };

  const onQuantityChange = e => {
    const { value } = e.target;

    const newValue = Number(value);
    if (newValue > 0 && Number.isInteger(newValue)) {
      setFieldForm(setFieldValue, setFieldError, 'quantity', newValue);
    }
  };

  const renderForm = containerClassName => (
    <form onSubmit={handleSubmit} className={containerClassName}>
      <div className="flex flex-col">
        <div className="mb-6 flex flex-row justify-between items-center">
          <h3 className={clsx('text-dark-charcoal font-roboto-medium text-h3')}>
            {selectedMedication ? 'Edit Medication' : 'Add Medications'}
          </h3>
          <div className="cursor-pointer" onClick={() => toggleOpen(false)}>
            <Cross className="stroke-current stroke-davy-grey w-4 h-4" />
          </div>
        </div>
        <div className={clsx('grid grid-cols-3 gap-8')}>
          <div className="col-span-3">
            <MedicationConstructor
              medicationType={values.medicationType}
              medicationObject={values.medicationObject}
              onChange={onMedicationChange}
              error={errors.medicationObject}
            />
          </div>
          <Input
            className="col-span-3"
            value={values.directions}
            onChange={e => setFieldForm(setFieldValue, setFieldError, 'directions', e.target.value)}
            variant="small"
            label="Directions"
            placeholder="Enter directions"
            error={errors.directions}
          />
          <Divider className="col-span-3" />
          <Select
            className="col-span-1"
            label="Package Size"
            placeholder="Select Package Size"
            options={packageSizeOptions}
            value={
              packageSizeOptions.find(packageSize => String(values.packageSize) === String(packageSize?.value)) || null
            }
            onChange={({ value }) => setFieldForm(setFieldValue, setFieldError, 'packageSize', value)}
            error={errors.packageSize}
          />
          <Input
            className="col-span-1"
            value={values.quantity}
            onChange={onQuantityChange}
            variant="small"
            label="Quantity"
            placeholder="Enter Quantity"
            type="number"
            disabled={values.medicationType === MEDICATION_TYPES.COMPOUNDED}
            error={errors.quantity}
          />
          <Input
            className="col-span-1"
            value={values.repeatsNum}
            onChange={onRepeatsNumChange}
            variant="small"
            label="No of Repeats"
            placeholder="Enter No of Repeats"
            type="number"
            error={errors.repeatsNum}
          />
          <Input
            className="col-span-3"
            value={values.additionalNote}
            onChange={e => setFieldForm(setFieldValue, setFieldError, 'additionalNote', e.target.value)}
            variant="small"
            label="Additional Note (optional)"
            placeholder="e.g. Vanilla flavour"
            error={errors.additionalNote}
          />
        </div>
      </div>
      <div className="flex flex-row justify-end pt-10">
        <Button onClick={() => toggleOpen(false)} className="px-6 py-3 mr-6" variant="default">
          <span>Cancel</span>
        </Button>
        <Button
          variant="primary"
          className="px-6 py-3"
          isLoading={isLoading}
          isDisabled={!!Object.values(errors)?.length}
          type="submit"
        >
          <span>Save</span>
        </Button>
      </div>
    </form>
  );

  return (
    <SidePanel openModal={isOpen} setOpenModal={toggleOpen} className={styles.createPanel}>
      {renderForm('p-16 bg-white h-full flex flex-col justify-between overflow-y-scroll')}
    </SidePanel>
  );
};

CreateMedicationPanel.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onFormSubmit: PropTypes.func,
  selectedMedication: PropTypes.shape({
    medicationType: PropTypes.string,
    directions: PropTypes.string,
    repeatsNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    packageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    medicationObject: PropTypes.shape(), // TODO: fill with fields
    additionalNote: PropTypes.string,
  }),
};

CreateMedicationPanel.defaultProps = {
  isOpen: false,
  isLoading: false,
  setIsOpen: () => {},
  onFormSubmit: () => {},
  selectedMedication: null,
};

export default CreateMedicationPanel;
