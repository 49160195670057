import PropTypes from 'prop-types';

import MobileMedicationItem from './MobileMedicationItem';

const MobileMedications = ({ medications }) => (
  <div className="flex w-full flex-col px-4 py-5 box-border rounded-lg border border-solid border-bright-gray h-full overflow-y-auto">
    {medications.map((medication, index) => (
      <MobileMedicationItem key={medication.id} item={medication} last={index === medications.length - 1} />
    ))}
  </div>
);

MobileMedications.propTypes = {
  medications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default MobileMedications;
