import { useState, useContext, useCallback, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { Logo } from '@assets/icons';
import { routerType } from '@router/types';
import useDevice from '@hooks/useDevice';
import { ResponsiveFontSizeContext } from '@contexts/ResponsiveFontSize';

import { Menu, Close } from '@patient/assets/icons';
import { Sidebar } from '@patient/components/index';
import { COMMON, PCT, PATIENT } from '@patient/router/paths';

const RootLayout = ({ route }) => {
  const { isMobileModalPage, setIsMobileModalPage } = useContext(ResponsiveFontSizeContext);
  const [isSideBarOpenedOnMobile, setIsSideBarOpenedOnMobile] = useState(isMobileModalPage);
  const { pathname } = useLocation();
  const { isMobile } = useDevice();
  const isPrescription = useRouteMatch(COMMON.PRESCRIPTION);
  const isPrescriptionCreate = useRouteMatch(PCT.CREATE_PRESCRIPTION);

  const regexpPatientCheckout = new RegExp(`${PATIENT.CHECKOUT}`);

  const regexpPatients = new RegExp(`${PCT.PATIENTS}/`);
  const regexpCreatePrescription = new RegExp(`${PCT.CREATE_PRESCRIPTION}`);
  const isWide =
    (/medications/.test(pathname) || !regexpPatients.test(pathname)) && !regexpCreatePrescription.test(pathname);

  const isCheckout = regexpPatientCheckout.test(pathname);

  useEffect(() => {
    if (isCheckout) {
      setIsMobileModalPage(false);
      setIsSideBarOpenedOnMobile(false);
    }
  }, [isCheckout]);

  const handleClickIcon = useCallback(() => {
    if (isMobileModalPage) {
      setIsMobileModalPage(prev => !prev);
      return;
    }
    setIsSideBarOpenedOnMobile(prev => !prev);
  }, [isMobileModalPage]);

  return (
    <div className="flex h-full w-full pt-32 laptop:pt-0 overflow-hidden">
      {isMobile && (
        <div className="absolute top-14 flex w-full flex-row justify-between items-center px-5 z-10">
          <Logo width={9} height={2.5} />
          <div className="cursor-pointer" onClick={handleClickIcon}>
            {!isSideBarOpenedOnMobile && !isMobileModalPage ? <Menu /> : <Close />}
          </div>
        </div>
      )}
      {!(isPrescription && !isPrescriptionCreate) &&
        (!isMobile || (isMobile && isSideBarOpenedOnMobile)) &&
        !isMobileModalPage && (
          <Sidebar wide={isWide || isMobile} handleClose={() => setIsSideBarOpenedOnMobile(false)} />
        )}
      {renderRoutes(!isSideBarOpenedOnMobile || isMobileModalPage ? route.routes : null)}
    </div>
  );
};

RootLayout.propTypes = routerType;

export default RootLayout;
