import PropTypes from 'prop-types';
import { format } from 'date-fns';
import clsx from 'clsx';

import { Counter } from '@components/index';
import { getMedicationDosageInfo, getMedicationFormulation, getMedicationName } from '@utils/medication';
import useDevice from '@hooks/useDevice';

import Property from '@patient/components/Elements/MedicationCard/Property';
import Label from '@patient/components/Elements/Label/Label';
import CardButton from './CardButton';
import styles from './HorizontalLayout.module.scss';

const HorizontalLayout = ({
  added,
  recent,
  disabled,
  onAdd,
  onOpenCart,
  medication,
  createdAt,
  orderWithin,
  quantity,
  onChangeQuantity,
}) => {
  const { packageSize, price, maxPackageCount } = medication;
  const { isMobile } = useDevice();

  // TODO
  // const ResponsiveShoppingBag = <ShoppingBag width={isMobile ? 1.375 : 1.75} height={isMobile ? 1.375 : 1.75} />;

  return (
    <div className="flex flex-col laptop:flex-row justify-between border h-max w-auto rounded-lg p-5 laptop:p-6">
      <div className="flex flex-col flex-grow">
        <div className="self-start">
          {recent && <Label className="mr-2">New</Label>}
          <Label disabled>{format(Number(createdAt), 'PP')}</Label>
        </div>
        <p className="mt-6 text-lg leading-6 laptop:text-2xl laptop:leading-8 font-medium laptop:font-semibold">
          {getMedicationName(medication)}
        </p>
        {isMobile && (
          <p className="mt-4 text-md laptop:text-xl font-medium laptop:font-semibold">{`£${
            Math.round(price * quantity * 100) / 100
          }`}</p>
        )}
        <div className={clsx('grid mt-6 gap-y-3', styles.card)}>
          <Property name="Formulation:" value={getMedicationFormulation(medication)} horizontal />
          <Property name="Available for order:" value={`${maxPackageCount} items`} horizontal />
          <Property name="Package size:" value={`${packageSize} ${getMedicationFormulation(medication)}`} horizontal />
          <Property name="Order within:" value={orderWithin} horizontal />
          <Property name="Dosage info:" value={getMedicationDosageInfo(medication)} horizontal />
        </div>
      </div>
      {!disabled && (
        <div className="flex flex-col justify-between mt-8 laptop:mt-2">
          <div className="flex mb-4 laptop:mb-0">
            <div className="h-max w-full laptop:w-64">
              <p className="text-sm text-silver-sand mb-2">Quantity</p>
              <Counter
                onChange={onChangeQuantity}
                value={quantity}
                maxValue={maxPackageCount}
                disabled={added || disabled}
              />
            </div>
            {!isMobile && (
              <div className="flex items-end flex-col w-20 justify-between h-max">
                <p className="text-sm text-silver-sand">Price</p>
                <p className="mt-4 text-xl font-semibold">{`£${Math.round(price * quantity * 100) / 100}`}</p>
              </div>
            )}
          </div>
          <CardButton added={added} disabled={disabled} onClick={added ? onOpenCart : onAdd} />
        </div>
      )}
    </div>
  );
};

HorizontalLayout.propTypes = {
  added: PropTypes.bool.isRequired,
  recent: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onOpenCart: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  medication: PropTypes.shape({
    price: PropTypes.number.isRequired,
    maxPackageCount: PropTypes.number.isRequired,
    packageSize: PropTypes.string.isRequired,
  }).isRequired,
  createdAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  orderWithin: PropTypes.string.isRequired,
};

export default HorizontalLayout;
