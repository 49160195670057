import { useEffect, useMemo, useCallback, useContext, useRef } from 'react';

import { UserContext } from '@contexts/User';
import MedicationService from '@services/api/medication';
import useSafeState from '@hooks/useSafeState';

export const useIngredientCreate = () => {
  const isUnmounted = useRef();
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const createIngredient = useCallback(
    async data => {
      setIsLoading(true);

      try {
        const newIngredient = await MedicationService.createIngredient(data);
        if (!isUnmounted.current) {
          setIsLoading(false);
        }

        return newIngredient;
      } catch (err) {
        setError(err);
        setIsLoading(false);

        return null;
      }
    },
    [user],
  );

  return [createIngredient, { isLoading, error, setError }];
};

export const useIngredientUpdate = () => {
  const isUnmounted = useRef();
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const updateIngredient = useCallback(
    async (ingredientId, data) => {
      setIsLoading(true);

      try {
        const updated = await MedicationService.updateIngredientById(ingredientId, data);
        setIsLoading(false);

        return updated;
      } catch (err) {
        setError(err);
        setIsLoading(false);

        return null;
      }
    },
    [user],
  );

  return [updateIngredient, { isLoading, error, setError }];
};

export const useIngredientDelete = () => {
  const isUnmounted = useRef();
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const deleteIngredient = useCallback(
    async ingredientId => {
      setIsLoading(true);

      try {
        await MedicationService.deleteIngredientById(ingredientId);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    },
    [user],
  );

  return [deleteIngredient, { isLoading, error, setError }];
};

export const useIngredientList = () => {
  const isUnmounted = useRef();
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const [ingredients, setIngredients] = useSafeState([], isUnmounted);
  const [isLoading, setIsLoading] = useSafeState(false, isUnmounted);
  const [error, setError] = useSafeState(null, isUnmounted);

  const [create, { isLoading: isCreating }] = useIngredientCreate();
  const [update, { isLoading: isUpdating }] = useIngredientUpdate();
  const [remove, { isLoading: isDeleting }] = useIngredientDelete();

  useEffect(async () => {
    setIsLoading(true);

    try {
      const ingredientsResponse = await MedicationService.getAllIngredients();
      setIngredients(ingredientsResponse);
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }, []);

  const fetchIngredients = useCallback(async () => {
    const ingredientsResponse = await MedicationService.getAllIngredients();
    setIngredients(ingredientsResponse);
  }, []);

  const createIngredient = useCallback(async data => {
    const newIngredient = await create(data);
    if (newIngredient) {
      setIngredients(prevIngredients => [...prevIngredients, newIngredient]);
    }

    return newIngredient;
  }, []);

  const updateIngredient = useCallback(async (ingredientId, data) => {
    const newIngredient = await update(ingredientId, data);
    if (!newIngredient) {
      return newIngredient;
    }

    setIngredients(prevIngredients =>
      prevIngredients.map(ingredient => {
        if (ingredient.id === newIngredient.id) {
          return { ...ingredient, ...newIngredient };
        }

        return ingredient;
      }),
    );

    return newIngredient;
  }, []);

  const deleteIngredient = useCallback(async ingredientId => {
    await remove(ingredientId);

    setIngredients(prevIngredients =>
      prevIngredients
        .map(ingredient => {
          if (ingredient.id === ingredientId) {
            return null;
          }

          return ingredient;
        })
        .filter(ingredient => !!ingredient),
    );
  }, []);

  return useMemo(
    () => [
      ingredients,
      {
        isLoading,
        isCreating,
        isUpdating,
        isDeleting,
        error,
        setError,
        setIngredients,
        createIngredient,
        updateIngredient,
        deleteIngredient,
        fetchIngredients,
      },
    ],
    [ingredients, isLoading, isCreating, isUpdating, isDeleting, fetchIngredients],
  );
};
