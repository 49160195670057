import { memo } from 'react';
import PropTypes from 'prop-types';

import { CountryPhones, CustomModal, Button } from '@components/index';

import ResendCode from '../ResendCode';

const PhoneModal = ({ open, setOpen, phoneStep, onSendCode, onChangeValue, phoneError, onPhoneCodeSubmit }) => (
  <CustomModal openModal={open} setOpenModal={setOpen}>
    {phoneStep ? (
      <div className="w-full p-8 bg-white rounded-md">
        <h4 className="text-default laptop:text-xl text-dark-charcoal font-roboto-medium mb-2">
          Enter your phone number
        </h4>
        <p className="text-xsLh22 laptop:text-base text-davy-grey mb-4 laptop:mb-8">
          We will send you SMS code for confirmation
        </p>
        <CountryPhones onChange={onChangeValue} />
        {!!phoneError && (
          <p className="mt-2 text-light-carmine-pink font-roboto-regular text-xs laptop:text-sm">{phoneError}</p>
        )}
        <div className="flex flex-row justify-center laptop:justify-end mt-4 laptop:mt-8">
          <Button onClick={() => setOpen(false)} className="px-4 py-3 mr-6" variant="empty">
            <span className="text-xsLh22 laptop:text-base">Cancel</span>
          </Button>
          <Button onClick={() => onSendCode()} variant="primary">
            <span className="text-xsLh22 laptop:text-base">Send code</span>
          </Button>
        </div>
      </div>
    ) : (
      <div className="p-8 bg-white rounded-md">
        <ResendCode setEnterCodeModal={setOpen} onSubmit={onPhoneCodeSubmit} onResend={onSendCode} />
      </div>
    )}
  </CustomModal>
);

PhoneModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  phoneStep: PropTypes.bool.isRequired,
  onSendCode: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onPhoneCodeSubmit: PropTypes.func.isRequired,
  phoneError: PropTypes.string,
};

PhoneModal.defaultProps = {
  phoneError: null,
};

export default memo(PhoneModal);
