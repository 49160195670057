import config from '@config/config';
import ApiService from './api';

class GeolocationApi extends ApiService {
  constructor() {
    if (GeolocationApi.instance) {
      return GeolocationApi.instance;
    }

    super(config.geolocationApiUrl);
    GeolocationApi.instance = this;
  }

  async getAutocomplete(countryISO, text, containerId) {
    const containerIdQuery = containerId ? `&containerId=${containerId}` : '';
    const response = await this.http.get(`/address/search?q=${text}&country=${countryISO}${containerIdQuery}`);

    return response.data;
  }

  async getAddressInfo(addressId, countryISO) {
    const response = await this.http.get(`/address/info?addressId=${addressId}&country=${countryISO}`);
    return response.data;
  }
}

const geolocationApiInstance = new GeolocationApi();
export default geolocationApiInstance;
