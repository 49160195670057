import PropTypes from 'prop-types';
import clsx from 'clsx';

const SvgComponent = ({ className, fill, ...props }) => (
  <svg
    className={clsx('w-8 h-10', className)}
    viewBox="0 0 32 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.74 9.15 22.85.26a.888.888 0 0 0-.628-.26H3.555A3.559 3.559 0 0 0 0 3.556V39.11a3.559 3.559 0 0 0 3.556 3.556h24.889A3.559 3.559 0 0 0 32 39.11V9.778a.888.888 0 0 0-.26-.629ZM23.11 3.034l5.854 5.854H24.89A1.78 1.78 0 0 1 23.11 7.11V3.035Zm7.111 36.076a1.78 1.78 0 0 1-1.777 1.778H3.555a1.78 1.78 0 0 1-1.777-1.778V3.556c0-.98.798-1.778 1.778-1.778h17.777V7.11a3.56 3.56 0 0 0 3.556 3.556h5.333V39.11Z"
      fill={fill || '#BABAC6'}
    />
    <path
      d="M22.778 25.082a.892.892 0 0 0-1.25.14l-2.62 3.274-4.052-5.672c1.7-.625 2.922-2.245 2.922-4.158a4.45 4.45 0 0 0-4.445-4.445H9.778a.888.888 0 0 0-.89.89v12.444a.888.888 0 1 0 1.778 0V23.11h2.21l4.878 6.829-3.338 4.171a.89.89 0 0 0 1.39 1.111l3.022-3.778 2.671 3.74a.887.887 0 0 0 1.24.206c.399-.285.492-.84.206-1.24l-2.963-4.148 2.934-3.669a.89.89 0 0 0-.138-1.25Zm-12.111-3.75V16h2.666A2.67 2.67 0 0 1 16 18.666a2.67 2.67 0 0 1-2.667 2.666h-2.667Z"
      fill={fill || '#BABAC6'}
    />
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: '#BABAC6',
  className: '',
};

export default SvgComponent;
