import loadable from '@loadable/component';
import { loadableOptions } from '@utils/helpers';

const Prescriber = {
  ConfirmationPending: loadable(() => import('./ConfirmationPending'), loadableOptions),
  Home: loadable(() => import('./Home'), loadableOptions),
  Settings: loadable(() => import('./Settings'), loadableOptions),
  PrescriptionHistory: loadable(() => import('./PrescriptionHistory'), loadableOptions),
};

export default Prescriber;
