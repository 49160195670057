import { memo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import useDevice from '@hooks/useDevice';

const SidebarItem = memo(({ wide, active, item, separatorBelow, onClick }) => {
  const { isMobile } = useDevice();

  return (
    <>
      <Link
        onClick={onClick}
        key={item.name}
        className={clsx(
          'flex flex-row items-center py-4 relative h-14',
          wide ? 'pl-5 laptop:pl-6' : 'w-full flex justify-center pr-1 mb-2',
        )}
        to={item.path}
      >
        <AnimatePresence>
          {active && (
            <motion.div
              className={clsx('absolute left-0 h-full w-1 rounded-r-sm')}
              style={{ background: '#5BC67F' }}
              initial={{
                opacity: 0,
                scaleY: 0,
              }}
              animate={{
                opacity: 1,
                scaleY: 1,
                transition: { duration: 0.3 },
              }}
              exit={{
                opacity: 0,
                scaleY: 0,
                transition: { duration: 0.3 },
              }}
            />
          )}
        </AnimatePresence>
        {item.menuIcon && (
          <div className=" laptop:w-9 flex items-center justify-center">
            <item.menuIcon
              className={clsx(isMobile ? 'w-5 h-5' : 'w-7 h-7')}
              fill={active ? 'var(--colors-cadet)' : 'var(--colors-santas-gray)'}
            />
          </div>
        )}
        {wide && (
          <span className={clsx('ml-3 text-base', active ? 'text-cadet' : 'text-santas-gray')}>{item.name}</span>
        )}
      </Link>
      {separatorBelow && <div className="border-b border-bright-gray mt-6 laptop:mt-10 mb-10" />}
    </>
  );
});

SidebarItem.propTypes = {
  wide: PropTypes.bool,
  active: PropTypes.bool,
  separatorBelow: PropTypes.bool,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string,
    menuIcon: PropTypes.func,
  }).isRequired,
  onClick: PropTypes.func,
};

SidebarItem.defaultProps = {
  wide: false,
  active: false,
  separatorBelow: false,
  onClick: () => {},
};

export default memo(SidebarItem);
