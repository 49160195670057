import { faker } from '@faker-js/faker';
import {
  ADDRESS_TYPE,
  DELIVERY_TYPES,
  MEDICATION_STATUSES,
  PRESCRIPTION_STATUSES,
  USER_ROLES,
  ORDER_STATUSES,
} from '@utils/consts';
import { add } from 'date-fns';

faker.locale = 'de';

export const generateOrders = ({ count }) => {
  const orders = [];
  const user = faker.name.fullName();

  const generateOrder = () => ({
    id: faker.datatype.uuid(),
    totalPrice: faker.datatype.number({ min: 10, max: 100 }),
    status: faker.helpers.arrayElement(Object.values(ORDER_STATUSES)),
    customerMessage: faker.lorem.sentence(3),
    note: faker.lorem.sentence(),
    deliveryData: {
      price: faker.datatype.number({ min: 10, max: 100 }),
      option: faker.lorem.sentence(),
      address: {
        country: faker.address.country(),
        address: faker.address.city() + faker.address.streetAddress(),
        postcode: faker.address.zipCode(),
      },
    },
    patient: generatePersons({ count: 1, prescriptionsNeeded: false })[0],
    orderItems: generateOrderItems({ count: 2 }),
    createdBy: user,
    updatedBy: user,
    createdAt: faker.date.past(),
    updatedAt: faker.date.recent(),
  });

  for (let i = 0; i < count; i += 1) {
    orders.push(generateOrder());
  }

  return orders;
};

export const generateOrderItems = ({ count }) => {
  const orderItems = [];
  const user = faker.name.fullName();

  const generateOrderItem = () => ({
    id: faker.datatype.uuid(),
    medication: {
      name: faker.lorem.word(),
      quantity: faker.datatype.number({ min: 1, max: 10 }),
      price: faker.datatype.number({ min: 10, max: 100 }),
    },
    createdBy: user,
    updatedBy: user,
    createdAt: faker.date.past(),
    updatedAt: faker.date.recent(),
  });

  for (let i = 0; i < count; i += 1) {
    orderItems.push(generateOrderItem());
  }

  return orderItems;
};

export const generateMedications = ({ orderId, user: userProp, count }) => {
  const medications = [];

  const user = userProp || faker.name.fullName();

  const generateMedication = () => {
    const createdAt = faker.date.past();
    const maxPackageCount = faker.datatype.number({ min: 1, max: 9 });

    return {
      orderId,
      id: faker.datatype.uuid(),
      medication: {
        name: faker.lorem.words(3),
        status: faker.helpers.arrayElement(Object.values(MEDICATION_STATUSES)),
        quantity: faker.datatype.number({ min: 1, max: maxPackageCount }),
        price: faker.datatype.number({ min: 10, max: 1000 }),
        dosage: faker.lorem.words(2),
        formulation: faker.lorem.word(),
        packageSize: faker.datatype.number({ min: 10, max: 100 }),
        maxPackageCount,
        availableUntil: add(new Date(createdAt), { days: 180 }),
      },
      createdBy: user,
      updatedBy: user,
      createdAt,
      updatedAt: faker.date.recent(),
    };
  };

  for (let i = 0; i < count; i += 1) {
    medications.push(generateMedication());
  }

  return medications;
};

export const generateDeliveryOptions = ({ count }) => {
  const options = [];

  const generateDeliveryOption = () => {
    const name = faker.lorem.sentence(3);
    const duration = faker.datatype.number({ min: 1, max: 14 });
    const price = faker.datatype.number({ min: 10, max: 100 });

    return {
      value: name,
      duration,
      price,
      label: `${name} (${duration} days): £${price}`,
    };
  };

  for (let i = 0; i < count; i += 1) {
    options.push(generateDeliveryOption());
  }

  const internationalDelivery = {
    value: DELIVERY_TYPES.INTERNATIONAL,
    duration: null,
    price: null,
    label: DELIVERY_TYPES.INTERNATIONAL,
  };

  const courierDelivery = {
    value: DELIVERY_TYPES.COURIER,
    duration: null,
    price: null,
    label: DELIVERY_TYPES.COURIER,
  };

  options.push(internationalDelivery, courierDelivery);

  return options;
};

export const generatePrescriptions = ({ count, personsNeeded = true }) => {
  const options = [];

  const generatePrescription = () => {
    const user = faker.name.fullName();

    return {
      id: faker.datatype.uuid().slice(0, 16),
      status: faker.helpers.arrayElement(Object.values(PRESCRIPTION_STATUSES)),
      patient: personsNeeded ? generatePersons({ count: 1, prescriptionsNeeded: false })[0] : null,
      prescriber: personsNeeded ? generatePersons({ count: 1, prescriptionsNeeded: false })[0] : null,
      createdBy: user,
      updatedBy: user,
      createdAt: faker.date.past(),
      updatedAt: faker.date.recent(),
    };
  };

  for (let i = 0; i < count; i += 1) {
    options.push(generatePrescription());
  }

  return options;
};

export const generatePersons = ({ count, duplicate, byEmail, prescriptionsNeeded = true }) => {
  const options = [];

  const user = faker.name.fullName();
  const name = faker.name.fullName();
  const birthday = faker.date.birthdate();
  const email = faker.internet.email();

  const generatePersonOption = () => {
    return {
      id: faker.datatype.uuid().slice(0, 16),
      accountId: faker.datatype.uuid(),
      userId: faker.datatype.uuid(),
      organizationId: faker.datatype.uuid(),
      organizationName: faker.company.name(),
      // eslint-disable-next-line no-nested-ternary
      name: duplicate ? (byEmail ? faker.name.fullName() : name) : faker.name.fullName(),
      allergies: faker.lorem.words(2),
      type: faker.helpers.arrayElement(Object.values(USER_ROLES)),
      profilePictureName: faker.image.avatar(),
      birthday: duplicate ? birthday : faker.date.birthdate(),
      addressType: faker.helpers.arrayElement(['multiple', 'common']),
      notes: [
        {
          id: faker.datatype.uuid(),
          date: faker.date.recent(),
          text: faker.lorem.lines(),
        },
        {
          id: faker.datatype.uuid(),
          date: faker.date.recent(),
          text: faker.lorem.lines(),
        },
        {
          id: faker.datatype.uuid(),
          date: faker.date.recent(),
          text: faker.lorem.lines(),
        },
        {
          id: faker.datatype.uuid(),
          date: faker.date.recent(),
          text: faker.lorem.lines(),
        },
      ],
      address: [
        {
          default: true,
          type: ADDRESS_TYPE.DELIVERY,
          country: faker.address.country(),
          address: faker.address.city() + faker.address.streetAddress(),
          postcode: faker.address.zipCode(),
        },
        {
          type: ADDRESS_TYPE.DELIVERY,
          country: faker.address.country(),
          address: faker.address.city() + faker.address.streetAddress(),
          postcode: faker.address.zipCode(),
        },
        {
          type: ADDRESS_TYPE.BILLING,
          country: faker.address.country(),
          address: faker.address.city() + faker.address.streetAddress(),
          postcode: faker.address.zipCode(),
        },
      ],
      additionalData: {
        personalEmail: [
          duplicate && byEmail ? email : faker.internet.email(),
          faker.internet.email(),
          faker.internet.email(),
        ],
        phone: [faker.phone.number(), faker.phone.number()],
      },
      prescriptions: prescriptionsNeeded ? generatePrescriptions({ count: 15, personsNeeded: false }) : null,
      createdBy: user,
      updatedBy: user,
      createdAt: faker.date.past(),
      updatedAt: faker.date.recent(),
    };
  };

  for (let i = 0; i < count; i += 1) {
    options.push(generatePersonOption());
  }

  return options;
};
