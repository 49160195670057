import * as React from 'react';

const SvgComponent = props => (
  <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24.5 9.333v15.159a1.164 1.164 0 0 1-1.159 1.175H4.659A1.159 1.159 0 0 1 3.5 24.509V3.491c0-.627.524-1.158 1.169-1.158h12.828l7.003 7ZM22.167 10.5h-5.834V4.667h-10.5v18.666h16.334V10.5ZM9.333 8.167h3.5V10.5h-3.5V8.167Zm0 4.666h9.334v2.334H9.333v-2.334Zm0 4.667h9.334v2.333H9.333V17.5Z"
      fill="#9B9DAE"
    />
  </svg>
);

export default SvgComponent;
