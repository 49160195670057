import { useEffect } from 'react';

export const useOutside = (ref, handler) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref?.current && !ref?.current?.contains(event.target) && handler) {
        handler(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handler, ref]);
};
