import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import clsx from 'clsx';

import { CustomModal, Button, Input, Select } from '@components/index';
import { PRESCRIPTION_DEACTIVATION_OPTIONS } from '@utils/consts';
import { validationDeactivationReason } from '@utils/validation';
import { setFieldForm } from '@utils/helpers';

import { memo } from 'react';
import styles from './DeactivationModal.module.scss';

const optionsArray = Object.values(PRESCRIPTION_DEACTIVATION_OPTIONS);

const DeactivationModal = ({ isOpen, setOpenModal, onConfirm, isLoading }) => {
  const onFormSubmit = form => {
    const reason =
      form.option?.value === PRESCRIPTION_DEACTIVATION_OPTIONS.OTHER.value
        ? form.customMessage
        : form.option?.label || '';

    onConfirm(reason);
  };

  const { handleSubmit, setFieldValue, values, errors, setFieldError } = useFormik({
    initialValues: {
      option: null,
      customMessage: '',
    },
    validationSchema: validationDeactivationReason,
    onSubmit: onFormSubmit,
  });

  const isShowCustomInput = values.option?.value === PRESCRIPTION_DEACTIVATION_OPTIONS.OTHER.value;

  return (
    <CustomModal openModal={isOpen} setOpenModal={setOpenModal} className={styles.deactivationModal}>
      <form className="p-8 bg-white rounded-md" onSubmit={handleSubmit}>
        <h4 className="text-xl text-dark-charcoal font-roboto-medium mb-2">Deactivate prescription</h4>
        <p className="text-davy-grey mb-8 pr-2">
          Are you sure you want to deactivate prescription? If so, write down the reason for the rejection or pick an
          option. This will help us improve the service performance.
        </p>
        <Select
          className={clsx('flex-1', isShowCustomInput ? 'mb-1.5' : 'mb-4')}
          value={values.option}
          error={!!errors.option}
          options={optionsArray}
          onChange={e => setFieldForm(setFieldValue, setFieldError, 'option', e)}
          label="Deactivation reason"
          placeholder="Select a reason"
        />
        {isShowCustomInput && (
          <Input
            className="flex-1 mb-8"
            value={values.customMessage}
            error={errors.customMessage}
            onChange={e => setFieldForm(setFieldValue, setFieldError, 'customMessage', e.target.value)}
            placeholder="Type a reason"
          />
        )}
        <div className="flex flex-row justify-end">
          <Button onClick={() => setOpenModal(false)} className="px-6 py-3 mr-6" variant="empty">
            <span>Cancel</span>
          </Button>
          <Button type="submit" variant="danger" className="px-6 py-3" isLoading={isLoading}>
            <span>Deactivate</span>
          </Button>
        </div>
      </form>
    </CustomModal>
  );
};

DeactivationModal.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  setOpenModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

DeactivationModal.defaultProps = {
  isOpen: false,
  isLoading: false,
};

export default memo(DeactivationModal);
