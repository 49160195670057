import clsx from 'clsx';
import PropTypes from 'prop-types';

const ArticleText = ({ children, className }) => (
  <p className={clsx('text-base text-dark-charcoal my-2', className)}>{children}</p>
);

ArticleText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string,
};

ArticleText.defaultProps = {
  className: '',
};

export default ArticleText;
