import config from '@config/config';
import ApiService from './api';

class MedicationApi extends ApiService {
  constructor() {
    if (MedicationApi.instance) {
      return MedicationApi.instance;
    }

    super(config.medicationApiUrl);
    MedicationApi.instance = this;
  }

  async getAllAllergies() {
    const response = await this.http.get('/allergies');
    return response.data;
  }

  async createAllergy(data) {
    const response = await this.http.post('/allergies', {
      id: data.id || undefined,
      name: data.name,
    });

    return response.data;
  }

  async updateAllergyById(id, data) {
    const response = await this.http.put(`/allergies/${id}`, {
      id: data.id || id,
      name: data.name,
    });

    return response.data;
  }

  async deleteAllergyById(id) {
    const response = await this.http.delete(`/allergies/${id}`);
    return response.data;
  }

  async getAllIngredients() {
    const response = await this.http.get('/ingredients');
    return response.data;
  }

  async createIngredient(data) {
    const response = await this.http.post('/ingredients', {
      id: data.id || undefined,
      name: data.name,
      types: data.types || [],
    });

    return response.data;
  }

  async updateIngredientById(id, data) {
    const response = await this.http.put(`/ingredients/${id}`, {
      id: data.id || id,
      name: data.name,
      types: data.types || [],
    });

    return response.data;
  }

  async deleteIngredientById(id) {
    const response = await this.http.delete(`/ingredients/${id}`);
    return response.data;
  }

  async getAllIngredientTypes() {
    const response = await this.http.get('/ingredients/types');
    return response.data;
  }

  async createIngredientType(data) {
    const response = await this.http.post('/ingredients/types', {
      id: data.id || undefined,
      name: data.name,
      measurement: data.measurement,
      packageSizes: data.packageSizes || [],
      dosage: data.dosage || '',
      isActive: data.isActive || true,
    });

    return response.data;
  }

  async updateIngredientTypeById(id, data) {
    const response = await this.http.put(`/ingredients/types/${id}`, {
      id: data.id || id,
      name: data.name,
      measurement: data.measurement,
      packageSizes: data.packageSizes || [],
      dosage: data.dosage || '',
      isActive: !!data.isActive,
    });

    return response.data;
  }

  async deleteIngredientTypeById(id) {
    const response = await this.http.delete(`/ingredients/types/${id}`);
    return response.data;
  }

  async getAllMedications() {
    const response = await this.http.get('/medications');
    return response.data;
  }

  async createMedication(data) {
    const response = await this.http.post('/medications', {
      id: data.id || undefined,
      name: data.name,
      formulation: data.formulation || '',
      category: data.category || '',
      packageSize: data.packageSize || '',
      strength: data.strength || '',
      isActive: !!data.isActive,
    });

    return response.data;
  }

  async updateMedicationById(id, data) {
    const response = await this.http.put(`/medications/${id}`, {
      id: data.id || id,
      name: data.name,
      formulation: data.formulation || '',
      category: data.category || '',
      packageSize: data.packageSize || '',
      strength: data.strength || '',
      isActive: !!data.isActive,
    });

    return response.data;
  }

  async deleteMedicationById(id) {
    const response = await this.http.delete(`/medications/${id}`);
    return response.data;
  }
}

const medicationApiInstance = new MedicationApi();
export default medicationApiInstance;
