import { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { Checkbox } from '@components/Form';
import { Divider } from '@components/Static';
import { useOutside } from '@hooks/useOutside';
import { SmallArrow } from '@assets/icons';

import styles from './MultiSelect.module.scss';

const Option = ({ label, isChecked, onClick, className }) => (
  <div
    className={clsx(
      'flex flex-row justify-between cursor-pointer px-3 py-2 hover:bg-bright-gray transition-all duration-300 rounded-lg',
      className,
    )}
    onClick={onClick}
  >
    <span>{label}</span>
    <Checkbox checked={isChecked} checkedBgColor="#6AB97A" checkColor="#FFFFFF" className="pointer-events-none" />
  </div>
);

Option.propTypes = {
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Option.defaultProps = {
  isChecked: false,
  onClick: () => {},
  className: '',
};

const MultiSelect = ({
  className,
  error,
  label,
  isRequired,
  placeholder,
  options,
  onOptionClick,
  selectedValues,
  Footer,
  isDisabled,
}) => {
  const selectRef = useRef();
  const inputValue = useMemo(() => {
    return options
      .filter(option => selectedValues.includes(option.value))
      .map(option => option.label)
      .join(', ');
  }, [selectedValues, options]);

  const [isOpen, setIsOpen] = useState(false);
  useOutside(selectRef, setIsOpen);

  const onKeyPress = event => {
    if (isDisabled) {
      return;
    }

    if (event.key === 'Enter') {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className={clsx(className, 'border-transparent relative', error && 'border-light-carmine-pink')}>
      {label && (
        <p className="font-roboto-medium ml-4 mb-2">
          <span style={{ color: '#9B9DAE' }}>{label}</span>
          {isRequired && <span className="text-light-carmine-pink">*</span>}
        </p>
      )}
      <div className="relative">
        <div
          tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
          placeholder={placeholder}
          className={clsx(
            'cursor-pointer relative flex items-center justify-between',
            styles.input,
            isDisabled && styles.disabled,
            isOpen && 'pointer-events-none',
          )}
          onClick={() => setIsOpen(!isOpen)}
          onKeyPress={onKeyPress}
        >
          {inputValue ? (
            <span className="text-dark-charcoal pointer-events-none">{inputValue}</span>
          ) : (
            <span className="text-silver-sand pointer-events-none">{placeholder}</span>
          )}
          <SmallArrow className={clsx('transform transition-transform', isOpen ? '' : 'rotate-180')} />
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              ref={selectRef}
              className={clsx(styles.dropdown, 'w-full absolute py-2 z-50 max-h-72 overflow-y-auto')}
              style={{ originY: 0 }}
              initial={{
                opacity: 0,
                scaleY: 0,
              }}
              animate={{
                opacity: 1,
                scaleY: 1,
                transition: { duration: 0.3 },
              }}
              exit={{
                opacity: 0,
                scaleY: 0,
                transition: { duration: 0.3 },
              }}
            >
              {options.map(option => (
                <Option
                  key={option.value}
                  label={option.label}
                  isChecked={selectedValues.includes(option.value)}
                  onClick={() => onOptionClick(option)}
                  className="mx-2"
                />
              ))}
              {Footer && (
                <div className="mt-2">
                  <Divider />
                  <Footer />
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {/* eslint-disable-next-line no-nested-ternary */}
      {!!error && !isOpen && (
        <span className="text-light-carmine-pink absolute font-light-rubik -bottom-6 left-4 text-sm">{error}</span>
      )}
    </div>
  );
};

MultiSelect.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  onOptionClick: PropTypes.func,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  Footer: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  isDisabled: PropTypes.bool,
};

MultiSelect.defaultProps = {
  className: '',
  error: '',
  label: '',
  isRequired: false,
  placeholder: '',
  Footer: null,
  options: [],
  selectedValues: [],
  onOptionClick: () => {},
  isDisabled: false,
};

export default MultiSelect;
