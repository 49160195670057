import loadable from '@loadable/component';
import { loadableOptions } from '@utils/helpers';

const Common = {
  Medications: loadable(() => import('./Medications'), loadableOptions),
  Checkout: loadable(() => import('./Checkout'), loadableOptions),
  Profile: loadable(() => import('./Profile'), loadableOptions),
};

export default Common;
