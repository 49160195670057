import { useContext } from 'react';
import { renderRoutes } from 'react-router-config';
import Zendesk from 'react-zendesk';

import { Hint, ToastContainer } from '@components/index';
import { UserContext } from '@contexts/User';
import config from '@config/config';
import { HintContextProvider } from '@contexts/HintContext';

import { useRouterPaths } from '@patient/hooks/useRouterPaths';
import '../styles/main.scss';
import 'normalize.css';

const setting = {
  launcher: {
    chatLabel: {
      'en-US': 'Need Help',
    },
  },
  contactForm: {
    fields: [{ id: 'description', prefill: { '*': 'My pre-filled description' } }],
  },
};

const App = () => {
  const { profile, isMainLoading } = useContext(UserContext);
  const router = useRouterPaths(profile?.type, isMainLoading);

  return (
    <HintContextProvider>
      <ToastContainer />
      <Hint />
      {renderRoutes(router)}
      <Zendesk defer zendeskKey={config.zendeskKey} {...setting} />
    </HintContextProvider>
  );
};

export default App;
