import Auth from '@pages/Auth';
import { RedirectRoot } from '@pages/RedirectRoot';
import { ROOT_PATH, AUTH_SIGN_UP, AUTH_RESET_PASSWORD, CREATE_PROFILE, NOT_FOUND } from '@router/paths';

export const AUTH_ROUTES = [
  {
    name: 'signIn',
    path: ROOT_PATH,
    exact: true,
    component: Auth.SignIn,
  },
  {
    name: 'signUp',
    path: AUTH_SIGN_UP,
    exact: true,
    component: Auth.SignUp,
  },
  {
    name: 'resetPassword',
    path: AUTH_RESET_PASSWORD,
    exact: true,
    component: Auth.ResetPassword,
  },
  {
    name: 'createProfile',
    path: CREATE_PROFILE,
    exact: true,
    component: Auth.CreateProfile,
  },
  {
    path: NOT_FOUND,
    component: () => <RedirectRoot path={ROOT_PATH} />,
  },
];
