import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './Label.module.scss';

const Label = ({ children, disabled, className, style }) => (
  <span style={style} className={clsx(styles.root, disabled && styles.disabled, className)}>
    {children}
  </span>
);

Label.propTypes = {
  style: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

Label.defaultProps = {
  style: null,
  className: '',
  disabled: false,
};

export default React.memo(Label);
