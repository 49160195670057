import { useMemo, useRef } from 'react';
import RS, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Button } from '@components/Form';
import styles from './Select.module.scss';

const formatOptionLabel = ({ label, additionalLabel }) => {
  const isShowAdditionalLabel = !!additionalLabel;

  return (
    <div className="flex flex-row">
      <p className="text-dark-charcoal truncate">{label}</p>
      {isShowAdditionalLabel && <p className="text-philippine-silver pl-4 truncate">{additionalLabel}</p>}
    </div>
  );
};

const Select = ({
  options,
  className,
  isRequired,
  label,
  isSearchable,
  error,
  helpMessage,
  isAsync,
  isCreatable,
  variant,
  onClickCustomOption,
  ...props
}) => {
  const menuRef = useRef();

  const SelectComponent = useMemo(() => {
    if (isAsync && isCreatable) {
      return AsyncCreatableSelect;
    }

    if (isAsync) {
      return AsyncSelect;
    }

    if (isCreatable) {
      return CreatableSelect;
    }

    return RS;
  }, [isAsync, isCreatable]);

  const customStyles = useMemo(() => {
    const isIngredientsDosage = variant === 'ingredientsDosage';

    return {
      menu: provided => ({
        ...provided,
        boxShadow: `0 0.5rem 1.75rem -0.375rem rgba(11, 17, 33, 0.12), 0 1.125rem 5.5rem -0.25rem rgba(11, 17, 33, 0.04)`,
        borderBottomLeftRadius: '0.5rem',
        borderBottomRightRadius: '0.5rem',
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        marginTop: 0,
      }),

      menuList: styles => ({
        ...styles,
        marginTop: 0,
        marginBottom: -8,
      }),

      control: (styles, state) => ({
        ...styles,
        display: 'flex',
        flexWrap: 'noWrap',
        padding: isIngredientsDosage ? '0px 0.75rem' : '0.5rem 0.75rem',
        maxHeight: '3.375rem',
        cursor: 'pointer',
        background: state.isDisabled ? 'var(--colors-ghost-white)' : '#FFFFFF',
        borderRadius: '0.5rem',
        transition: 'all 0.3s ease-in-out',
        // eslint-disable-next-line no-nested-ternary
        border: error
          ? '1px solid var(--colors-light-carmine-pink)'
          : state.isFocused
          ? '1px solid var(--colors-iguana-green)'
          : '1px solid var(--colors-bright-gray)',
        '&:hover': { borderColor: state.isFocused ? 'var(--colors-iguana-green)' : 'var(--colors-davy-grey)' },
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minHeight: isIngredientsDosage ? '1.8rem' : '2.375rem',
      }),

      option: styles => ({
        ...styles,
        cursor: 'pointer',
        borderRadius: '0.5rem',
        marginTop: 4,
        marginBottom: 4,
      }),

      dropdownIndicator: (styles, state) => ({
        ...styles,
        transition: 'all 0.3s ease-in-out',
        // eslint-disable-next-line no-nested-ternary
        color: error
          ? 'var(--colors-light-carmine-pink)'
          : state.isFocused
          ? 'var(--colors-iguana-green)'
          : 'var(--colors-silver-sand)',
        '&:hover': { color: state.isFocused ? 'var(--colors-iguana-green)' : 'var(--colors-silver-sand)' },
      }),

      indicatorsContainer: styles => ({
        ...styles,
        display: isIngredientsDosage ? 'none' : styles.display,
      }),

      valueContainer: () => ({
        display: 'flex',
        flex: 1,
        width: '80%',
      }),

      placeholder: (styles, { isFocused, selectProps }) => {
        return {
          ...styles,
          color: 'var(--colors-silver-sand)',
          lineHeight: isIngredientsDosage ? '1.5rem' : '2.25rem',
          display: selectProps?.isSearchable && isFocused ? 'none' : 'flex',
        };
      },

      input: styles => ({
        ...styles,
        color: 'var(--colors-dark-charcoal)',
      }),

      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {
          ...provided,
          opacity,
          transition,
          lineHeight: isIngredientsDosage ? 1.5 : 2.5,
          color: 'var(--colors-dark-charcoal)',
        };
      },
    };
  }, [error, variant]);

  return (
    <div className={clsx(className, 'border border-transparent relative')}>
      {label && (
        <p className="text-xsLh22 laptop:text-base font-roboto-medium ml-2 laptop:ml-4 mb-2">
          <span style={{ color: '#9B9DAE' }}>{label}</span>
          {isRequired && <span className="text-light-carmine-pink">*</span>}
        </p>
      )}
      <SelectComponent
        styles={customStyles}
        className={clsx('rounded-lg outline-none ease-in-out')}
        isSearchable={isSearchable || isAsync || isCreatable}
        options={options}
        formatOptionLabel={formatOptionLabel}
        components={{
          IndicatorSeparator: null,
          // eslint-disable-next-line react/prop-types
          Menu: ({ children, ...props }) => (
            <components.Menu {...props} className={styles.menu} innerRef={menuRef}>
              {children}
              {onClickCustomOption && (
                <Button
                  className="text-xsLh22 laptop:text-base text-iguana-green"
                  onClick={onClickCustomOption}
                  variant="empty"
                >
                  + Add new
                </Button>
              )}
            </components.Menu>
          ),
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            text: 'var(--colors-dark-charcoal)',
            primary25: 'var(--colors-cultured)',
            primary: 'var(--colors-cultured)',
            primary50: theme.colors.neutral10,
          },
        })}
        onMenuClose={() => {
          if (!menuRef.current) {
            return;
          }

          const menuEl = menuRef.current;
          const containerEl = menuEl?.parentElement;
          const clonedMenuEl = menuEl?.cloneNode(true);

          if (!clonedMenuEl) return; // safeguard

          clonedMenuEl.classList.add(styles.menu__close);
          clonedMenuEl.addEventListener('animationend', () => {
            containerEl?.removeChild(clonedMenuEl);
          });

          containerEl?.appendChild(clonedMenuEl);
        }}
        {...props}
      />
      {/* eslint-disable-next-line no-nested-ternary */}
      {error ? (
        <span className="text-light-carmine-pink absolute font-light-rubik -bottom-6 left-4 text-sm">{error}</span>
      ) : helpMessage && !error ? (
        <span className="text-silver-sand absolute font-light-rubik -bottom-6 left-4 text-sm truncate">
          {helpMessage}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

const optionType = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

Select.propTypes = {
  options: PropTypes.arrayOf(optionType),
  className: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helpMessage: PropTypes.string,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isAsync: PropTypes.bool,
  isCreatable: PropTypes.bool,
  variant: PropTypes.string,
  onClickCustomOption: PropTypes.func,
};

Select.defaultProps = {
  onClickCustomOption: null,
  options: [],
  className: '',
  label: '',
  isRequired: false,
  isSearchable: false,
  error: '',
  helpMessage: '',
  isMulti: false,
  isAsync: false,
  isCreatable: false,
  variant: '',
};

export default Select;
