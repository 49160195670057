import * as React from 'react';

const SvgComponent = props => (
  <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.333 10.5V4.667h-10.5v18.666H12.9a5.82 5.82 0 0 0 1.376 1.266l1.622 1.068H4.658A1.159 1.159 0 0 1 3.5 24.509V3.491c0-.627.524-1.158 1.169-1.158h12.828l7.003 7V10.5h-8.167ZM14 12.833h10.5v6.94a3.444 3.444 0 0 1-1.559 2.877l-3.691 2.43-3.691-2.43A3.447 3.447 0 0 1 14 19.775v-6.942Zm2.333 6.94c0 .37.19.717.509.928l2.408 1.587 2.408-1.587a1.113 1.113 0 0 0 .509-.927v-4.607h-5.834v4.607Z"
      fill="#9B9DAE"
    />
  </svg>
);

export default SvgComponent;
