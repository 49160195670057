/* eslint-disable no-nested-ternary */
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { format } from 'date-fns';

import { useOutside } from '@hooks/useOutside';
import { Calendar as CalendarIcon, Cross } from '@assets/icons';

import Button from '../Button';
import Calendar from '../Calendar';

import styles from './DatePicker.module.scss';

const DatePicker = ({
  value,
  onChange,
  label,
  withIcon,
  placeholder,
  className,
  withClearButton,
  error,
  hideErrorText,
  helpMessage,
  isRequired,
}) => {
  const calendarRef = useRef();
  const [showCalendar, setShowCalendar] = useState(false);

  useOutside(calendarRef, setShowCalendar);

  const onChangeValue = value => {
    onChange(format(value, 'yyyy-MM-dd'));
  };

  const onClearValue = e => {
    onChange('');
    e.stopPropagation();
  };

  return (
    <div className={clsx('flex flex-col', className)}>
      {!!label && (
        <p className="font-roboto-medium ml-4 mb-2">
          <span style={{ color: '#9B9DAE' }}>{label}</span>
          {isRequired && <span className="text-light-carmine-pink">*</span>}
        </p>
      )}
      <div className="relative flex flex-row items-center">
        <Button
          onClick={() => setShowCalendar(!showCalendar)}
          className={clsx(
            'bg-silver-light rounded-lg px-4 py-2 border border-solid w-full',
            showCalendar && 'pointer-events-none',
            error ? 'border-light-carmine-pink' : 'border-bright-gray',
          )}
          variant="custom"
        >
          <div className="h-9 flex items-center justify-between w-full">
            <div className="flex items-center justify-start">
              {withIcon && (
                <div className="mr-3">
                  <CalendarIcon className="w-5 h-5" />
                </div>
              )}
              <span className="text-dawn-gray mr-4 text-base w-full text-left">
                {!value ? (
                  <span className="text-chinese-silver">{placeholder}</span>
                ) : (
                  <span>{format(new Date(value), 'dd / MM / yyyy')}</span>
                )}
              </span>
            </div>
            {!!withClearButton && !!value && (
              <div className="flex items-center cursor-pointer" onClick={onClearValue}>
                <Cross className="cursor-pointer stroke-current stroke-silver-sand w-4 h-4" />
              </div>
            )}
          </div>
        </Button>
        <AnimatePresence>
          {showCalendar && (
            <motion.div
              ref={calendarRef}
              className={clsx('absolute left-44 rounded-md bg-white z-10 flex flex-row bg-milk', styles.calendarShadow)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, translateY: '0.625rem' }}
              exit={{ opacity: 0, translateY: '1.25rem' }}
            >
              <Calendar withoutShadow value={value ? new Date(value) : new Date()} onChange={onChangeValue} />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {!hideErrorText ? (
            error ? (
              <span className="text-light-carmine-pink absolute font-light-rubik -bottom-6 left-4 text-sm">
                {error}
              </span>
            ) : helpMessage && !error ? (
              <span className="text-silver-sand absolute font-light-rubik -bottom-6 left-4 text-sm">{helpMessage}</span>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  withIcon: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  withClearButton: PropTypes.bool,
  error: PropTypes.string,
  hideErrorText: PropTypes.bool,
  helpMessage: PropTypes.string,
  isRequired: PropTypes.bool,
};

DatePicker.defaultProps = {
  value: '',
  label: '',
  withIcon: false,
  placeholder: '',
  className: '',
  withClearButton: false,
  error: '',
  hideErrorText: false,
  helpMessage: '',
  isRequired: false,
};

export default DatePicker;
