import React from 'react';
import PropTypes from 'prop-types';

import { Plus } from '@assets/icons';

const AddAddress = ({ onClick }) => (
  <div className="flex flex-row items-center cursor-pointer max-w-fit" onClick={onClick}>
    <Plus />
    <span className="text-iguana-green ml-3 text-xsLh22 laptop:text-md font-roboto-medium">Add address</span>
  </div>
);

AddAddress.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default React.memo(AddAddress);
