import Root from '@pages/Root';

import { TERMS_AND_CONDITIONS, PRIVACY_POLICY } from '@patient/router/paths';

export const ROOT_ROUTES = [
  {
    name: 'termsAndConditions',
    path: TERMS_AND_CONDITIONS,
    exact: true,
    component: Root.TermsAndConditions,
  },
  {
    name: 'privacyPolicy',
    path: PRIVACY_POLICY,
    exact: true,
    component: Root.PrivacyPolicy,
  },
];
