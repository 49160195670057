import { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import useDevice from '@hooks/useDevice';
import { Button, CustomModal, TextHintWrapper } from '@components/index';
import { AccountIcon, LogoIcon, Logout, Policy, Terms } from '@assets/icons';
import { UserContext } from '@contexts/User';
import { USER_ROLES } from '@utils/consts';

import { useRouterPaths } from '@patient/hooks/useRouterPaths';
import { PATIENT, PRIVACY_POLICY, ROOT_PATH, TERMS_AND_CONDITIONS } from '@patient/router/paths';
import styles from './Sidebar.module.scss';
import SidebarItem from './SidebarItem';

const Sidebar = ({ wide, handleClose }) => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const { isMobile } = useDevice();
  const { profile, logout, isMainLoading } = useContext(UserContext);

  const { pathname } = useLocation();
  const [active, setActive] = useState();
  const menuItems = useRouterPaths(profile?.type, isMainLoading)[2].routes;

  return (
    <>
      <motion.div
        className={clsx(
          'bg-alabaster flex items-start flex-col border-r border-bright-gray',
          isMobile && 'w-full h-full overflow-auto z-20',
        )}
        onClick={() => setActive(!active)}
      >
        <AnimatePresence>
          <div className={clsx('flex flex-col h-full', wide && !isMobile && 'w-72', isMobile && 'w-full pt-10')}>
            {!isMobile && (
              <div className="px-4 py-8 border-b border-bright-gray flex items-center mb-6">
                <Link to={ROOT_PATH}>
                  <LogoIcon />
                </Link>
                {wide && (
                  <span className="text-white ml-3 cursor-default" style={{ color: '#0963AE' }}>
                    specialist pharmacy
                  </span>
                )}
              </div>
            )}
            <div className="flex flex-col justify-between h-full">
              <div>
                {menuItems
                  .map(item => {
                    if (!item?.name || item.hiddenInSidebar) {
                      return null;
                    }

                    const isActive = pathname && pathname.startsWith(item.path);

                    return (
                      <SidebarItem
                        onClick={handleClose}
                        key={item.name}
                        wide={wide}
                        active={isActive}
                        item={item}
                        separatorBelow={item.name === 'My Orders'}
                      />
                    );
                  })
                  .filter(Boolean)}
              </div>
              <div>
                <div
                  className={clsx(
                    'flex flex-row justify-start items-center mt-4 laptop:mt-auto px-5 laptop:px-8',
                    !wide && 'self-center',
                  )}
                >
                  <Link to={TERMS_AND_CONDITIONS} className="flex flex-row items-center">
                    <Terms className={clsx(isMobile ? 'w-5 h-5' : 'w-7 h-7')} />
                    {wide && <span className="text-santas-gray ml-3">Terms and Conditions</span>}
                  </Link>
                </div>
                <div
                  className={clsx(
                    'flex flex-row justify-start items-center mt-8 px-5 laptop:px-8',
                    !wide && 'self-center',
                  )}
                >
                  <Link to={PRIVACY_POLICY} className="flex flex-row items-center">
                    <Policy className={clsx(isMobile ? 'w-5 h-5' : 'w-7 h-7')} />
                    {wide && <span className="text-santas-gray ml-3">Privacy Policy</span>}
                  </Link>
                </div>
                <div
                  className={clsx(wide ? 'justify-between items-center' : 'self-center', 'px-4 py-10 laptop:py-8 flex')}
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    to={profile.type === USER_ROLES.PATIENT ? PATIENT.PROFILE : '#'}
                    className="block cursor-pointer flex flex-row flex-grow-0 items-center truncate"
                  >
                    <div
                      className="bg-white w-12 h-12 laptop:w-14 laptop:h-14 rounded-full items-center p-4 laptop:p-5 flex"
                      style={{ background: '#F0F0F2' }}
                    >
                      <AccountIcon className="opacity-60 w-4 h-5 laptop:w-5 laptop:h-6" />
                    </div>
                    {wide && (
                      <TextHintWrapper className="text-santas-gray text-lg ml-4">
                        {profile.firstName} {profile.lastName}
                      </TextHintWrapper>
                    )}
                  </Link>
                  {wide && (
                    <div className="cursor-pointer" onClick={() => setIsLogoutModalOpen(true)}>
                      <Logout width={isMobile ? 1.5 : 1.75} height={isMobile ? 1.5 : 1.75} />
                    </div>
                  )}
                </div>
                {!wide && (
                  <div
                    className="cursor-pointer flex self-center justify-center mb-8"
                    onClick={() => setIsLogoutModalOpen(true)}
                  >
                    <Logout width={isMobile ? 1.5 : 1.75} height={isMobile ? 1.5 : 1.75} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </AnimatePresence>
      </motion.div>
      <CustomModal
        openModal={isLogoutModalOpen}
        setOpenModal={setIsLogoutModalOpen}
        className={clsx(isMobile ? 'w-full' : styles.logoutModal)}
      >
        <div className="p-8 bg-white rounded-md">
          <h4 className="text-base laptop:text-xl text-dark-charcoal font-roboto-medium mb-3">Log out</h4>
          <p className="text-xsLh22 laptop:text-base text-davy-grey mb-6 laptop:mb-10">
            Are you sure you want to log out from your account?
          </p>
          <div className="flex flex-row justify-center laptop:justify-end">
            <Button onClick={() => setIsLogoutModalOpen(false)} className="px-6 py-3 mr-6" variant="empty">
              <span className="text-xsLh22 laptop:text-base">Cancel</span>
            </Button>
            <Button onClick={() => logout()} variant="danger" className="px-6 py-3">
              <span className="text-xsLh22 laptop:text-base">Log out</span>
            </Button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

Sidebar.propTypes = {
  wide: PropTypes.bool,
  handleClose: PropTypes.func,
};

Sidebar.defaultProps = {
  wide: true,
  handleClose: () => {},
};

export default memo(Sidebar);
