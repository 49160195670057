import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  selectedRows: [],
  setSelectedRows: () => {},
};

const TableContext = createContext(initialState);

export const TableContextProvider = ({ children }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const context = useMemo(
    () => ({
      selectedRows,
      setSelectedRows,
    }),
    [selectedRows],
  );

  return <TableContext.Provider value={context}>{children}</TableContext.Provider>;
};

TableContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableContext;
