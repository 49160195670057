import config from '@config/config';
import ApiService from './api';

class PrescriptionApi extends ApiService {
  constructor() {
    if (PrescriptionApi.instance) {
      return PrescriptionApi.instance;
    }

    super(config.prescriptionApiUrl);
    PrescriptionApi.instance = this;
  }

  async getPrescriptions() {
    const response = await this.http.get(`/accounts/${this.mainAccountId}/prescriptions`);
    return response.data;
  }

  async getPrescriptionById(id) {
    const response = await this.http.get(`/accounts/${this.mainAccountId}/prescriptions/${id}`);
    return response.data;
  }

  async getPrescriptionsByPatientIds(patientIds) {
    const response = await this.http.get(
      `/accounts/${this.mainAccountId}/prescriptions?${patientIds.map(id => `patientId=${id}`).join('&')}`,
    );
    return response.data;
  }

  async createPrescription(data) {
    const response = await this.http.post(`/accounts/${this.mainAccountId}/prescriptions`, {
      id: data.id || undefined,
      accountId: this.mainAccountId,
      userId: data.userId,
      patientId: data.patientId,
      sourceDocumentPath: data.sourceDocumentPath,
      status: data.status,
      note: data.note,
      prescriptionDate: data.prescriptionDate,
      dateReceived: data.dateReceived,
      dateExpiry: data.dateExpiry,
    });

    return response.data;
  }

  async updatePrescriptionById(id, data, signToken, additionalOptions) {
    const query = additionalOptions
      ? Object.keys(additionalOptions)
          .map(key => `${key}=${additionalOptions[key]}`)
          .join('&')
      : '';

    const response = await this.http.put(
      `/accounts/${this.mainAccountId}/prescriptions/${id}?${query}`,
      {
        id: data.id || undefined,
        accountId: this.mainAccountId,
        userId: data.userId,
        patientId: data.patientId,
        sourceDocumentPath: data.sourceDocumentPath,
        status: data.status,
        note: data.note,
        prescriptionDate: data.prescriptionDate,
        dateReceived: data.dateReceived,
        dateExpiry: data.dateExpiry,
      },
      {
        headers: {
          'sign-token': signToken,
        },
      },
    );

    return response.data;
  }

  async deletePrescriptionById(id) {
    const response = await this.http.delete(`/accounts/${this.mainAccountId}/prescriptions/${id}`);
    return response.data;
  }

  async getPrescriptionItems(prescriptionId) {
    const response = await this.http.get(`/accounts/${this.mainAccountId}/prescriptions/${prescriptionId}/items`);
    return response.data;
  }

  async createPrescriptionItem(prescriptionId, data) {
    const response = await this.http.post(`/accounts/${this.mainAccountId}/prescriptions/${prescriptionId}/items`, {
      id: data.id || undefined,
      accountId: this.mainAccountId,
      userId: data.userId,
      prescriptionId,
      medicationType: data.medicationType,
      medicationObject: data.medicationObject,
      repeatsNum: data.repeatsNum,
      quantity: data.quantity,
      packageSize: data.packageSize,
      directions: data.directions,
      additionalNote: data.additionalNote,
    });

    return response.data;
  }

  async updatePrescriptionItemById(prescriptionId, id, data) {
    const response = await this.http.put(
      `/accounts/${this.mainAccountId}/prescriptions/${prescriptionId}/items/${id}`,
      {
        id: data.id || id,
        accountId: this.mainAccountId,
        userId: data.userId,
        prescriptionId,
        medicationType: data.medicationType,
        medicationObject: data.medicationObject,
        repeatsNum: data.repeatsNum,
        quantity: data.quantity,
        packageSize: data.packageSize,
        directions: data.directions,
        additionalNote: data.additionalNote,
      },
    );

    return response.data;
  }

  async deletePrescriptionItemById(prescriptionId, id) {
    const response = await this.http.delete(
      `/accounts/${this.mainAccountId}/prescriptions/${prescriptionId}/items/${id}`,
    );

    return response.data;
  }

  async signPrescription(prescriptionId, password) {
    const response = await this.http.post(`/accounts/${this.mainAccountId}/prescriptions/${prescriptionId}/sign`, {
      password,
    });

    return response.data;
  }
}

const prescriptionApiInstance = new PrescriptionApi();
export default prescriptionApiInstance;
