import React from 'react';
import PropTypes from 'prop-types';

import { BlockSmall } from '@assets/icons';

const DeleteAddress = ({ onClick, label }) => (
  <div className="flex flex-row cursor-pointer items-center" onClick={onClick}>
    <BlockSmall />
    <p className="ml-1">
      <span style={{ color: '#9B9DAE' }}>{`Delete ${label} address`}</span>
    </p>
  </div>
);

DeleteAddress.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default React.memo(DeleteAddress);
