import config from '@config/config';
import ApiService from './api';
import LocalStorageService from '../localStorage';

class AuthApi extends ApiService {
  constructor() {
    if (AuthApi.instance) {
      return AuthApi.instance;
    }

    super(config.authApiUrl);
    AuthApi.instance = this;
  }

  async checkEmail(email) {
    const response = await this.http.post(`/accounts/${this.mainAccountId}/checkEmail`, { email });
    return response.data;
  }

  async emailLogin(email, password, otp) {
    const response = await this.http.post(`/accounts/${this.mainAccountId}/login`, { email, password, otp });
    return response.data;
  }

  async emailRegistration(email, password, token) {
    const response = await this.http.post(`/accounts/${this.mainAccountId}/register`, { email, password, token });
    return response.data;
  }

  async getSecurityQuestion(email) {
    const response = await this.http.post(`/accounts/${this.mainAccountId}/forgotPassword`, { email });
    return response.data;
  }

  async forgotPassword(email, answer) {
    const response = await this.http.post(`/accounts/${this.mainAccountId}/forgotPassword`, { email, answer });
    return response.data;
  }

  async resetPassword(newPassword, token) {
    const response = await this.http.post(`/accounts/${this.mainAccountId}/resetPassword`, {
      newPassword,
      resetPasswordToken: token,
    });

    return response.data;
  }

  async logout() {
    const response = await this.http.post('/logout');

    return response.data;
  }

  async getCurrentUser() {
    const response = await this.http.get('/current');
    return response.data;
  }

  async refreshToken() {
    const refreshToken = LocalStorageService.getRefreshToken();
    if (!refreshToken) {
      throw new Error('Refresh token does not exist');
    }

    const response = await this.http.post('/refreshToken', { refreshToken });
    return response.data;
  }

  async createPhoneVerificationToken(userId, phone) {
    await this.http.post(`/accounts/${this.mainAccountId}/phone/generateToken`, {
      userId,
      phone,
    });
  }

  async verifyPhoneToken(userId, token) {
    const response = await this.http.post(`/accounts/${this.mainAccountId}/phone/verify`, {
      userId,
      token,
    });

    return response.data;
  }
}

const authApiInstance = new AuthApi();
export default authApiInstance;
