const SvgComponent = () => (
  <svg className="w-6 h-6" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="7.2" stroke="#9B9DAE" strokeWidth="1.6" />
    <rect
      x="4.58594"
      y="5.72278"
      width="1.6"
      height="13.8037"
      transform="rotate(-45.16 4.58594 5.72278)"
      fill="#9B9DAE"
    />
  </svg>
);

export default SvgComponent;
