import clsx from 'clsx';
import PropTypes from 'prop-types';

function SVGComponent({ className, ...props }) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('w-6 h-6', className)}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M13.1717 12L8.22168 16.95L9.63568 18.364L15.9997 12L9.63568 5.63599L8.22168 7.04999L13.1717 12Z"
        fill="#C4C4C4"
      />
    </svg>
  );
}

SVGComponent.propTypes = {
  className: PropTypes.string,
};

SVGComponent.defaultProps = {
  className: '',
};

export default SVGComponent;
