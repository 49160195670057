import { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';

import useResize from '@hooks/useResize';

import { useHint } from '@contexts/HintContext';
import styles from './Hint.module.scss';

const Hint = () => {
  const hint = useRef();
  const { targetRef, setTargetRef } = useHint();
  const dimensions = useResize();

  const handleClickOutside = useCallback(
    event => {
      if (hint.current && !hint.current.contains(event.target)) {
        setTargetRef(null);
      }
    },
    [hint?.current, setTargetRef],
  );

  const getCoordsTarget = () => {
    const box = targetRef.current.getBoundingClientRect();

    return {
      top: box.top + window.pageYOffset,
      right: box.right + window.pageXOffset,
      bottom: box.bottom + window.pageYOffset,
      left: box.left + window.pageXOffset,
      width: box.width,
    };
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (targetRef?.current && hint?.current) {
      const targetCoords = getCoordsTarget();
      const hintCoords = hint.current.getBoundingClientRect();

      hint.current.style.left = `${targetCoords.left - hintCoords.width / 2}px`;
      hint.current.style.top = `${targetCoords.top - hintCoords.height}px`;
    }
  }, [targetRef?.current, hint?.current, dimensions]);

  useEffect(() => {
    return () => {
      setTargetRef(null);
    };
  }, []);

  if (!targetRef?.current) {
    return null;
  }

  return (
    <span ref={hint} className={clsx('absolute w-max bg-white rounded-lg p-3 z-10', styles.root)}>
      {targetRef.current.textContent}
    </span>
  );
};

export default Hint;
