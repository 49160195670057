import React, { useMemo, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { format } from 'date-fns';

import { UserContext } from '@contexts/User';
import { CustomModal, Table, Divider, Button, Checkbox, Input, TextHintWrapper } from '@components/index';
import { getMedicationName, getMedicationFormulation } from '@utils/medication';
import usePrescriptionSign from '@hooks/prescription/usePrescriptionSign';

import styles from './PrescriptionPreview.module.scss';

const PrescriptionPreview = ({
  isOpen,
  setIsOpen,
  medications,
  prescription,
  patient,
  prescriber,
  note,
  onPrescriptionSubmit,
  isLoading,
}) => {
  const [password, setPassword] = useState('');
  const [isPasswordShow, setIsPasswordShow] = useState(true);

  const [isSigned, setIsSigned] = useState(false);
  const [signPrescription, { isLoading: isSigning }] = usePrescriptionSign();
  const [signToken, setSignToken] = useState('');
  const [signError, setSignError] = useState(false);

  const { profile } = useContext(UserContext);

  useEffect(() => {
    if (isOpen) {
      return;
    }

    // Cleanup on modal close
    setPassword('');
    setIsSigned(false);
    setSignToken('');
    setSignError(false);
    setIsPasswordShow(true);
  }, [isOpen]);

  /* eslint-disable react/prop-types */
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Product (composition)',
        accessor: 'product',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
      {
        Header: 'Formulation',
        accessor: 'formulation',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
      {
        Header: 'Packsize',
        accessor: 'packageSize',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
      {
        Header: 'No. of Repeats',
        accessor: 'repeatsNum',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
      {
        Header: 'Directions',
        accessor: 'directions',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
      {
        Header: 'Additional Note',
        accessor: 'additionalNote',
        Cell: ({ cell: { value } }) => <TextHintWrapper>{value}</TextHintWrapper>,
      },
    ],
    [],
  );
  /* eslint-enable react/prop-types */

  const tableData = useMemo(() => {
    if (!medications?.length) {
      return [];
    }

    return medications.map(medication => ({
      id: medication.id,
      product: getMedicationName(medication),
      formulation: getMedicationFormulation(medication),
      packageSize: medication.packageSize,
      quantity: medication.quantity,
      repeatsNum: medication.repeatsNum,
      directions: medication.directions,
      additionalNote: medication.additionalNote,
      originalMedication: { ...medication },
    }));
  }, [medications]);

  const onPrescriptionSign = async () => {
    const token = await signPrescription(prescription.id, password);
    if (!token) {
      setSignError(true);
      return;
    }

    setIsSigned(true);
    setSignToken(token);
    setIsPasswordShow(false);
  };

  const onSavePrescription = () => {
    if (!signToken) {
      return;
    }

    onPrescriptionSubmit(signToken);
  };

  const onPasswordChange = e => {
    setSignError(false);
    setPassword(e.target.value);
  };

  if (!patient || !prescription) {
    return <></>;
  }

  return (
    <CustomModal openModal={isOpen} setOpenModal={setIsOpen} className={styles.prescriptionPreview}>
      <div className="p-8 bg-white h-full overflow-y-auto">
        <h4 className="text-xl text-dark-charcoal font-roboto-medium mb-2">Prescription Preview</h4>
        <div className="mt-9">
          <span className="text-md text-dark-charcoal">Unique ID: {prescription.id}</span>
        </div>
        <div className="flex flex-row mt-5">
          {/* TODO: Make separate component */}
          <div className="flex flex-col">
            <span className="mb-3 font-roboto-medium" style={{ color: '#9B9DAE' }}>
              Creation Date:
            </span>
            <span className="text-davy-grey">{format(Number(prescription.createdAt), 'dd/MM/yyyy')}</span>
          </div>
          {prescriber && (
            <div className="flex flex-col ml-16">
              <span className="mb-3 font-roboto-medium" style={{ color: '#9B9DAE' }}>
                Doctor Name:
              </span>
              <span className="text-davy-grey">
                {prescriber?.firstName} {prescriber?.lastName}
              </span>
            </div>
          )}
          <div className="flex flex-col ml-16">
            <span className="mb-3 font-roboto-medium" style={{ color: '#9B9DAE' }}>
              Patient Name:
            </span>
            <span className="text-davy-grey">
              {patient?.firstName} {patient?.lastName}
            </span>
          </div>
          <div className="flex flex-col ml-16">
            <span className="mb-3 font-roboto-medium" style={{ color: '#9B9DAE' }}>
              Patient’s DOB:
            </span>
            <span className="text-davy-grey">{format(new Date(patient.birthday), 'dd/MM/yyyy')}</span>
          </div>
        </div>
        <Divider className="my-7" />
        <Table columns={tableColumns} data={tableData} templateColumns="4fr 2fr 2fr 2fr 2fr 3fr 3fr" />
        <div className="flex flex-col mt-10">
          <span className="font-roboto-medium mb-3" style={{ color: '#9B9DAE' }}>
            Notes:
          </span>
          <span className="text-davy-grey">{note}</span>
        </div>
        <div className={clsx(styles.signBox, 'mt-10')}>
          <div className="flex flex-row relative mb-7">
            <div
              className={clsx(
                'py-3.5 px-5 flex items-center border border-r-0 border-solid rounded-l-lg transition-colors duration-30',
                signError ? 'border-light-carmine-pink' : 'border-bright-gray',
                isSigned ? 'bg-iguana-green rounded-r-lg' : 'bg-anti-flash-white',
              )}
            >
              {isSigned && <Checkbox checked={isSigned} />}
              <span
                className={clsx('transition-colors duration-300', isSigned ? 'ml-2 text-white' : 'text-dark-charcoal')}
              >
                E-Signature
              </span>
            </div>
            {isPasswordShow && (
              <div className="relative">
                <input type="email" className="w-0 h-0 border-0 m-0 p-0 absolute" />
                <Input
                  value={password}
                  onChange={onPasswordChange}
                  placeholder="Enter your password"
                  className="w-80"
                  type="password"
                  variant="rightRounded"
                  iconClassName="hidden"
                  error={signError}
                />
                {!!password && (
                  <Button
                    onClick={onPrescriptionSign}
                    isLoading={isSigning}
                    className="absolute right-2 top-1.5 w-20 h-10"
                    variant="primary"
                    isDisabled={!password || !!signError}
                  >
                    Confirm
                  </Button>
                )}
              </div>
            )}
            {!!signError && (
              <span className="absolute -bottom-6 left-4 text-sm text-light-carmine-pink">Invalid password</span>
            )}
          </div>
          <div className="flex flex-col">
            <span className="text-iguana-green">
              {isSigned ? `Successfully e-signed by ${profile.firstName} ${profile.lastName}` : ''}
            </span>
            <span className="mb-2 text-sm text-davy-grey">
              I, {profile.firstName} {profile.lastName}, confirm that my advanced electronic signature is proof that
              this health prescription is the one and only original copy to be filled by the London Specialist Pharmacy
              Ltd. This is in line with Regulation 219 of the Human Medicines Regulation 2012
            </span>
          </div>
        </div>
        <div className="flex flex-row justify-end mt-4">
          <Button className="mr-10" variant="empty" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSavePrescription} isDisabled={!signToken} isLoading={isLoading}>
            Save and Submit
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

PrescriptionPreview.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
  onPrescriptionSubmit: PropTypes.func.isRequired,
  note: PropTypes.string,
  isLoading: PropTypes.bool,
  medications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      packageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      repeatsNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      directions: PropTypes.string,
      additionalNote: PropTypes.string,
    }),
  ),
  prescription: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  patient: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    birthday: PropTypes.string,
  }),
  prescriber: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

PrescriptionPreview.defaultProps = {
  isOpen: false,
  medications: [],
  note: '',
  isLoading: false,
  patient: null,
  prescriber: null,
};

export default React.memo(PrescriptionPreview);
