import { RedirectRoot } from '@pages/RedirectRoot';

import { NOT_FOUND, PATIENT } from '@patient/router/paths';
import Patient from '@patient/pages/Patient';
import Common from '@patient/pages/Common';
import { Notice, Phone, Pill, Question, Sheet } from '@patient/assets/icons';

export const PATIENT_ROUTES = [
  {
    name: 'My Medications',
    path: PATIENT.MEDICATIONS,
    exact: true,
    component: Common.Medications,
    menuIcon: Pill,
  },
  {
    name: 'My Orders',
    path: PATIENT.ORDERS,
    exact: true,
    component: Patient.Orders,
    menuIcon: Sheet,
  },
  {
    name: 'FAQ’s',
    path: PATIENT.FAQ,
    exact: true,
    component: () => <></>,
    menuIcon: Question,
  },
  {
    name: 'Contact us',
    path: PATIENT.CONTACT,
    exact: true,
    component: () => <></>,
    menuIcon: Phone,
  },
  {
    name: 'Feedback',
    path: PATIENT.FEEDBACK,
    exact: true,
    component: () => <></>,
    menuIcon: Notice,
  },
  {
    name: 'Checkout',
    path: PATIENT.CHECKOUT,
    exact: true,
    component: Common.Checkout,
    hiddenInSidebar: true,
  },
  {
    name: 'Profile',
    path: PATIENT.PROFILE,
    exact: true,
    component: Common.Profile,
    hiddenInSidebar: true,
  },
  {
    path: NOT_FOUND,
    component: () => <RedirectRoot path={PATIENT.MEDICATIONS} />,
  },
];
