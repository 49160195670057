import { PRESCRIBER, NOT_FOUND, COMMON } from '@router/paths';
import { RedirectRoot } from '@pages/RedirectRoot';
import Prescriber from '@pages/Prescriber';
import Common from '@pages/Common';
import { Home, Settings } from '@assets/icons';

export const PRESCRIBER_ROUTES = [
  {
    name: 'Home',
    path: PRESCRIBER.HOME,
    exact: true,
    component: Prescriber.Home,
    isMenu: true,
    menuIcon: Home,
  },
  {
    name: 'Settings',
    path: PRESCRIBER.SETTINGS,
    exact: true,
    component: Prescriber.Settings,
    isMenu: true,
    menuIcon: Settings,
  },
  {
    name: 'PrescriptionHistory',
    path: PRESCRIBER.PRESCRIPTION_HISTORY,
    exact: true,
    component: Prescriber.PrescriptionHistory,
  },
  {
    name: 'Prescription',
    path: COMMON.PRESCRIPTION,
    exact: true,
    component: Common.Prescription,
  },
  {
    path: NOT_FOUND,
    component: () => <RedirectRoot path={PRESCRIBER.HOME} />,
  },
];
