import PropTypes from 'prop-types';

const SvgComponent = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_19_1556)">
      <path
        d="M10.0001 8.82166L14.1251 4.69666L15.3034 5.87499L11.1784 9.99999L15.3034 14.125L14.1251 15.3033L10.0001 11.1783L5.87511 15.3033L4.69678 14.125L8.82178 9.99999L4.69678 5.87499L5.87511 4.69666L10.0001 8.82166Z"
        fill="#C4C4C4"
      />
    </g>
    <defs>
      <clipPath id="clip0_19_1556">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: 'var(--colors-santas-gray)',
};

export default SvgComponent;
