const SvgComponent = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 4.9H19V3.1H5V4.9ZM19 19.1H5V20.9H19V19.1ZM4.9 9V5H3.1V9H4.9ZM19.1 5V9H20.9V5H19.1ZM4 9.9H20V8.1H4V9.9ZM19.1 9V15H20.9V9H19.1ZM19.1 15V19H20.9V15H19.1ZM4.9 19V15H3.1V19H4.9ZM4.9 15V9H3.1V15H4.9ZM20 14.1H4V15.9H20V14.1ZM5 19.1C4.94477 19.1 4.9 19.0552 4.9 19H3.1C3.1 20.0493 3.95066 20.9 5 20.9V19.1ZM19 20.9C20.0493 20.9 20.9 20.0493 20.9 19H19.1C19.1 19.0552 19.0552 19.1 19 19.1V20.9ZM19 4.9C19.0552 4.9 19.1 4.94477 19.1 5H20.9C20.9 3.95066 20.0493 3.1 19 3.1V4.9ZM5 3.1C3.95066 3.1 3.1 3.95066 3.1 5H4.9C4.9 4.94477 4.94477 4.9 5 4.9V3.1Z"
      fill="#9DA0AB"
    />
  </svg>
);

export default SvgComponent;
