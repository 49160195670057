import PropTypes from 'prop-types';

const ShoppingBag = ({ width, height, ...props }) => (
  <svg
    width={`${width}rem`}
    height={`${height}rem`}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.83325 10.5C5.83325 9.21134 6.87792 8.16667 8.16659 8.16667H19.8333C21.1219 8.16667 22.1666 9.21134 22.1666 10.5V21C22.1666 22.933 20.5996 24.5 18.6666 24.5H9.33325C7.40025 24.5 5.83325 22.933 5.83325 21V10.5Z"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 11.6667V7C17.5 5.067 15.933 3.5 14 3.5V3.5C12.067 3.5 10.5 5.067 10.5 7V11.6667"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

ShoppingBag.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

ShoppingBag.defaultProps = {
  width: 1.75,
  height: 1.75,
};

export default ShoppingBag;
