import { useState } from 'react';
import { useTable } from 'react-table';
import clsx from 'clsx';

import { routerType } from '@router/types';

import './CartModal.scss';

const ItemsTable = ({ columns, data, templateColumns }) => {
  const [activeIndex] = useState(null);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });
  const lastRow = data[data.length - 1];

  return (
    <div className={clsx('items-table-wrapper')}>
      <table
        className={clsx('items-table', !templateColumns && `grid-cols-${columns.length}`, 'compactTable')}
        style={templateColumns ? { gridTemplateColumns: templateColumns } : {}}
        {...getTableProps()}
      >
        <thead {...headerGroups[0].getHeaderGroupProps()}>
          <tr>
            {headerGroups[0].headers.map((column, i) => (
              <th
                key={i} // eslint-disable-line react/no-array-index-key
              >
                {column.render('Header')}
                <span className={`resize-handle ${activeIndex === i ? 'active' : 'idle'}`} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                key={i} // eslint-disable-line react/no-array-index-key
              >
                {row.cells.map((cell, j) => {
                  if (lastRow === row.original) {
                    if (cell.column.Header === 'Quantity' || cell.column.Header === '')
                      // eslint-disable-next-line react/no-array-index-key
                      return <td key={i + j} className="w-full" />;
                  }
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <td key={i + j} {...cell.getCellProps()} className={clsx('px-8 w-full h-full items-center')}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

ItemsTable.propTypes = routerType;

export default ItemsTable;
