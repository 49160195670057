import * as React from 'react';

const CalendarIcon = props => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      d="M15 2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h4V0h2v2h6V0h2v2Zm-2 2H7v2H5V4H2v4h16V4h-3v2h-2V4Zm5 6H2v8h16v-8ZM4 13h2v2H4v-2Zm4 0h8v2H8v-2Z"
      fill="#C4C4C4"
    />
  </svg>
);

export default CalendarIcon;
