import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = ({ fill, ...props }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.0743 4.92567C25.8078 7.65917 25.8078 12.0913 23.0743 14.8248L14.826 23.0743C12.0913 25.8078 7.65917 25.8078 4.92567 23.0743C2.19217 20.3408 2.19217 15.9087 4.92567 13.1752L13.174 4.92567C15.9087 2.19217 20.3408 2.19217 23.0743 4.92567V4.92567ZM16.4757 18.1242L9.87584 11.5243L6.57534 14.826C4.75301 16.6472 4.75301 19.6023 6.57534 21.4247C8.39767 23.247 11.3528 23.247 13.1752 21.4247L16.4757 18.123V18.1242ZM21.4247 6.57534C19.6023 4.75301 16.6472 4.75301 14.8248 6.57534L11.5267 9.87467L18.1265 16.4745L21.4258 13.1752C23.2482 11.3528 23.2482 8.39767 21.4258 6.57534H21.4247Z"
      fill={fill}
    />
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: 'var(--colors-santas-gray)',
};

export default SvgComponent;
