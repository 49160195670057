import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { Counter } from '@components/index';
import { getMedicationDosageInfo, getMedicationFormulation, getMedicationName } from '@utils/medication';

import Property from '@patient/components/Elements/MedicationCard/Property';
import Label from '@patient/components/Elements/Label/Label';
import CardButton from './CardButton';

const DefaultLayout = ({
  added,
  recent,
  disabled,
  onAdd,
  onOpenCart,
  medication,
  createdAt,
  orderWithin,
  quantity,
  onChangeQuantity,
}) => {
  const { packageSize, price, maxPackageCount } = medication;

  return (
    <div className="border h-max rounded-lg p-6">
      {recent && <Label className="mr-2">New</Label>}
      <Label disabled>{format(Number(createdAt), 'PP')}</Label>
      <p className="mt-6 text-2xl w-80 font-semibold">{getMedicationName(medication)}</p>
      <p className="mt-4 text-xl font-semibold">{`£${Math.round(price * quantity * 100) / 100}`}</p>
      <div className="mt-8 flex flex-col gap-y-3">
        <Property name="Dosage info" value={getMedicationDosageInfo(medication)} />
        <Property name="Formulation" value={getMedicationFormulation(medication)} />
        <Property name="Package size" value={`${packageSize} ${getMedicationFormulation(medication)}`} />
        <Property name="Available for order" value={`${maxPackageCount} items`} />
        <Property name="Order within" value={orderWithin} />
      </div>
      {!disabled && (
        <div className="flex gap-x-4 justify-between mt-8">
          <CardButton added={added} disabled={disabled} onClick={added ? onOpenCart : onAdd} />
          <Counter
            className="w-full"
            onChange={onChangeQuantity}
            value={quantity}
            maxValue={maxPackageCount}
            disabled={added || disabled}
          />
        </div>
      )}
    </div>
  );
};

DefaultLayout.propTypes = {
  added: PropTypes.bool.isRequired,
  recent: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onOpenCart: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  medication: PropTypes.shape({
    price: PropTypes.number.isRequired,
    maxPackageCount: PropTypes.number.isRequired,
    packageSize: PropTypes.string.isRequired,
  }).isRequired,
  createdAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  orderWithin: PropTypes.string.isRequired,
};

export default DefaultLayout;
