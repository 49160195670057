import config from '@config/config';
import { USER_ROLES } from '@utils/consts';

import ApiService from './api';

class ProfileApi extends ApiService {
  constructor() {
    if (ProfileApi.instance) {
      return ProfileApi.instance;
    }

    super(config.accountApiUrl);

    ProfileApi.instance = this;
  }

  async getProfileByUserId(userId) {
    const response = await this.http.get(`/accounts/${this.mainAccountId}/persons?userId=${userId}`);

    return response.data;
  }

  async getOwnPatients() {
    const response = await this.http.get(`/accounts/${this.mainAccountId}/persons?userId=null`);

    return response.data;
  }

  async getProfileById(id) {
    const response = await this.http.get(`/accounts/${this.mainAccountId}/persons/${id}`);

    return response.data;
  }

  async getProfilesByType(type, userIdExists) {
    let url = `/accounts/${this.mainAccountId}/persons?type=${type}`;

    if (userIdExists !== undefined) {
      url = `${url}&userId__exists=${userIdExists}`;
    }

    const response = await this.http.get(url);

    return response.data;
  }

  async getProfilesByAssociatedUserId(associatedUserId) {
    const response = await this.http.get(
      `/accounts/${this.mainAccountId}/persons?associatedUserId=${associatedUserId}`,
    );

    return response.data;
  }

  async createProfile(userId, data) {
    let profileType = '';

    if (userId) {
      profileType = (() => {
        if (config.isPatientApp) {
          return USER_ROLES.PATIENT;
        }

        if (config.isPctApp) {
          return USER_ROLES.PCT;
        }

        return USER_ROLES.PRESCRIBER;
      })();
    }

    const response = await this.http.post(`/accounts/${this.mainAccountId}/persons`, {
      accountId: this.mainAccountId,
      userId,
      organizationId: this.mainOrganizationId,
      organizationName: 'Main Organization',
      firstName: data.firstName,
      lastName: data.lastName,
      type: data.type || profileType,
      profilePictureName: '',
      birthday: data.birthday || null,
      address: data.address,
      additionalData: {
        personalEmails: data.emails,
        phones: data.phones,
        practitionerType: data.practitionerType,
        qualificationNumber: data.qualificationNumber,
        clinicEmail: data.clinicEmail,
        clinicName: data.clinicName,
        clinicPhone: data.clinicPhone,
        allergies: data.allergies,
        gender: data.gender,
      },
    });

    return response.data;
  }

  async updateProfileById(id, data) {
    const response = await this.http.put(`/accounts/${this.mainAccountId}/persons/${id}`, data);

    return response.data;
  }

  async deleteProfileById(id) {
    const response = await this.http.delete(`/accounts/${this.mainAccountId}/persons/${id}`);

    return response.data;
  }

  async createProfileNote(data) {
    const response = await this.http.post(`/accounts/${this.mainAccountId}/personNotes`, {
      ...data,
      accountId: this.mainAccountId,
    });

    return response.data;
  }

  async updateProfileNote(id, data) {
    const response = await this.http.put(`/accounts/${this.mainAccountId}/personNotes/${id}`, {
      ...data,
      accountId: this.mainAccountId,
    });

    return response.data;
  }

  async getProfileNotesByProfileId(profileId) {
    const response = await this.http.get(`/accounts/${this.mainAccountId}/personNotes?personId=${profileId}`);

    return response.data;
  }
}

const profileApiInstance = new ProfileApi();
export default profileApiInstance;
