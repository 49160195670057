import PropTypes from 'prop-types';
import clsx from 'clsx';

const SvgComponent = ({ className, fill, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={clsx('w-6 h-6', className)}
    {...props}
  >
    <path
      d="M21.074 2.925a7 7 0 0 1 0 9.9l-8.248 8.249a7.001 7.001 0 0 1-9.9-9.9l8.248-8.249a7.001 7.001 0 0 1 9.9 0Zm-6.598 13.199-6.6-6.6-3.3 3.302a4.666 4.666 0 1 0 6.6 6.598l3.3-3.302v.002Zm4.949-11.55a4.667 4.667 0 0 0-6.6 0l-3.298 3.3 6.6 6.6 3.299-3.3a4.667 4.667 0 0 0 0-6.6h-.001Z"
      fill={fill || 'var(--colors-bright-gray)'}
    />
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: 'var(--colors-cadet)',
  className: '',
};

export default SvgComponent;
