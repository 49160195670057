import { MEDICATION_TYPES } from './consts';

const getCompoundedName = medicationObject => {
  const { dosage } = medicationObject.type;

  return `${medicationObject.ingredients
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    .map(
      ingredient =>
        `${ingredient.name} ${medicationObject.ingredientsDosage[ingredient.id]?.value}${
          medicationObject.ingredientsDosage[ingredient.id]?.measurement
        }`,
    )
    .join(' / ')} ${dosage}`;
};

const getCompoundedFormulation = medicationObject => {
  return medicationObject.type.name;
};

const getCompoundedDosageInfo = medicationObject => {
  return medicationObject.type.dosage;
};

export const getNonCompoundedLabel = medicationObject => {
  return `${medicationObject.name} ${medicationObject.formulation} ${medicationObject.strength || ''}`;
};

const getLicensedUnlicensedName = medicationObject => {
  return `${medicationObject.name} ${medicationObject.formulation} ${medicationObject.strength} x ${medicationObject.packageSize}`;
};

const getLicensedUnlicensedFormulation = medicationObject => {
  return medicationObject.formulation;
};

const getLicensedUnlicensedVitaminDosageInfo = medicationObject => {
  return medicationObject.strength;
};

const getVitaminName = medicationObject => {
  return `${medicationObject.name} ${medicationObject.formulation} ${medicationObject.strength}`;
};

const getVitaminFormulation = medicationObject => {
  return medicationObject.formulation;
};

export const getMedicationName = medication => {
  switch (medication.medicationType) {
    case MEDICATION_TYPES.COMPOUNDED:
      return getCompoundedName(medication.medicationObject);
    case MEDICATION_TYPES.LICENSED:
    case MEDICATION_TYPES.UNLICENSED:
      return getLicensedUnlicensedName(medication.medicationObject);
    case MEDICATION_TYPES.VITAMIN:
      return getVitaminName(medication.medicationObject);
    default:
      return '';
  }
};

export const getMedicationFormulation = medication => {
  switch (medication.medicationType) {
    case MEDICATION_TYPES.COMPOUNDED:
      return getCompoundedFormulation(medication.medicationObject);
    case MEDICATION_TYPES.LICENSED:
    case MEDICATION_TYPES.UNLICENSED:
      return getLicensedUnlicensedFormulation(medication.medicationObject);
    case MEDICATION_TYPES.VITAMIN:
      return getVitaminFormulation(medication.medicationObject);
    default:
      return '';
  }
};

export const getMedicationDosageInfo = medication => {
  switch (medication.medicationType) {
    case MEDICATION_TYPES.COMPOUNDED:
      return getCompoundedDosageInfo(medication.medicationObject);
    case MEDICATION_TYPES.LICENSED:
    case MEDICATION_TYPES.UNLICENSED:
    case MEDICATION_TYPES.VITAMIN:
      return getLicensedUnlicensedVitaminDosageInfo(medication.medicationObject);
    default:
      return '';
  }
};
